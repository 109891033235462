import { useNavigate, useParams } from 'react-router-dom';
import encabezado_contratos from '../../assets/images/Encabezados/encabezado-contratos.png';
import informacion_carpeta_financiera from '../../assets/images/Iconos/informacion_carpeta_financiera.png';
import pdf_icon from '../../assets/images/Iconos/icono_pdf.png';
import '../../style/contratos.css';
import { useEffect, useState } from 'react';

/* TAL VEZ SERIA MAS RAPIDO HACIENDOLO COMO UN DICCIONARIO PERO SOLO ES EL EJEMPLO */
const contratos = [
	{
		id: 'contratos-1',
		descripciones: [
			'Consulta el clausulado de los productos que Banco Forjadores ofrece al público:',
		],
		children: [
			{
				id: 'prlv-cede',
				titulo: 'PRLV-CEDE',
				subtitulo: 'PRLV-CEDE',
				descripciones: [
					'Este contrato contiene las condiciones aplicables a los siguientes productos:',
					'i. Pagaré Forjadores',
					'ii. Forjadores CEDE',
					'Cada producto contenido en este contrato se contrata de forma independiente, en ningún caso el cliente se encuentra obligado a contratar más de un producto si no lo desea.',
				],
				url: '/contratos/prlv-cede',
				children: [
					{
						id: 'PRLV-CEDE-1',
						tipo: 'ARCHIVO',
						subtitulo:
							'Contrato PRLV-CEDE RECA 13285-429-028564/08-03612-0819, vigente para las operaciones realizadas hasta el 28 de julio de 2022',
						url: 'https://www.forjadores.mx/wp-content/uploads/2021/03/CONTRATO-PRLV.pdf',
					},
					{
						id: 'contrato-prlv-cede-2022',
						tipo: 'CARPETA',
						titulo: 'Contrato PRLV-CEDE 2022',
						subtitulo:
							'Contrato PRLV-CEDE RECA 13285-429-028564/09-03029-0722 vigente para las operaciones realizadas a partir del 29 de julio de 2022f',
						url: '/contratos/prlv-cede/contrato-prlv-cede-2022',
						children: [
							{
								id: 'contrato-prlv-cede-2022-1',
								tipo: 'ARCHIVO',
								subtitulo: 'Contrato PRLV-CEDE 2022',
								url: 'https://www.forjadores.mx/wp-content/uploads/2022/08/CONTRATO-PRLV-CEDE-2022.pdf',
							},
							{
								id: 'contrato-prlv-cede-2022-2',
								tipo: 'ARCHIVO',
								subtitulo:
									'Anexo de disposiciones legales PRLV',
								url: 'https://www.forjadores.mx/wp-content/uploads/2022/08/ANEXO-DE-DISPOSICIONES-LEGALES-PRLV.pdf',
							},
						],
					},
					{
						id: 'contrato-prlv-cede-1222',
						tipo: 'CARPETA',
						titulo: 'Contrato PRLV-CEDE 2023',
						subtitulo:
							'Contrato PRLV-CEDE RECA 13285-429-028564/10-05234-1222 vigente para las operaciones realizadas a partir del 02 de enero de 2023',
						url: '/contratos/prlv-cede/contrato-prlv-cede-1222',
						children: [
							{
								id: 'contrato-prlv-cede-1222-1',
								tipo: 'ARCHIVO',
								subtitulo: 'Contrato PRLV-CEDE 2022',
								url: 'https://www.forjadores.mx/wp-content/uploads/2023/01/CONTRATO-PRLV-CEDE-1222.pdf',
							},
							{
								id: 'contrato-prlv-cede-1222-2',
								tipo: 'ARCHIVO',
								subtitulo:
									'Anexo de disposiciones legales PRLV',
								url: 'https://www.forjadores.mx/wp-content/uploads/2023/01/ANEXO-DE-DISPOSICIONES-LEGALES-PRLV-1222.pdf',
							},
						],
					},
					{
						id: 'contrato-prlv-cede-0323',
						tipo: 'CARPETA',
						titulo: 'Contrato PRLV-CEDE 0323',
						subtitulo:
							'Contrato PRLV-CEDE RECA 13285-999-037796/01-00622-0323 vigente para las operaciones realizadas a partir del 21 de marzo de 2023',
						url: '/contratos/prlv-cede/contrato-prlv-cede-0323',
						children: [
							{
								id: 'contrato-prlv-cede-0323-1',
								tipo: 'ARCHIVO',
								subtitulo: 'Contrato PRLV-CEDE 0323',
								url: 'https://www.forjadores.mx/wp-content/uploads/2023/03/CONTRATO-CAPTACION-0323_cambio-de-RECA.pdf',
							},
							{
								id: 'contrato-prlv-cede-0323-2',
								tipo: 'ARCHIVO',
								subtitulo:
									'Anexo de disposiciones legales PRLV',
								url: 'https://www.forjadores.mx/wp-content/uploads/2023/03/ANEXO-DE-DISPOSICIONES-LEGALES-PRLV-0323_cambio-de-RECA.pdf',
							},
						],
					},
				],
			},
			{
				id: 'credito-grupal',
				subtitulo: 'Crédito Grupal',
				url: '/contratos/credito-grupal',
				children: [
					{
						id: 'contrato-13285-439-038687',
						tipo: 'CARPETA',
						titulo: 'Contrato PRLV-CEDE 0323',
						subtitulo:
							' Contrato con número de registro 13285-439-038687/01-01486-0623 (vigente a partir del 19.06.2023)',
						url: '/contratos/credito-grupal/contrato-13285-439-038687',
						children: [
							{
								id: 'contrato-13285-439-038687-1',
								tipo: 'ARCHIVO',
								subtitulo: ' Anexo de disposiciones legales',
								url: 'https://www.forjadores.mx/wp-content/uploads/2023/07/Anexo-de-disposiciones-legales-Grupal-0623.pdf',
							},
							{
								id: 'contrato-13285-439-038687-2',
								tipo: 'ARCHIVO',
								subtitulo: 'Contrato de Crédito Grupal 0623',
								url: 'https://www.forjadores.mx/wp-content/uploads/2023/07/Contrato-Grupal-0623.pdf',
							},
						],
					},
				],
			},
			{
				id: 'contrato-credito-adicional',
				subtitulo: 'Crédito Adicional',
				url: '/contratos/contrato-credito-adicional',
				children: [
					{
						id: 'contrato-credito-adicional-1',
						tipo: 'ARCHIVO',
						subtitulo: 'Contrato Crédito Adicional',
						url: 'https://www.forjadores.mx/wp-content/uploads/2023/08/Contrato-Credito-Adicional.pdf',
					},
					{
						id: 'contrato-credito-adicional-2',
						tipo: 'ARCHIVO',
						subtitulo:
							'Anexo de disposiciones legales Crédito Adicional',
						url: 'https://www.forjadores.mx/wp-content/uploads/2023/08/Anexo-de-disposiciones-legales-Credito-Adicional.pdf',
					},
				],
			},
			{
				id: 'contrato-credito-interciclo',
				subtitulo: 'Crédito interciclo',
				url: '/contratos/contrato-credito-interciclo',
				children: [
					{
						id: 'contrato-credito-interciclo-1',
						tipo: 'ARCHIVO',
						subtitulo: 'Contrato Crédito Interciclo',
						url: 'https://www.forjadores.mx/wp-content/uploads/2023/08/Contrato-Credito-Interciclo.pdf',
					},
					{
						id: 'contrato-credito-interciclo-2',
						tipo: 'ARCHIVO',
						subtitulo:
							'A Anexo de disposiciones legales Crédito Interciclo',
						url: 'https://www.forjadores.mx/wp-content/uploads/2023/08/Anexo-de-disposiciones-legales-Credito-Interciclo.pdf',
					},
				],
			},
		],
	},
	{
		id: 'contratos-2',
		descripciones: [
			'Los siguientes productos ya no se ofrecen al público. Sin embargo, si usted mantiene un crédito vigente contratado al amparo de cualquiera de ellos, puede consultar aquí su clausulado:',
		],
		children: [
			{
				id: 'credito-individual',
				titulo: 'Contrato Universal de Crédito Individual',
				subtitulo: 'Contrato Universal de Crédito Individual',
				vigencia: 'Vigente hasta el 06/Mayo/2022',
				descripciones: [
					'Este contrato contiene las condiciones aplicables a los siguientes productos:',
					'i. Micro Crédito Individual Montos Menores',
					'ii. Micro Crédito Individual Montos Mayores',
					'iii. Autoemprende Déjalo',
					'iv. Autoemprende Llévalo',
					'v. Crédito Industrial',
					'Los créditos contenidos en este contrato no se encuentran relacionados, cada uno se contrata de manera independiente mediante la suscripción de los anexos correspondientes.',
				],
				url: '/contratos/credito-individual',
				children: [
					{
						id: 'contrato-01-05202-1221',
						tipo: 'CARPETA',
						titulo: 'Contrato Universal de Crédito Individual',
						subtitulo:
							'CONTRATO CON NÚMERO DE REGISTRO 13285-435-035652/01-05202-1221 (vigente hasta el 11.02.2022)',
						url: '/contratos/credito-individual/contrato-01-05202-1221',
						children: [
							{
								id: 'contrato-01-05202-1221-1',
								tipo: 'ARCHIVO',
								subtitulo:
									' Contrato Universal Crédito Individual',
								url: 'https://www.forjadores.mx/wp-content/uploads/2021/12/CONTRATO-UNIVERSAL-DE-CREDITO-INDIVIDUAL.pdf',
							},
							{
								id: 'contrato-01-05202-1221-2',
								tipo: 'ARCHIVO',
								subtitulo:
									'Disposiciones Legales Contrato Universal de Crédito Individual',
								url: 'https://www.forjadores.mx/wp-content/uploads/2021/12/Disposiciones-Legales-Contrato-Universal-de-Credito-Individual.pdf',
							},
						],
					},
					{
						id: 'contrato-02-00602-0222',
						tipo: 'CARPETA',
						titulo: 'Contrato Universal de Crédito Individual',
						subtitulo:
							'CONTRATO CON NÚMERO DE REGISTRO 13285-435-035652/02-00602-0222 (vigente a partir del 12.02.2022)',
						url: '/contratos/credito-individual/contrato-02-00602-0222',
						children: [
							{
								id: 'contrato-02-00602-0222-1',
								tipo: 'ARCHIVO',
								subtitulo:
									'Contrato Universal Crédito Individual',
								url: 'https://www.forjadores.mx/wp-content/uploads/2022/02/CONTRATO-UNIVERSAL-DE-CREDITO-INDIVIDUAL-0222.pdf',
							},
							{
								id: 'contrato-02-00602-0222-2',
								tipo: 'ARCHIVO',
								subtitulo:
									'Disposiciones Legales Contrato Universal de Crédito Individual',
								url: 'https://www.forjadores.mx/wp-content/uploads/2022/02/DISP-LEGALES-CONTRATO-UNIVERSAL-DE-CREDITO-INDIVIDUAL-0222.pdf',
							},
						],
					},
				],
			},
			{
				id: 'credito-grupal',
				titulo: 'Contrato Universal Grupal',
				subtitulo: 'Contrato Universal Grupal',
				vigencia: 'Vigente hasta el 06/Mayo/2022',
				descripciones: [
					'Este contrato contiene las condiciones aplicables a los siguientes productos:',
					'i. Crédito Grupal',
					'ii. PRLV Grupal',
					'El producto denominado PRLV Grupal contenido en este contrato no es de contratación forzosa, las acreditadas podrán contratarlo de forma libre mediante la suscripción de los anexos correspondientes',
				],
				url: '/contratos/credito-grupal',
				children: [
					{
						id: 'contrato-02-00185-0121',
						tipo: 'CARPETA',
						titulo: 'Contrato Universal de Crédito Grupal',
						subtitulo:
							'CONTRATO CON NÚMERO DE REGISTRO 13285-437-033896/02-00185-0121 (vigente hasta el 11.03.2022)',
						url: '/contratos/credito-grupal/contrato-02-00185-0121',
						children: [
							{
								id: 'contrato-02-00185-0121-1',
								tipo: 'ARCHIVO',
								subtitulo: 'Contrato Universal Crédito Grupal',
								url: 'https://www.forjadores.mx/wp-content/uploads/2022/03/Contrato-Universal-Grupal-0121.pdf',
							},
							{
								id: 'contrato-02-00185-0121-2',
								tipo: 'ARCHIVO',
								subtitulo:
									'Disposiciones Legales Contrato Universal de Crédito Grupal',
								url: 'https://www.forjadores.mx/wp-content/uploads/2022/03/Disp-Legales-Contrato-Universal-Grupal-0121.pdf',
							},
						],
					},
					{
						id: 'contrato-04-00910-0322',
						tipo: 'CARPETA',
						titulo: 'Contrato Universal de Crédito Grupal',
						subtitulo:
							'CONTRATO CON NÚMERO DE REGISTRO 13285-437-033896/04-00910-0322 (vigente a partir del 12.03.2022  al 17.07.2022)',
						url: '/contratos/credito-grupal/contrato-04-00910-0322',
						children: [
							{
								id: 'contrato-04-00910-0322-1',
								tipo: 'ARCHIVO',
								subtitulo: ' Contrato Universal Crédito Grupal',
								url: 'https://www.forjadores.mx/wp-content/uploads/2022/03/CONTRATO-UNIVERSAL-GRUPAL-0322.pdf',
							},
							{
								id: 'contrato-04-00910-0322-2',
								tipo: 'ARCHIVO',
								subtitulo:
									'Disposiciones Legales Contrato Universal de Crédito Grupal',
								url: 'https://www.forjadores.mx/wp-content/uploads/2022/03/Anexo-de-disposiciones-legales-CUG-0322.pdf',
							},
						],
					},
					{
						id: 'contrato-05-02259-0622',
						tipo: 'CARPETA',
						titulo: 'Contrato Universal de Crédito Grupal',
						subtitulo:
							'CONTRATO CON NÚMERO DE REGISTRO 13285-437-033896/05-02259-0622 (vigente a partir del 18.07.2022)',
						url: '/contratos/credito-grupal/contrato-05-02259-0622',
						children: [
							{
								id: 'contrato-05-02259-0622-1',
								tipo: 'ARCHIVO',
								subtitulo: 'Contrato Universal Crédito Grupal',
								url: 'https://www.forjadores.mx/wp-content/uploads/2022/07/CONTRATO-UNIVERSAL-GRUPAL-junio2022.pdf',
							},
							{
								id: 'contrato-05-02259-0622-2',
								tipo: 'ARCHIVO',
								subtitulo:
									'Disposiciones Legales Contrato Universal de Crédito Grupal',
								url: 'https://www.forjadores.mx/wp-content/uploads/2022/07/Anexo-de-disposiciones-legales-CUG.pdf',
							},
						],
					},
					{
						id: 'contrato-06-03535-0822',
						tipo: 'CARPETA',
						titulo: 'Contrato Universal de Crédito Grupal',
						subtitulo:
							'CONTRATO CON NÚMERO DE REGISTRO 13285-437-033896/06-03535-0822 (vigente a partir del 28.11.2022)',
						url: '/contratos/credito-grupal/contrato-06-03535-0822',
						children: [
							{
								id: 'contrato-06-03535-0822-1',
								tipo: 'ARCHIVO',
								subtitulo: ' Contrato Universal Crédito Grupal',
								url: 'https://www.forjadores.mx/wp-content/uploads/2022/12/CONTRATO-UNIVERSAL-GRUPAL_0822.pdf',
							},
							{
								id: 'contrato-06-03535-0822-2',
								tipo: 'ARCHIVO',
								subtitulo:
									'Disposiciones Legales Contrato Universal de Crédito Grupal',
								url: 'https://www.forjadores.mx/wp-content/uploads/2022/12/Anexo-de-disposiciones-legales-CUG-0822.pdf',
							},
						],
					},
					{
						id: 'contrato-07-05219-1222',
						tipo: 'CARPETA',
						titulo: 'Contrato Universal de Crédito Grupal',
						subtitulo:
							'CONTRATO CON NÚMERO DE REGISTRO 13285-437-033896/07-05219-1222 (vigente a partir del 02.01.2023)',
						url: '/contratos/credito-grupal/contrato-07-05219-1222',
						children: [
							{
								id: 'contrato-07-05219-1222-1',
								tipo: 'ARCHIVO',
								subtitulo: ' Contrato Universal Crédito Grupal',
								url: 'https://www.forjadores.mx/wp-content/uploads/2023/01/CONTRATO-UNIVERSAL-GRUPAL-1222.pdf',
							},
							{
								id: 'contrato-07-05219-1222-2',
								tipo: 'ARCHIVO',
								subtitulo:
									'Disposiciones Legales Contrato Universal de Crédito Grupal',
								url: 'https://www.forjadores.mx/wp-content/uploads/2023/01/Anexo-de-disposiciones-legales-CUG-1222.pdf',
							},
						],
					},
					{
						id: 'contrato-01-00622-0323',
						tipo: 'CARPETA',
						titulo: 'Contrato Universal de Crédito Grupal',
						subtitulo:
							'CONTRATO CON NÚMERO DE REGISTRO 13285-999-037795/01-00622-0323 (vigente a partir del 21.03.2023 al 18.06.2023)',
						url: '/contratos/credito-grupal/contrato-01-00622-0323',
						children: [
							{
								id: 'contrato-01-00622-0323-1',
								tipo: 'ARCHIVO',
								subtitulo: 'Contrato Universal Crédito Grupal',
								url: 'https://www.forjadores.mx/wp-content/uploads/2023/03/CONTRATO-UNIVERSAL-GRUPAL_0323.pdf',
							},
							{
								id: 'contrato-01-00622-0323-2',
								tipo: 'ARCHIVO',
								subtitulo:
									'Disposiciones Legales Contrato Universal de Crédito Grupal',
								url: 'https://www.forjadores.mx/wp-content/uploads/2023/03/Anexo-de-disposiciones-legales-CUG-0323.pdf',
							},
						],
					},
				],
			},
			{
				id: 'credito-en-cuenta-corriente-garantia-hipotecaria-pf',
				titulo: 'Crédito en Cuenta Corriente Garantía Hipotecaria PF',
				subtitulo:
					'Crédito en Cuenta Corriente Garantía Hipotecaria PF',
				vigencia: 'Vigente hasta el 06/Mayo/2022',
				url: '/contratos/credito-en-cuenta-corriente-garantia-hipotecaria-pf',
				children: [
					{
						id: 'credito-en-cuenta-corriente-garantia-hipotecaria-pf-1',
						tipo: 'ARCHIVO',
						subtitulo: 'Contrato CCC Garantía Hipotecaria PF',
						url: 'https://www.forjadores.mx/wp-content/uploads/2021/08/Contrato-CCC-garantia-hipotecaria-PF.pdf',
					},
					{
						id: 'credito-en-cuenta-corriente-garantia-hipotecaria-pf-2',
						tipo: 'ARCHIVO',
						subtitulo:
							'Disposiciones Legales CCC Garantía Hipotecaria PF',
						url: 'https://www.forjadores.mx/wp-content/uploads/2021/08/Anexo-DL-CCC-garantia-hipotecaria-PF.pdf',
					},
				],
			},
			{
				id: 'credito-en-cuenta-corriente-garantia-hipotecaria-pm',
				titulo: 'Crédito en Cuenta Corriente Garantía Hipotecaria PM',
				subtitulo:
					'Crédito en Cuenta Corriente Garantía Hipotecaria PM',
				vigencia: 'Vigente hasta el 06/Mayo/2022',
				url: '/contratos/credito-en-cuenta-corriente-garantia-hipotecaria-pm',
				children: [
					{
						id: 'credito-en-cuenta-corriente-garantia-hipotecaria-pm-1',
						tipo: 'ARCHIVO',
						subtitulo: 'Contrato CCC Garantía Hipotecaria PM',
						url: 'https://www.forjadores.mx/wp-content/uploads/2021/04/CONTRATO-CREDITO-EN-CUENTA-CORRIENTE-CON-GARANTIA-HIPOTECARIA-PM.pdf',
					},
					{
						id: 'credito-en-cuenta-corriente-garantia-hipotecaria-pm-2',
						tipo: 'ARCHIVO',
						subtitulo:
							'Disposiciones Legales CCC Garantía Hipotecaria PM',
						url: 'https://www.forjadores.mx/wp-content/uploads/2021/04/DISP-LEGALES-CREDITO-EN-CUENTA-CORRIENTE-CON-GARANTIA-HIPOTECARIA-PM.pdf',
					},
				],
			},
		],
	},
];

function Contratos() {
	const [contratosToMap, setContratosToMap] = useState([]);
	/* GETTING THE ID OF THE CONTRATO */
	const { id, id2 } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (!id) {
			setContratosToMap(contratos);
		} else if (id) {
			let contrato = null;
			contratos.forEach((contratoIn) => {
				contratoIn.children.forEach((child) => {
					if (child.id === id) {
						if (!id2) {
							contrato = child;
						} else if (id2) {
							child.children.forEach((child2) => {
								if (child2.id === id2) {
									contrato = child2;
								}
							});
						}
					}
				});
			});

			setContratosToMap([contrato]);
		}
	}, [id, id2]);

	return (
		<div className="contratos">
			<section
				className="encabezado"
				style={{
					padding: '0px 5%',
				}}
			>
				<img src={encabezado_contratos} alt="Encabezado contratos" />
			</section>
			<section className="clausulado">
				{contratosToMap?.length > 0 &&
					contratosToMap.map((contrato) => (
						<div key={contrato?.id}>
							<p className="simple-text">
								<big>
									<b>{contrato?.titulo ?? ''}</b>
								</big>
							</p>
							<div className="descripcion">
								{contrato?.descripciones?.map((descripcion) => (
									<p
										className="simple-text"
										style={{ marginBottom: '0px' }}
									>
										{descripcion}
									</p>
								))}
							</div>
							<div className="row">
								{contrato?.children?.length > 0 &&
									contrato.children.map((child) => (
										<div
											key={child.id}
											className="clausula"
											style={{
												cursor: 'pointer',
											}}
											onClick={() => {
												if (child.tipo === 'ARCHIVO') {
													window.open(child.url);
												} else {
													navigate(child.url);
												}
											}}
										>
											<img
												src={
													child.tipo === 'ARCHIVO'
														? pdf_icon
														: informacion_carpeta_financiera
												}
												alt="Icono de carpeta forjadores"
											/>
											<p
												className="simple-text"
												style={{ fontSize: '1em' }}
											>
												{child.subtitulo}
											</p>
											{child.vigencia && (
												<p
													className="simple-text"
													style={{ fontSize: '90%' }}
												>
													{child.vigencia}
												</p>
											)}
										</div>
									))}
							</div>
						</div>
					))}
			</section>
		</div>
	);
}

export default Contratos;
