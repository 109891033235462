import { useNavigate, Link } from 'react-router-dom';
import banner from '../../../assets/images/Encabezados/encabezado_50consejos-1536x768.jpg';

import '../../../style/quienessomos.css';

function Cinconsejos() {
    return (
        <div className="entrys Cinconsejos">            
            <div className='banner-postss'>
                <img src={banner} className='' />
            </div>
            <div className='posts'>
                <p className="post-step"><strong>1</strong> <strong>Conoce tus habilidades como Forjador</strong> Antes de invertir tiempo o recursos, <b>Banco Forjadores</b> te recomienda <b>autoevaluarse</b> y conocer si se cuenta con las habilidades típicas de un emprendedor.</p>



                <p className="post-step"><strong>2</strong> <strong>Desarrolla una idea Forjadora</strong> Desarrolla un concepto del negocio que te apasione como también tengas la experiencia en dicha labor, con el fin de que en dicha labor, con el fin de que lo domines sin descuidar en el beneficio que le proporcionará las personas tu producto o servicio.</p>



                <p className="post-step">​<strong>3</strong> <strong>Prueba tu credibilidad</strong> Una vez que tengas una idea Forjadora descubre cómo puedes convertirla en realidad.</p>



                <p className="post-step">​<strong>4</strong> <strong>Escribe un plan de negocios</strong> Un plan de negocios sólido te llevará hacia el éxito. Debe incluir una misión, un resumen ejecutivo, un resumen de la empresa, muestras del servicio o producto, una descripción de tu mercado objetivo, proyecciones financieras y el costo de las operaciones.</p>



                <p className="post-step">​<strong>5</strong> <strong>Identifica tu mercado Forjador</strong> Evalúa el mercado para que le vendas a las personas que seguro realizarán la compra.<br />Haz una evaluación competitiva con tu competencia.</p>



                <p className="post-step"><strong>6</strong> <strong>Determina los costos</strong> Investiga y conoce los costos promedio dentro del mercado. Esto no sólo te ayudará a manejar el negocio más eficientemente, también será información valiosa para los inversionistas Forjadores.</p>



                <p className="post-step">​<strong>7</strong> <strong>Establece un presupuesto Forjador</strong> Una vez que determines cuánto dinero tendrás para trabajar, descubre cuánto necesitas para desarrollar tu producto o servicio y crea un plan de marketing.</p>



                <p className="post-step">​<strong>8</strong> <strong>Encuentra a los socios Forjadores</strong> Necesitarás algún tipo de financiamiento para comenzar, ya sea de ahorros, tarjetas de crédito, préstamos, capitalistas de riesgo o donaciones. En Banco Forjadores compartimos tu pasión y queremos trabajar contigo.</p>



                <p className="post-step">​<strong>9</strong> <strong>Escucha a los inversionistas Forjadores</strong> ​Te daremos una opinión cuando se trate de tu empresa. Escucha sus consejos y sugerencias de nuestro personal Forjador calificado, sin embargo eso no quiere decir que tengas que hacer todo lo que te digan.</p>



                <p className="post-step"><strong>10</strong> <strong>Ten un gran sistema de apoyo</strong> Vas a invertir mucho tiempo y recursos en tu desarrollo empresarial. Asegúrate de que tu familia esté de acuerdo, ya que deben estar conscientes de que este proceso los retará en lo económico y emocional donde nosotros como Banco estamos dispuestos a apoyarlos.</p>



                <p className="post-step"><strong>11</strong> <strong>Determina la estructura legal</strong> Determina qué te conviene más: ser el único propietario, tener un socio, una sociedad de responsabilidad limitada, una corporación, una organización sin fines de lucro o una cooperativa.</p>



                <p className="post-step"><strong>12</strong> <strong>Selecciona el nombre de tu empresa</strong> Decide un nombre Forjador que vaya con tu marca o sello Forjador, verifica si está disponible y si puedes ocuparlo libremente en tu país y estado.</p>



                <p className="post-step">​<strong>13</strong> <strong>Registra el nombre de tu negocio Forjador</strong> Si el nombre que quieres está disponible, registralo lo antes posible en las oficinas correspondientes antes que te puedan ganar tu idea.<br />​Si el nombre que quieres está disponible, registralo lo antes posible en las oficinas correspondientes antes que te puedan ganar tu idea.</p>



                <p className="post-step"><strong>14</strong> <strong>Aprovecha los recursos gratuitos</strong> Estos pueden ser las redes sociales o hasta una persona que te ofrezca consejos y experiencia para sacar adelante tu nuevo negocio <strong>Forjador</strong> o la expansión del que ya tienes.</p>



                <p className="post-step">​<strong>15</strong> <strong>Adquiere seguros</strong> Asegúrate de tener el seguro adecuado para ti y tus empleados, que esté de acuerdo con tu tipo de negocio, que incluya, vida, robos o daños a los activos de tu empresa así como la responsabilidad por los daños relacionados con la misma. En <strong>Banco Forjadores</strong> ofrecemos diversos seguros que te ayudarán con este punto.</p>



                <p className="post-step">​<strong>16</strong> <strong>Ten inventarios de tu flujo de ingresos</strong> Ten registrado todo el dinero que entra y sale del negocio.</p>



                <p className="post-step">​<strong>17</strong> <strong>Elige la locación adecuada</strong> ​Escoge un local que se adecuado a las necesidades de tu negocio Forjador, que ofrezca la oportunidad para el crecimiento, el nivel adecuado de competencia y cercanía con los proveedores, siendo accesible para los clientes.</p>



                <p className="post-step"><strong>18</strong> <strong>No te preocupes por una oficina</strong> Si no estás obteniendo ganancias, no te mortifiques por adquirir un espacio para trabajar, <strong>Banco Forjadores</strong> te recomienda usar la comodidad del hogar.</p>



                <p className="post-step">​<strong>19</strong> <strong>La patente puede esperar</strong> Las patentes pueden costar mucho dinero. Paga esta cantidad cuando estés seguro de que tienes los clientes suficientes para saldar las cuentas o puedes adquirir un financiamiento con Banco Forjadores.</p>



                <p className="post-step">​<strong>20</strong> <strong>Sé flexible</strong> Ser capaz de adaptarte y cambiar para ofrecerle al cliente algo que desea, esto determinará si tu mentalidad es la de un Forjador del futuro.</p>



                <p className="post-step">​<strong>21</strong> <strong>Comparte tus ideas Forjadoras con amigos y familia</strong> Las personas más cercanas son las que serán más honestas contigo acerca de tu idea. No dudes en buscar sus consejos y sugerencias.</p>



                <p className="post-step">​<strong>22</strong> <strong>Ignora a los negativos</strong> Hay una gran brecha entre la crítica constructiva y alguien mencionando que tu negocio fracasará. Lo mejor que puedes hacer es ignorar a estas personas que no son Forjadoras de futuro.</p>



                <p className="post-step">​<strong>23</strong> <strong>No te enojes</strong> Si tu idea es rechazada por los clientes o inversionistas no recurras al enojo. Descubre qué fue lo que no agrado, haz ajustes y regresa con ellos una vez que hayas adecuado tu producto, tener una mentalidad abierta es parte de ser Forjador.</p>



                <p className="post-step">​<strong>24</strong> <strong>Entrega el producto o servicio rápido</strong> Tu negocio es un trabajo en proceso y si lanzas un producto o servicio eficazmente, serás capaz de construir una comunidad de clientes que te podrán proveer retroalimentación valiosa que te ayudará a mejorar tu oferta. Como dice el fundador de LinkedIn “Si no te avergüenza el primer lanzamiento de tu producto lo diste a conocer muy tarde”.</p>



                <p className="post-step"><strong>25</strong> <strong>Ofrece nuevos productos o servicios Forjadores</strong> Si ya tienes clientes asegúrate de cuidarlos dándoles nuevos productos o servicios.</p>



                <p className="post-step">​<strong>26</strong> <strong>Sé paciente</strong> Siempre ten en mente que el éxito no pasará de la noche a la mañana. Va a tomarte algo de tiempo antes de que ganes algo.</p>



                <p className="post-step"><strong>27</strong> <strong>Entrega más de lo que te pidan al principio</strong> Una vez que tengas un nuevo cliente asegúrate de ir más allá por lo menos el primer mes, dicha actitud <strong>Forjadora</strong> consolida la Lealtad del cliente.</p>



                <p className="post-step">​<strong>28</strong> <strong>Publica en tus Medios todo el tiempo</strong> No te avergüences de compartir tus triunfos y duelos Forjadores. Los clientes admiraran como también disfrutarán de tu honestidad.</p>



                <p className="post-step"><strong>29</strong> <strong>Evita las peleas con socios</strong> Si tienes desacuerdos, <strong>soluciónalos</strong> lo antes posible. Los conflictos pueden afectar el buen camino del negocio Forjador.</p>



                <p className="post-step"><strong>30</strong> ​<strong>No te preocupes por perder un porcentaje de la compañía</strong> Un inversionista ha adquirido una acción de tu empresa. Reconoce el hecho de que eventualmente en algún punto tendrás que renunciar a una porción del control del negocio.</p>



                <p className="post-step"><strong>31</strong> <strong>Consigue ideas Forjadoras para redactar</strong> ​Recibe consejos para redactar los comunicados Forjadores hacia los clientes meta.</p>



                <p className="post-step"><strong>32</strong> <strong>Prepárate para reuniones</strong> ​Cuando te prepares para reunirte con tus clientes, Banco Forjadores te recomienda que estudies acerca del mercado similar a tu servicio o producto, para labor de convencimiento y persuasión.</p>



                <p className="post-step"><strong>33</strong> <strong>Haz check list de los puntos importantes</strong> ​Haz llegar a tus colaboradores dichos puntos para el buen logro de resultados, asegúrate de que estos objetivos sean claros y todos los entiendan.</p>



                <p className="post-step"><strong>34</strong> <strong>No tengas miedo de la competencia</strong> Banco forjadores te recomienda no hablar mal de la competencia cuando estés con clientes. Hablar de esta manera incrementa la posibilidad de que los clientes se vayan con la competencia que ofrece un producto o servicio que tú no ofreces. Recuerda, cuando la competencia se vaya, habrá un mercado para tu negocio. Usa ese conocimiento como inspiración para hacer las cosas mejor que tu rival.</p>



                <p className="post-step"><strong>35</strong> <strong>Benefíciate del boca en boca</strong> Nada vence a un buen marketing de boca en boca. Deja que tus amigos, familia y clientes, divulguen lo que hace tu producto o servicio.</p>



                <p className="post-step"><strong>36</strong> <strong>Ofrece una experiencia Forjadora al cliente</strong> Esto significa manejar colores, luces, aromas, etcétera. Integra todas estas sensaciones a tu oferta, de tal forma que conquisten los sentidos de tus consumidores bajo tu marca y estilo Forjador.</p>



                <p className="post-step"><strong>37</strong> <strong>Haz contactos</strong> Hazte de contactos en el exterior para ampliar tus horizontes de clientes Forjadores.</p>



                <p className="post-step">​<strong>38</strong> <strong>Da un servicio de experiencia Forjadora al cliente</strong> Interactuar con la gente es una parte importante de tu trabajo. Tu negocio podría ganar nuevos clientes por la simple calidad en el servicio.</p>



                <p className="post-step">​<strong>39</strong> <strong>Asegúrate de que tu medios de comunicación funcionen</strong> Los clientes potenciales quieren saber lo más que se pueda de tu negocio, así que el acceso al mismo debe ser eficiente.</p>



                <p className="post-step"><strong>40</strong> <strong>No te preocupes en exceso por tu economía</strong> Algunos de los mejores negocios se lanzaron cuando la situación económica no era la mejor para el mundo, así que no lo pienses tanto y ¡Se Forjador!</p>



                <p className="post-step"><strong>41</strong> <strong>Asegúrate de que los clientes paguen las cuentas</strong> Cerciórate de recibir el pago de tu producto o servicio. Establece un tiempo determinado para realizar el pago.</p>



                <p className="post-step"><strong>42</strong> ​<strong>Encuentra a los empleados adecuados</strong> Contrata a las personas Forjadoras para el puesto. Aunque sea tu negocio, no podrás lidiar con todo, por eso necesitas a gente calificada para completar el trabajo.</p>



                <p className="post-step"><strong>43</strong> <strong>Asigna responsabilidades Forjadoras</strong> Delega tareas realizables a tu equipo de trabajo.</p>



                <p className="post-step"><strong>44</strong> ​<strong>La honestidad es la mejor política</strong> Si tienes problemas con algún empleado, asegúrate de que se resuelvan. A nadie le gusta que hablen a sus espaldas.</p>



                <p className="post-step"><strong>45</strong> <strong>Recuerda que los opuestos se atraen</strong> Contrata a personas con habilidades y personalidades opuestas a las tuyas. Te retarán y traerán cosas nuevas a tu negocio Forjador.</p>



                <p className="post-step"><strong>46</strong> ​<strong>Presta atención a tu negocio Forjador</strong> Vas a pasar gran tiempo dedicado a tu negocio. Debes de comprender que ante la menor inconsistencia en tu negocio, será la mayor alerta para atenderla.</p>



                <p className="post-step"><strong>47</strong> <strong>Acepta que tú serás la última persona a la que le paguen</strong> Como dueño, serás el último en recibir un cheque, y así será hasta que tengas las ganancias Forjadoras.</p>



                <p className="post-step"><strong>48</strong> <strong>No esperes el éxito de inmediato</strong> Sólo porque tu negocio no te ha convertido en millonario (aún) no quiere decir que tu empresa es un fracaso. Si has recibido algún tipo de ganancia haciendo algo que te apasiona es una historia de éxito Forjador ¿o no?</p>



                <p className="post-step">​<strong>49</strong> <strong>Acepta cuando sea momento de retirarse</strong> El fracaso es inevitable. Si las cosas no están funcionando y has hecho todo lo que puedes, haz a un lado tu orgullo y cierra. Algo así no es fácil de aceptar pero es para lo mejor.</p>



                <p className="post-step">​<strong>50</strong> <strong>No confíes sólo en el consejo de los otros</strong> ​A pesar de que Banco Forjadores te brinda esta serie de consejos, debes recordar lo más importante, tú eres el dueño del negocio y el responsable del éxito o fracaso del mismo. Si sabes qué es lo que funcionó, tendrás las habilidades y conocimiento para sacar adelante tu empresa.</p>



                <p className="post-closure"><strong>En Banco Forjadores estaremos para brindarte atención como también&nbsp; orientación para el logro de tus resultados.</strong></p>
            </div>
            <nav class="pagination-single section-inner" aria-label="Publicación">
                <hr class="styled-separator is-style-wide" aria-hidden="true"/>
                <div class="pagination-single-inner">
                    <Link className="previous-post" to="/tips-para-mejorar-tu-negocio/presupuesto/">
                        <span class="arrow" aria-hidden="true">←</span>
                        <span class="title"><span class="title-inner">PRESUPUESTO</span></span>
                    </Link>
                    {/* <Link className="next-post" to="/tips-para-mejorar-tu-negocio/actualizacion-30-07-2021/">
                        <span class="arrow" aria-hidden="true">→</span>
                        <span class="title"><span class="title-inner">Actualización 30/07/2021</span></span>
                    </Link> */}
                </div>
                <hr class="styled-separator is-style-wide" aria-hidden="true"/>
            </nav>
        </div>
    );
}

export default Cinconsejos;