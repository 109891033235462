import encabezado from '../../assets/images/Encabezados/encabezado-quienes-somos-banco-forjadores.png';
import lineas_quienes_somos from '../../assets/images/lineas_quienes_somos.png';

import '../../style/quienessomos.css';

function QuienesSomos() {
    return (
        <div className="QuienesSomos">
            <section className="encabezado">
                <img src={encabezado} />
            </section>
            <p>
            Banco Forjadores es una empresa mexicana constituida en el 2005, cuyo principal accionista desde 2007 es Grupo ACP, organización de reconocida experiencia en el rubro en Latinoamérica.<br/><br/>
            
            Apoyamos a Forjar los sueños de miles de mujeres y hombres mexicanos, personas de lucha diaria y continua, personas incansables, personas exitosas. Apoyamos sus proyectos mediante microcréditos productivos bajo el concepto de Banca Comunal y Crédito Individual.<br/><br/>
            
            Por ahora estamos presentes en 10 estados de la República Mexicana contando con 31 oficinas de servicio y con un equipo de cerca de 450 colaboradores.<br/><br/>
            
            Estamos motivados a seguir trabajando para el beneficio de nuestros clientes e impulsando el desarrollo económico del país, con la visión de llegar a ser el mejor Banco de Microfinanzas en México.<br/><br/> 
            
            <b>PORQUE LO NUESTRO ES CUMPLIR TUS SUEÑOS<small>®</small></b>
            </p>
            <img src={lineas_quienes_somos} className='lineas'/>
        </div>
    );
}

export default QuienesSomos;
