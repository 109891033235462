import '../../style/infohead.css';
import PuntosDePagoFile from "../../assets/documents/Despachos-de-Cobranza-septiembre-2024.pdf";
import encabezado from '../../assets/images/Encabezados/Encabezado_puntospago.png';
import Lineas_puntospago from '../../assets/images/Lineas_puntospago.png';

function PuntosDePago() {
    return (
        <div className="entry-content">

            <div className="paymentIntro">                
                <img src={encabezado} />
            </div>

            <div className="paymentDescription">
                <p>Te compartimos los <b>lugares</b> en los cuales podrás realizar <b>tus pagos sin demorarte</b> o complicarte:</p>

                <p>Realiza el pago de tu crédito en los puntos autorizados por Banco Forjadores que se muestran a continuación. Recuerda siempre llevar:<br/><b>•</b> Número de RAP (para los créditos grupales la que se asignó a tu grupo y para los individuales la que se te asignó)<br/><b>•</b> Convenio<br/><br/>Para tu comodidad los pagos a tu crédito puedes realizarlo en <b>múltiples Puntos</b>, elige el más cercano a ti.<br/><br/>Las <b>Instituciones financieras</b> en las cuales podrás realizar depósitos son las siguientes:</p>
                </div>

            <div className="paymentOptions">
                <div>
                    <h4>BBVA</h4>
                    <p><b>Servicio o CIE: 726230</b> De lunes a viernes de 9 am a 4:00 pm, algunas sucursales abren los sábados de 9:00 am a 3:00 pm.</p>
                </div>
                <div>
                    <h4>Banorte <small>Seven Eleven y Telecomm</small></h4>
                    <p><b>Número de empresa: 70783</b> De lunes a viernes de 8:30 am a 5:00 pm, algunas sucursales abren los sábados de 9:00 am a 2:00 pm.</p>
                </div>
                <div>
                    <h4>Santander</h4>
                    <p><b>Convenio:</b> 6346 De lunes a viernes de 8:30 am a 4:00 pm, algunas sucursales abren los sábados de 10:00 am a 2:00 pm.</p>
                </div>
                <div>
                    <h4>BanBajio</h4>
                    <p><b>Convenio:</b> 2598 De lunes a viernes de 9 am a 4:00 pm, algunas sucursales abren los sábados de 10:00 am a 2:00 pm</p>
                </div>
                <div>
                    <h4>Oxxo</h4>
                    <p><b>Acudir con el código de barras que está impreso en tu ficha de pago.</b> De lunes a domingo, las 24 horas del día.</p>
                    <a class="forjadores-btn" href={PuntosDePagoFile} download="Listado de Tiendas - Oxxo">Descargar listado de tiendas</a>
                </div>

                <p>De acuerdo al Artículo 333 párrafo segundo de las Disposiciones de Carácter General aplicables a las Instituciones de Crédito, en esta sección se encuentran publicados los establecimientos de los Comisionistas autorizados por la CNBV donde nuestros clientes pueden realizar operaciones de pago de crédito.<br/><br/>Los derechos sobre las marcas, signos distintivos, nombres comerciales y/o avisos comerciales se encuentran protegidos a favor de sus respectivos titulares de conformidad con la legislación aplicable en materia de propiedad intelectual, se prohibe expresamente al usuario modificar, alterar, suprimir ya sea en forma total o parcial las marcas, signos distintivos nombres comerciales y/o avisos comerciales aquí contenidos.<br/><br/>Las operaciones realizadas a través de los comisionistas bancarios podrán generar una comisión, consulte antes de realizar su operación.</p>

                <img src={Lineas_puntospago} />
            </div>

        </div>
    );
}

export default PuntosDePago;