import { useNavigate, Link } from 'react-router-dom';

import '../../style/footer.css';
import BancoMexico from "../../assets/images/Logos/banco_mexico.png";
import CNBV from "../../assets/images/Logos/cnbv.png";
import Buro from "../../assets/images/Logos/buro.png";
import IPAB from "../../assets/images/Logos/ipab.png";
import Condusef from "../../assets/images/Logos/condusef.png";

import Codigo from "../../assets/documents/CE-26-Codigo-de-Etica-y-Conducta.pdf";

function Footer() {

    const navigate = useNavigate();

    return (
        <div>            
            <div className="footer-nav-widgets-wrapper header-footer-group">
                <div className="footer-inner section-inner">
                    <div className="footer-widgets-outer-wrapper">
                        <div className="footer-widgets-wrapper">
                            <div className="footer-widgets column-one grid-item">
                                <div className="widget widget_nav_menu">
                                    <div className="widget-content">
                                        <nav className="menu-mainu-container" aria-label="Menú">
                                            <ul className="menu-mainu-2 menu">
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home"><Link to="/">Inicio</Link></li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page current-page-ancestor menu-item-has-children">
                                                    <Link to="/quienes-somos">Quiénes somos</Link>
                                                    <ul className="sub-menu">
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3107"><Link to="/quienes-somos/nuestra-historia/">Nuestra Historia</Link></li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3103"><Link to="/quienes-somos/compromiso/">Compromiso</Link></li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-484"><Link to="/quienes-somos/relacion-con-inversionistas/">Relación con Inversionistas</Link></li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-483"><Link to="/quienes-somos/trabaja-con-nosotros/">Trabaja con nosotros</Link></li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3787"><Link to="/quienes-somos/tips-para-mejorar-tu-negocio/">Tips para Mejorar tu Negocio</Link></li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page current-page-ancestor menu-item-3791"><Link to="/quienes-somos/tips-de-seguridad/">Tips de Seguridad</Link></li>
                                                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-12579">
                                                            <a href={Codigo} target='blank_'>Código de Ética y Conducta</a>
                                                            {/* <Link to="/wp-content/uploads/2023/09/CE-26-Codigo-de-Etica-y-Conducta.pdf">Código de Ética y Conducta</Link> */}
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children">
                                                    <Link to="/creditos/">Créditos</Link>
                                                    <ul className="sub-menu">
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-56"><Link to="/creditos/credito-grupal/">Crédito Grupal</Link></li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-12251"><Link to="/creditos/credito-adicional/">Crédito Adicional</Link></li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-12250"><Link to="/creditos/credito-interciclo/">Crédito Interciclo</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children"><Link to="/inversiones/">Inversiones</Link>
                                                    <ul className="sub-menu">
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-60"><Link to="/inversiones/pagare-forjadores/">Pagaré Forjadores</Link></li>
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-61"><Link to="/inversiones/forjadores-cede/">Forjadores CEDE</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page"><Link to="/asistencias-combo/">Asistencias Combo</Link></li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children"><Link to="/programas-de-beneficios/">Programas de beneficios</Link>
                                                    <ul className="sub-menu">
                                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2787"><Link to="/programas-de-beneficios/recomienda-y-gana/">Recomienda y Gana</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page"><Link to="/contacto/">Contacto</Link></li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page"><Link to="/contratos/">Contratos</Link></li>
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page"><Link to="/costos-y-comisiones-de-productos-de-credito/">Consulta los costos y las comisiones de nuestros productos</Link></li>
                                                {/* <li className="menu-item menu-item-type-post_type menu-item-object-page"><a to="/quienes-somos/trabaja-con-nosotros/">Trabaja con nosotros</a></li> */}
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            
                                <div className="widget widget_nav_menu">
                                    <div className="widget-content">
                                        <nav className="menu-social-container" aria-label="Menú">
                                            <ul className="menu-social menu">
                                                <li id="menu-item-184" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-184"><a href="https://www.facebook.com/BForjadores/">Fb</a></li>
                                                <li id="menu-item-185" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-185"><a href="https://www.instagram.com/bancoforjadores/">Ig</a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div className="footer-widgets column-two grid-item">
                                <div className="widget widget_nav_menu">
                                    <div className="widget-content">
                                        <nav className="menu-legal-container" aria-label="Menú">
                                            <ul className="menu-legal menu">
                                                <li id="menu-item-208" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"><Link to="/consultas-reclamaciones-y-aclaraciones/">Consultas, Reclamaciones y Aclaraciones</Link></li>
                                                <li id="menu-item-207" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-207"><Link to="/aviso-de-privacidad/">Aviso de Privacidad</Link></li>
                                                <li id="menu-item-206" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"><Link to="/buro-de-entidades-financieras/">Buró de Entidades Financieras</Link></li>
                                                <li id="menu-item-3204" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3204"><Link to="/despachos-de-cobranza/">Despachos de Cobranza</Link></li>
                                                <li id="menu-item-4189" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4189"><Link to="/quienes-somos/relacion-con-inversionistas/informe-de-remuneraciones/">Informe de Remuneraciones</Link></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                
                                <div className="widget_text widget widget_custom_html">
                                    <div className="widget_text widget-content">
                                        <div className="textwidget custom-html-widget">
                                            <div className="footerLogos">
                                                <a href="https://www.banxico.org.mx/" target="_blank" rel="noopener"><img src={BancoMexico} /></a>
                                                
                                                <a href="https://www.gob.mx/cnbv" target="_blank" rel="noopener"><img src={CNBV} /></a>
                                                
                                                <a href="https://www.buro.gob.mx/" target="_blank" rel="noopener"><img src={Buro} /></a>
                                                
                                                <a href="https://www.gob.mx/ipab" target="_blank" rel="noopener"><img src={IPAB} /></a>
                                                
                                                <p>Dudas e información <br/><a href="mailto:informacion@ipab.org.mx">informacion@ipab.org.mx</a> <br/>Tel: <a href="tel:5552095500">55 5209 5500</a></p>
                                                
                                                <a href="https://www.gob.mx/condusef" target="_blank" rel="noopener"><img src={Condusef} /></a>
                                                
                                                <p>Dudas e información <br/> condusef.gob.mx <br/>Tel: <a href="tel:5553400999">55 5340 0999</a> <br/> <a href="tel:8009998080">800 999 8080</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Footer;
