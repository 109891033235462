import encabezado from '../../../assets/images/Encabezados/encabezado-tips-de-seguridad-de-banco-forjadores.png';
import lineas_separacion from '../../../assets/images/Unete_lineas.png';
import numuno from '../../../assets/images/Parapoderpagar_1.png';
import numdos from '../../../assets/images/Parapoderpagar_2.png';
import numtre from '../../../assets/images/Parapoderpagar_3.png';

import '../../../style/quienessomos.css';

function TipsForjadores() {
    return (
        <div class="entry-content TipsForjadores">

            <div id="bfIntro">
                <img src={encabezado} alt="Encabezado Tips de Seguridad de Banco Forjadores" />
                <img src={lineas_separacion} decoding="async" alt='' />
            </div>
            <div id="bfContainer">
                <img src={numuno} decoding="async" alt='' data-ll-status="loaded" />
                <p>No responda correos electrónicos en los que se informe sobre un supuesto bloqueos de los productos que tenga con <b>Banco Forjadores</b>, si tiene duda acuda a su Oficina de Servicios.</p>

                <img src={numdos} decoding="async" alt='' data-ll-status="loaded" />
                <p>No acceda a enlaces enviados a través de mensajes SMS no solicitados y que impliquen la descarga de contenidos en los dispositivos; esto ayudará a prevenir que el dispositivo sea infectado con software malicioso (malware), el cual le permitiría al delincuente tener el control del dispositivo y la información almacenada en él.</p>
                
                <img src={numtre} decoding="async" alt='' data-ll-status="loaded" />
                <p>En caso de pérdida o cambio de su teléfono celular o de casa, notifíquelo de inmediato a Banco Forjadores.</p>
            </div>

        </div>
    );
}

export default TipsForjadores;