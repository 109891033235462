import { useNavigate } from 'react-router-dom';
import '../../style/programas_de_beneficios.css';
import encabezado_programa_de_beneficios from '../../assets/images/Encabezados/encabezado_programa_de_beneficios.png';
import grafico_recomienda_y_gana from '../../assets/images/grafico-recomienda-y-gana.png';

function ProgramasDeBeneficios() {
	const navigate = useNavigate();

	return (
		<div className="programas-de-beneficios">
			<section
				className="encabezado"
				style={{
					padding: '0px 5%',
				}}
			>
				<img
					src={encabezado_programa_de_beneficios}
					alt="Encabezado prorgama de beneficios"
				/>
			</section>
			<section className="beneficios-list">
				<div className="beneficio">
					<img src={grafico_recomienda_y_gana} alt='Beneficio image'/>
					<h2>Recomienda y gana</h2>
					<p>
						Recomienda a un nuevo integrante para Crédito Grupal o a
						un cliente nuevo en Crédito Individual y gana.
					</p>
					<button
						onClick={() => {
							navigate(
								'/programas-de-beneficios/recomienda-y-gana/',
							);
						}}
					>
						Más informacion
					</button>
				</div>
			</section>
		</div>
	);
}

export default ProgramasDeBeneficios;
