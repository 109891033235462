import '../../style/inicio.css';
import EncabezadoCovid from '../../assets/images/Encabezados/encabezado-informacion-de-interes-sobre-covid.png';
import Limpia from '../../assets/images/limpia-y-desinfecta-superficies-para-prevenir-covid.png';
import Lineas from '../../assets/images/comofunciona_lineas.png';
import ciruno from '../../assets/images/duracion-covid-en-piel.png';
import cirdos from '../../assets/images/duracion-covid-en-papel.png';
import cirtre from '../../assets/images/duracion-covid-en-vestimenta-y-mascarillas.png';
import circua from '../../assets/images/duracion-covid-en-madera.png';
import circin from '../../assets/images/duracion-covid-en-vidrio.png';
import cirsei from '../../assets/images/duracion-covid-en-plastico.png';
import reco_uno from '../../assets/images/recomendaciones-covid-1.png';
import reco_dos from '../../assets/images/recomendaciones-covid-2.png';
import reco_tres from '../../assets/images/recomendaciones-covid-3.png';
import reco_cuat from '../../assets/images/recomendaciones-covid-4.png';
import reco_cinc from '../../assets/images/recomendaciones-covid-5.png';
import reco_seis from '../../assets/images/recomendaciones-covid-6.png';
import reco_siet from '../../assets/images/recomendaciones-covid-7.png';
import reco_ocho from '../../assets/images/recomendaciones-covid-8.png';
import reco_nuev from '../../assets/images/recomendaciones-covid-9.png';
import reco_diez from '../../assets/images/recomendaciones-covid-10.png';

function InfoCovid() {
    return(
        <div className="entry-content">
            <div id="covidInfoIntro">
                <img src={EncabezadoCovid} decoding="async" alt='' data-ll-status="loaded" />
            </div>

            <div id="covidInfoClean">
                <img src={Limpia} decoding="async" alt=''/>
                <div>
                    <h2><strong>LIMPIA Y</strong> <b>DESINFECTA</b> SUPERFICIES</h2>
                    <p><strong>CON AGUA Y JABÓN /<br/>CLORO DILUIDO EN AGUA /<br/>VIROL OXY DILUIDO EN AGUA</strong><br/><br/><b>•</b> Objetos de uso común<br/><b>•</b> Juguetes<br/><b>•</b> Mobiliario<br/><b>•</b> Cocina<br/><b>•</b> Suelos</p>
                </div>                
                <p>
                    <img src={Lineas} decoding="async" alt='' />
                    La tos y estornudos pueden expulsar <strong>gotas de saliva</strong> que se esparcen el virus <strong>hasta 4 metros.</strong><br/><br/><strong>Tiempo de supervivencia del virus en supreficies:</strong>
                </p>                
                <span>                
                    <img src={ciruno} decoding="async" alt='' />
                    <p><strong>PIEL</strong> Algunos minutos</p>
                </span>
                <span>
                    <img src={cirdos} decoding="async" alt='' />
                        <p><strong>PAPEL</strong> Hasta 5 días</p>
                </span>
                <span>
                    <img src={cirtre} decoding="async" alt='' />
                    <p><strong>VESTIMENTA Y MASCARILLAS</strong> Hasta 5 días</p>
                </span>
                <span>
                    <img src={circua} decoding="async" alt='' />
                    <p><strong>MADERA</strong> Hasta 4 días</p>
                </span>
                <span>
                    <img src={circin} decoding="async" alt='' />
                    <p><strong>VIDRIO</strong> Hasta 5 días</p>
                </span>
                <span>
                    <img src={cirsei} decoding="async" alt='' />
                    <p><strong>PLÁSTICO</strong> 6 a 9 días</p>
                </span>
            </div>

            <div id="covidInfoRecommendations">
                <h2>RECOMENDACIONES <b>PARA ESTAR EN CASA</b></h2>
                <div>
                    <img src={reco_uno} decoding="async" alt='' data-ll-status="loaded" />
                    <p>Establece una rutina y actividades diarias.</p>
                </div>
                <div>
                    <img src={reco_seis} decoding="async" alt='' data-ll-status="loaded" />
                    <p>Comunícate con tus seres queridos, es un distanciamiento físico, no afectivo.</p>
                </div>

                <div>
                    <img src={reco_dos} decoding="async" alt='' data-ll-status="loaded" />
                    <p>Haz ejerccio y practica técnicas de respiración.</p>
                </div>
                <div>
                    <img src={reco_siet} decoding="async" alt='' data-ll-status="loaded" />
                    <p>Come saludablemente, mantén un horario en tus alimentos.</p>
                </div>

                <div>
                    <img src={reco_tres} decoding="async" alt='' data-ll-status="loaded" />
                    <p>Mantén el orden en tu casa.</p>
                </div>
                <div>
                    <img src={reco_ocho} decoding="async" alt='' data-ll-status="loaded" />
                    <p>Recuerda tomar agua.</p>
                </div>

                <div>
                    <img src={reco_cuat} decoding="async" alt='' data-ll-status="loaded" />
                    <p>Evita recibir visitas, hacer fiestas y/o reuniones.</p>
                </div>
                <div>
                    <img src={reco_nuev} decoding="async" alt='' data-ll-status="loaded" />
                    <p>Dedícate tiempo.</p>
                </div>

                <div>
                    <img src={reco_cinc} decoding="async" alt='' data-ll-status="loaded" />
                    <p>Dedícate tiempo.</p>
                </div>
                <div>
                    <img src={reco_diez} decoding="async" alt='' data-ll-status="loaded" />
                    <p>Limpia y desinfecta objetos de uso común con cloro o alcohol al 70%</p>
                </div>
            </div>

        </div>
    );
}

export default InfoCovid;