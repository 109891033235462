import '../../style/inicio.css';
import Encabezado from '../../assets/images/Encabezados/encabezado-forjadoras-de-corazon-campana-credito.jpg';
import Mujer from '../../assets/images/cliente-campana-credito-forjadoras-de-corazon.png';
import linea_formulario_contrata from "../../assets/images/Individual_lineas_contrata.png";
import icono_correo_contactanos from '../../assets/images/Iconos/icono_correo_contactanos.png';
import icono_whats_contactanos from '../../assets/images/Iconos/icono_whatsapp.png';

function ForjadorasCorazon() {
    return (
        <div className="entry-content">
            <div id="forjadorasIntro" className="product-intro">
                <img src={Encabezado} decoding="async" alt="Encabezado Campaña para Crédito Grupal - Forjadoras de Corazón" data-ll-status="loaded" />
            </div>
            <div id="forjadorasDescription" className="product-description">
                <p>Si tienes un <b>crédito grupal</b> en otra institución financiera <b>te mejoramos las condiciones</b> de tu crédito con una tasa de interés <b>preferencial*</b> y además te igualamos o <b>mejoramos el monto de tu préstamo*</b> y, por si fuera poco, recibirás todos los beneficios al ser parte de nuestro grupo de clientes <b>“Forjadoras de Corazón”</b>.</p>
                <div>
                    <p><b>Asesoría personalizada con un trato amable y respetuoso:</b><br/>Cada semana nuestros asesores te visitan en el domicilio de reunión del grupo para brindarte asesoría financiera y en tu primer desembolso lo celebramos con un pastel.</p>
                    <p><b>Grupos pequeños: </b><br/>El mínimo de integrantes para grupos nuevos es de 8 personas por lo que podrás reunirte con las personas de tu mayor confianza.</p>
                    <p><b>Montos adecuados a tu capacidad de pago</b><br/>Si ya tienes experiencia con otros créditos te podemos mejorar el monto de tu préstamo actual, sin que tengan que empezar con monto limitado por su antigüedad, todo de acuerdo a tu capacidad de pago para no sobreendeudarte.</p>
                    <p><b>Renovaciones anticipadas: </b><br/>Si tienes la necesidad de resurtir tu negocio podemos renovarte de manera anticipada el crédito.</p>
                    <p><b>Campañas de bonificaciones:</b><br/>Y para nuestros clientes que cumplen con sus pagos en tiempo tenemos campañas de bonificaciones por su excelente historial de pagos.</p>
                </div>
                <img src={Mujer} decoding="async" alt="Cliente Crédito Grupal - Forjadoras de Corazón" data-ll-status="loaded" />
            </div>

            <div className="service-quote product-quote" id=''>
                <h2>¿YA TIENES TUS <b>DOCUMENTOS</b> LISTOS?</h2>
                <div className="row contact-container">
                    <div className="info-container forjadoras">
                        <p>
                            <big>¡Inicia tu trámite ahora!</big>

                            Llámanos al <br /><a className='highlighted' href="tel:8008300005">800 830 0005</a>

                            <img decoding="async" src={linea_formulario_contrata} data-ll-status="loaded" />

                            <a className="highlighted" href="https://api.whatsapp.com/send?phone=5215580507824" target="_blank" rel="noopener">
                                <img className="info-icon" decoding="async" src={icono_whats_contactanos} data-ll-status="loaded" />
                                55 8039 8668
                            </a>

                            <a className="highlighted" href="mailto:atencionaclientes@forjadores.com.mx"
                            >
                                <img className="info-icon" decoding="async" src={icono_correo_contactanos} data-ll-status="loaded"
                                />
                                atencionaclientes@forjadores.com.mx
                            </a>

                            Déjanos tus datos y empieza desde hoy tu proceso
                        </p>
                    </div>
                    <div className="form-container forjadoras">
                        <form>
                            <div className="row" style={{ gap: '25px' }}>
                                <div className="form-item">
                                    <label htmlFor="nombre">
                                        Nombre
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="nombre"
                                        name="nombre"
                                        required
                                    />
                                </div>

                                <div className="form-item">
                                    <label htmlFor="apellido">
                                        Apellido
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="apellido"
                                        name="apellido"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row" style={{ gap: '25px' }}>
                                <div className="form-item">
                                    <label htmlFor="email">
                                        Email
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        required
                                    />
                                </div>

                                <div className="form-item">
                                    <label htmlFor="telefono">
                                        Teléfono
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="tel"
                                        id="telefono"
                                        name="telefono"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row" style={{ gap: '25px' }}>
                                <div className="form-item">
                                    <label htmlFor="estado">
                                        Estado
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="estado"
                                        name="estado"
                                        required
                                    />
                                </div>

                                <div className="form-item">
                                    <label htmlFor="municipio">
                                        Municipio
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="tel"
                                        id="municipio"
                                        name="municipio"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="form-item" style={{ width: '100%' }}>
                                <label htmlFor="mensaje">
                                    Mensaje
                                    <span className="erf-required">*</span>
                                </label>
                                <textarea
                                    style={{ height: 'unset !important' }}
                                    rows="5"
                                    id="mensaje"
                                    required
                                ></textarea>
                            </div>

                            <button type="submit" className="forjadores-btn">
                                Enviar
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            
            <div id="forjadorasLegal" class="product-legal">
                <h2>Términos y condiciones</h2>
                <p>Banco Forjadores S.A, INSTITUCIÓN DE BANCA MÚLTIPLE (en adelante “Banco Forjadores”). Aplica para el producto denominado CRÉDITO GRUPAL. Los términos y requisitos de contratación para este producto pueden ser consultados en www.forjadores.mx. <b>La Tasa fija anual promedio de intereses para este producto es de 84.07% (Ochenta y cuatro punto cero siete por ciento). EL CAT (Costo Anual Total) promedio para este producto es de 177.2% SIN IVA (Ciento setenta y siete punto dos por ciento SIN IVA)</b>, lo anterior para fines informativos y de comparación exclusivamente, CAT calculado el 9 de Mayo de 2023 vigente al 31 de diciembre del 2023. Leyendas de advertencia: i) Incumplir tus obligaciones te puede generar comisiones e intereses moratorios, ii) Contratar créditos que excedan tu capacidad de pago afecta tu historial crediticio, iii) El avalista, obligado solidario y/o coacreditado responderá como obligado principal por el total del pago frente a la Institución Financiera. Este producto requiere la contratación de los seguros que pueden ser consultados en la página de internet www. forjadores.mx, <b>el cliente tiene derecho a contratar este seguro a través de un tercero independiente</b>. Para mayor información de este producto, consulta la página web www.forjadores.mx o llama a los teléfonos 55.56-11-24-50 ó LADA SIN COSTO 800-830-00-05. Este producto se oferta en pesos mexicanos, consulta la cobertura geográfica en la página de internet. UNE: unidad_especializada@forjadores.com.mx, tel: 55 56 11 24 50, 800 83 000 05. CONDUSEF: www.condusef.gob.mx, tel: 55 53 40 09 99 *Se requiere presentar documento probatorio de la tasa de interés y monto que se tenga con otra institución financiera.</p>
            </div>

        </div>
    );
}

export default ForjadorasCorazon;