
import '../../style/creditos.css';
import encabezado from "../../assets/images/Encabezados/encabezado-banco-forjadores-credito-grupal.jpg";
import Grupal_caracteristicas from "../../assets/images/Grupal_caracteristicas.png";
import linea_formulario_contrata from "../../assets/images/Individual_lineas_contrata.png";
import icono_correo_contactanos from '../../assets/images/Iconos/icono_correo_contactanos.png';
import icono_whats_contactanos from '../../assets/images/Iconos/icono_whatsapp.png';
import pasouno from "../../assets/images/paso_1.png";
import pasodos from "../../assets/images/icono-credito-grupal-visita-de-un-asesor.png";
import pasotre from "../../assets/images/icono-paso-3-credito-grupal.png";
import pasocua from "../../assets/images/icono-paso-4-credito-grupal.png";

import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import UseProductosStore from '../../stores/UseProductosStore';
import DOMPurify from 'dompurify';


function CreditoGrupal() {

    const [mappedProductos, setMappedProductos] = useState([]);
    const {
        getProductos,
        productos,
        loadingProductos,
    } = UseProductosStore();

    useEffect(() => {
        getProductos();
    }, []);

    useEffect(() => {
        const newMappedProductos = productos.map((producto) => {
            return {
                ...producto,
                open: false,
            };
        });

        setMappedProductos(newMappedProductos);
    }, [productos]);

    return (
        <div className="entry-content">

            <div id="groupIntro">
                <img src={encabezado} alt="Encabezado Crédito Grupal - Banco Forjadores" />
                <p>¿Eres una mujer de negocios y conoces más mujeres como tú?<br/> Nuestros créditos grupales están pensados en el<br/> crecimiento de tu negocio y el de tu familia.</p>
            </div>

            <div id="groupInformation">
                <h2><small>FORJANDO HISTORIAS DE ÉXITO<small>®</small></small> OBTÉN UN CRÉDITO <b>GRUPAL</b> Y HAZ CRECER TU <b>MICRONEGOCIO</b></h2>

                <span className="cta-credit">Solicita tu crédito ahora<br /><a className="forjadores-btn" href="#credit-quote">Contratar</a></span>

                {/* {loadingProductos ? (
                    'Cargando productos...'
                ) : mappedProductos?.length === 0 ? (
                    <div>No hay productos para mostrar</div>
                ) : (
                    mappedProductos.map((producto) => {
                        return (
                            <div key={producto.id_Producto}>
                                {!producto.open && (
                                    <div>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(producto.caracteristicas,),
                                            }}
                                        ></div>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(producto.requisitos,),
                                            }}
                                        ></div>
                                    </div>
                                )}
                            </div>                                
                        );
                    })
                )} */}
                
                <div>
                    <h3>CARACTERÍSTICAS</h3>

                    {/* <div id='Car-CredGpo'></div> */}

                    <p><b>•</b> Grupo autoconformado con un mínimo de 6 integrantes.<br/><b>•</b> Monto de crédito desde $5,000 MXN hasta $91,000 MXN <br/><b>•</b> Plazo 16, 20 y 24 semanas para créditos con frecuencia de pago semanal<br/><b>•</b> Plazo 8, 10 y 12 catorcenas para créditos con frecuencia de pago catorcenal<br/><b>••</b> Pago semanal y catorcenal<br/><b>•</b> Este producto requiere la contratación de seguros, consulta sus características y condiciones dando clic <a href="https://www.forjadores.mx/costos-y-comisiones-de-productos-de-credito/">aquí</a><br/><b>•</b> Liquidación anticipada sin penalización<br/><b>•</b> Obligado solidario (mismas integrantes del grupo)<br/><b>•</b> Este crédito se oferta para personas físicas</p>
                        <p><strong>Al ser cliente del banco puedes acceder a los <a href="https://www.forjadores.mx/programas-de-beneficios/">Programas de beneficios</a></strong></p>
                </div>
                
                <div>
                    <h3>REQUISITOS</h3>

                    {/* <div id='Req-CredGpo'></div> */}

                    <p><b>•</b> Edad de 18 a 75 años<br/><b>•</b> Solicitud de crédito y consulta a buró firmada<br/><b>•</b> Identificación Oficial (INE).<br/><b>•</b> CURP<br/><b>•</b> Comprobante de domicilio con antigüedad no mayor a 3 meses<br/><b>•</b> Tener su domicilio o negocio ubicado dentro de la zona de influencia de la Oficina de Servicio.</p>

                    <img src={Grupal_caracteristicas} alt="Grupal Caracteristicas" />
                </div>
                
                <p>Consulta los costos y las comisiones de nuestros productos dando clic <a href="https://www.forjadores.mx/costos-y-comisiones-de-productos-de-credito/">aquí</a></p>
                <p>Leyendas sobre riesgos:<br/><b>–</b> “Incumplir tus obligaciones te puede generar comisiones e intereses moratorios”<br/><b>–</b> “Contratar créditos que excedan tu capacidad de pago afecta tu historial crediticio”<br/><b>–</b> “El avalista, obligado solidario o coacreditado responderá como obligado principal por el total del pago frente a la Institución Financiera”<br/><b>–</b> “Las operaciones realizadas a través de los comisionistas bancarios podrán generar una comisión, consulte antes de realizar su operación”</p>
                
                <p>Este producto se oferta en todas las oficinas, consulta su ubicación en la parte superior de esta página</p>
            </div>
            
            <div className="credit-quote" id='credit-quote'>
                <h2>¿YA TIENES TUS <b>DOCUMENTOS</b> LISTOS?</h2>

                <div className="row contact-container">
                    <div className="info-container">
                        <p>
                            <big>¡Contrata tu Crédito Grupal ahora!</big> 
                            
                            Llámanos al<br/>                            
                            <a className='highlighted' href="tel:8008300005">800 830 0005</a> 
                            
                            <img
                                decoding="async"
                                src={linea_formulario_contrata}
                                data-ll-status="loaded"
                            />
                            
                            <a className="highlighted" href="https://api.whatsapp.com/send?phone=5215580507824" target="_blank" rel="noopener">
                                <img className="info-icon" decoding="async" src={icono_whats_contactanos} data-ll-status="loaded"/>
                                55 8039 8668
                            </a>

                            <a className="highlighted" href="mailto:atencionaclientes@forjadores.com.mx"
                            >
                                <img className="info-icon" decoding="async" src={icono_correo_contactanos} data-ll-status="loaded"
                                />
                                atencionaclientes@forjadores.com.mx
                            </a>

                            Déjanos tus datos y empieza desde hoy tu proceso
                        </p>
                    </div>
                    <div className="form-container">
                        <form>
                            <div className="row" style={{ gap: '25px' }}>
                                <div className="form-item">
                                    <label htmlFor="nombre">
                                        Nombre
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="nombre"
                                        name="nombre"
                                        required
                                    />
                                </div>

                                <div className="form-item">
                                    <label htmlFor="apellido">
                                        Apellido
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="apellido"
                                        name="apellido"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row" style={{ gap: '25px' }}>
                                <div className="form-item">
                                    <label htmlFor="email">
                                        Email
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        required
                                    />
                                </div>

                                <div className="form-item">
                                    <label htmlFor="telefono">
                                        Teléfono
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="tel"
                                        id="telefono"
                                        name="telefono"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row" style={{ gap: '25px' }}>
                                <div className="form-item">
                                    <label htmlFor="estado">
                                        Estado
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="estado"
                                        name="estado"
                                        required
                                    />
                                </div>

                                <div className="form-item">
                                    <label htmlFor="municipio">
                                        Municipio
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="tel"
                                        id="municipio"
                                        name="municipio"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="form-item" style={{ width: '100%' }}>
                                <label htmlFor="mensaje">
                                    Mensaje
                                    <span className="erf-required">*</span>
                                </label>
                                <textarea
                                    style={{ height: 'unset !important' }}
                                    rows="5"
                                    id="mensaje"
                                    required
                                ></textarea>
                            </div>

                            <button type="submit" className="forjadores-btn">
                                Enviar
                            </button>
                        </form>
                    </div>
                </div>

                <div id="groupSteps">
                    <div>
                        <span>
                            <p><big>1</big> REÚNE A UN EQUIPO DE PODER</p>
                            <img src={pasouno} decoding="async" data-ll-status="loaded" />
                        </span><span>
                            <p><big>2</big> VISITA DE TU ASESOR FORJADORES</p>
                            <img src={pasodos} decoding="async" data-ll-status="loaded" />
                        </span><span>
                            <p><big>3</big> FORMALIZA TU CRÉDITO JUNTO CON TU GRUPO</p>
                            <img src={pasotre} decoding="async" data-ll-status="loaded" />
                        </span><span>
                            <p><big>4</big> DISFRUTA DE BIENESTAR PARA TU NEGOCIO</p>
                            <img src={pasocua} decoding="async" data-ll-status="loaded" />
                        </span>
                    </div>
                    <Link className="forjadores-btn"  to={"/creditos/credito-grupal/glosario-de-terminologia-legal-credito-grupal/"}>Glosario de Terminología Legal</Link>
                    <Link className="forjadores-btn" to={"/costos-y-comisiones-de-productos-de-credito/"}>Consulta los costos y las comisiones de nuestros productos</Link>
                </div>
            </div>

        </div>
    );
}

export default CreditoGrupal;