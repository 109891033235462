import { useNavigate, Link } from 'react-router-dom';
import banner from '../../../assets/images/Encabezados/Encabezado-1.png';
import graf1 from '../../../assets/images/Seguro_definicion-de-riesgo.png';
import graf2 from '../../../assets/images/Seguro_graficoseguros.png';
import graf3 from '../../../assets/images/Seguro_incendios_.png';
import lineas from '../../../assets/images/lineas_1-1024x59.png';
import lineas2 from '../../../assets/images/Ahorro_lineas7-1024x51.png';
import lineas3 from '../../../assets/images/Ahorro_lineas5-1024x35.png';

import '../../../style/quienessomos.css';

function Seguros() {
    return (
        <div className="type-post">
            <div className="entry-content">
                <div className='banner-postss'>
                    <img src={banner} className='' />
                </div>
                {/* <figure className='wp-block-image size-full'>
                    <img src={banner} decoding="async" width="1920" height="736" alt='' className='lazyloaded' />
                </figure> */}

                <figure className='wp-block-image size-full size-large post-p-right'>
                    <img src={graf1} decoding="async" width="550" height="430" alt='' className='lazyloaded' />
                </figure>
                
                <p class="post-img-left"><strong>Definición de Riesgo</strong><br/><br/>El riesgo es la probabilidad de que ocurra un accidente o un imprevisto que pueda dañar a la persona, la familia o el patrimonio.<br/><br/><strong>Riesgo</strong><br/><br/>Para protegernos de los riesgos podemos prevenirlos, y transferirlos, se pueden minimizar, pero nunca erradicar.</p>

                <figure className='wp-block-image size-large size-full post-img-line'>
                    <img src={lineas} decoding="async" width="1024" height="59" alt='' className='lazyloaded' />
                </figure>
                
                <p><strong>Seguros</strong><br/><br/>Cuando se habla de seguros, el comprador paga una prima que es el precio del seguro a cambio de un posible servicio futuro, sin embargo, la protección comienza desde el momento en que se formaliza el contrato del seguro.<br/><br/>La venta del seguro constituye el inicio de la relación entre asegurado y la compañía aseguradora, estas no solo tienen que atender el siniestro, además tienen la obligación de mantener informando y asesorado a su cliente en todo momento.</p>
                <p></p>
                <p class="post-title-greenbg"><strong>Requisitos para poder asegurar</strong></p>
                <p class="post-step"><strong>1</strong><br/>Debe tratarse de algo material, por ejemplo un coche, vivienda, negocio o algún artículo que tengamos en casa, también puede ser inmaterial, es decir, un perjuicio económico por paralización de actividad, como cuando sucede alguna enfermedad.</p>
                <p class="post-step"><strong>2</strong><br/>Debe existir un período de tiempo en el contrato, en el cual se puedan manifestar los riesgos.</p>
                <p class="post-step"><strong>3</strong><br/>Debe ser cuantificable, es decir, tasable en dinero.</p>
                <p class="post-step"><strong>4</strong><br/>Debe ser un objeto derivado de una actividad lícita y estar expuesto a perderse por el riesgo que corre el asegurado.</p>

                <figure className='wp-block-image size-large size-full post-img-line'>
                    <img src={lineas2} decoding="async" width="1024" height="51" alt='' className='lazyloaded' />
                </figure>
                
                <p>La mayoría de los seguros son productos preestablecidos, diseñados para cubrir una o más soluciones concretas. Al final el solicitante del seguro es quien elige la cobertura que más se adapte a sus necesidades o presupuesto.</p>

                <figure className='wp-block-image size-full post-img-centered'>
                    <img src={graf2} decoding="async" width="758" height="416" alt='' className='lazyloaded' />
                </figure>
                
                <p><strong>Seguros</strong><br/><br/>Generalmente en un contrato de seguro intervienen seis partes:</p>
                <p><strong>Aseguradora</strong><br/><br/>Es la entidad o persona moral que asume las consecuencias del riesgo que es objeto de cobertura en un contrato de seguro. Se encarga de recibir el importe de la prima como precio del riesgo que cubre, así mismo tiene derecho a verificar las circunstancias en las que se ha producido el siniestro a fin de comprobar que existe cobertura según lo acordado en el contrato firmado.</p>
                <p><strong>Tomador</strong><br/><br/>Es la persona que suscribe o paga el contrato con la entidad aseguradora. No siempre la persona asegurada es el beneficiario. Puede solicitar a la aseguradora que realice cambios en la póliza, como modificar la cobertura, cambiar a los beneficiarios o el domicilio de cobro siempre y cuando no se deje de pagar la prima pactada.</p>
                <p><strong>Asegurado</strong><br/><br/>Es la persona que en sí misma en sus bienes o en sus intereses económicos está expuesta al riesgo, por ejemplo, en un seguro de vida el asegurado será la persona cuya vida se basa en el contrato. Su supervivencia o su fallecimiento en un período dado es la cláusula principal del contrato.</p>
                <p><strong>Beneficiario</strong><br/><br/>Es la persona que recibe la indemnización en los casos previstos en la póliza, suele tener un vínculo común de intereses personales, familiares o económicos con el asegurado o tomador. Un ejemplo es el seguro de vida, los beneficiarios en caso de fallecimiento en caso de titular serán los familiares más cercanos. Los cónyuges e hijos del asegurado, que es la persona que ha fallecido. Aunque en las condiciones de la póliza se puede designar como beneficiario a cualquier persona.</p>
                <p><strong>Póliza</strong><br/><br/>Es la persona que recibe la indemnización en los casos previstos en la póliza, suele tener un vínculo común de intereses personales, familiares o económicos con el asegurado o tomador. Un ejemplo es el seguro de vida, los beneficiarios en caso de fallecimiento en caso de titular serán los familiares más cercanos. Los cónyuges e hijos del asegurado, que es la persona que ha fallecido. Aunque en las condiciones de la póliza se puede designar como beneficiario a cualquier persona.</p>
                <p><strong>Mediadores</strong><br/><br/>Es decir la persona o asesor que aconseja al cliente sobre las modalidades del seguro y coberturas que más le convienen en función de su situación personal. Para ello analiza los riesgos a lo que está expuesto, los perjuicios económicos que podrían surgir y le propone una oferta y no es obligatorio que intervenga en esta parte.</p>
                <p>dado es la cláusula principal del contrato.</p>
                <p><strong>Beneficiario</strong></p>
                <p>Es la persona que recibe la indemnización en los casos previstos en la póliza, suele tener un vínculo común de intereses personales, familiares o económicos con el asegurado o tomador. Un ejemplo es el seguro de vida, los beneficiarios en caso de fallecimiento en caso de titular serán los familiares más cercanos. Los cónyuges e hijos del asegurado, que es la persona que ha fallecido. Aunque en las condiciones de la póliza se puede designar como beneficiario a cualquier persona.</p>
                <p><strong>Póliza</strong></p>
                <p>Es la persona que recibe la indemnización en los casos previstos en la póliza, suele tener un vínculo común de intereses personales, familiares o económicos con el asegurado o tomador. Un ejemplo es el seguro de vida, los beneficiarios en caso de fallecimiento en caso de titular serán los familiares más cercanos. Los cónyuges e hijos del asegurado, que es la persona que ha fallecido. Aunque en las condiciones de la póliza se puede designar como beneficiario a cualquier persona.</p>
                <p><strong>Mediadores</strong></p>
                <p>Es decir la persona o asesor que aconseja al cliente sobre las modalidades del seguro y coberturas que más le convienen en función de su situación personal. Para ello analiza los riesgos a lo que está expuesto, los perjuicios económicos que podrían surgir y le propone una oferta y no es obligatorio que intervenga en esta parte.</p>
                <p><strong>Seguro de incendios</strong><br/>En el seguro de incendios el asegurado es el titular del inmueble o bien cubierto en la póliza. Cuando se asegura un bien, el asegurado debe custodiarlo, protegerlo y tomar las precauciones necesarias para no favorecer la ocurrencia de un siniestro.</p>

                <figure className='wp-block-image size-full post-img-centered'>
                    <img src={graf3} decoding="async" width="870" height="379" alt='' className='lazyloaded' />
                </figure>

                <figure className='wp-block-image size-large size-full post-img-line'>
                    <img src={lineas2} decoding="async" width="1024" height="51" alt='' className='lazyloaded' />
                </figure>
                
                <p><strong>Clasificación de seguros</strong></p>
                <p><strong>SEGURO PÚBLICO</strong><br/><br/>Son los que ofrece el gobierno y por lo regular son los seguros sociales que cubren gastos médicos. Dentro de los seguros privados los más comunes son el seguro de vida, el seguro de gastos médicos mayores, seguro de automóvil, seguro de daños para el hogar y el seguro de desempleo. Entre los tipos de seguros tenemos el de vida, en caso de que muera el asegurado, la compañía aseguradora le dará una cantidad de dinero a quienes hayan designado como beneficiario.</p>
                <p><strong>SEGURO PRIVADO</strong><br/><br/>El seguro de gastos médicos mayores por otro lado cubre gastos por accidente o enfermedad, en tanto que el seguro de automóvil es que dependiendo del tipo de cobertura se pagarán los daños ocasionados a la salud de los ocupantes o al vehículo de un tercero y los daños que nuestro automóvil y sus ocupantes puedan sufrir.</p>

                <figure className='wp-block-image size-large size-full post-img-line'>
                    <img src={lineas3} decoding="async" width="1024" height="35" alt='' className='lazyloaded' />
                </figure>
                
                <p class="post-step"><strong>Hogar</strong><br/><br/>El seguro de daños para el hogar cubre los daños a consecuencia de un fenómeno natural, robo o incendio.</p>
                <p class="post-step"><strong>Desempleo</strong><br/><br/>El seguro de desempleo otorga una protección básica a los trabajadores asalariados que hayan perdido el empleo incluyendo a varios grupos vulnerables y discriminados.</p>
                <p class="post-step"><strong>Tiendas departamentales</strong><br/><br/>También existe otro tipo de seguro, los cuales son otorgados y emitidos por las tiendas departamentales o por los fabricantes de algún artículo, estos cubren alguna descompostura, robo, o alguna falla de algún producto que hayamos comprado.</p>
                <p class="post-step"><strong>Pensión</strong><br/><br/>La pensión es una cantidad de dinero que periódicamente reciben los trabajadores cuando se retiran por haber ahorrado una parte de su sueldo durante todos los años que estuvieron trabajando.<br/><br/>Ese dinero es administrado por instituciones de seguridad social o por administradoras de fondos para el retiro a través de una cuenta individual.<br/><br/>El ahorro para el retiro de los trabajadores proviene de las aportaciones que durante años realiza la empresa, el gobierno y el propio trabajador.<br/><br/>Estas aportaciones son una parte del ahorro para el retiro, la otra se puede conformar con un ahorro adicional voluntario que realizaría el trabajador a lo largo de su vida laboral. Las administradoras de los fondos para el retiro no solo guardan los ahorros, sino que también los invierten. Así con el tiempo ese dinero incrementa su valor como es un ahorro a largo plazo.<br/><br/>Si el trabajador ahorra de forma voluntaria pequeñas cantidades adicionales de forma constante tendrá una mejor pensión y nivel de vida en su retiro.</p>
                <p><strong>Consejos</strong></p>
                <ul class="post-list-half"><li>Revisa y compara varias opciones en el mercado antes de contratar un seguro.</li><li>Revisa la póliza del seguro, acércate a un profesional, para que te explique cuál es el mejor seguro de acuerdo a tus necesidades.</li><li>Reflexiona que es lo que realmente necesitas.</li><li>Lee y analiza las condiciones de la póliza.</li></ul>
                <p>El riesgo es inesperado, incontrolable y no sabemos cuándo aparecerá. Por eso siempre es mejor tener cubiertos nuestros riesgos, el riesgo no se puede eliminar, pero sí se puede minimizar. ¿Cómo? Con una póliza de seguro. Y recuerda que el seguro más barato, no siempre es el mejor, lee las letras chiquitas del contrato.</p>
                <p class="post-closure">Recuerda que en términos del seguro es mejor tenerlo y no usarlo, que tener que usarlo y no tenerlo.</p>
                <p></p>
            </div>
            <nav class="pagination-single section-inner" aria-label="Publicación">
                <hr class="styled-separator is-style-wide" aria-hidden="true"/>
                <div class="pagination-single-inner">
                    <Link className="previous-post" to="/tips-para-mejorar-tu-negocio/consumo-responsable/">
                        <span class="arrow" aria-hidden="true">←</span>
                        <span class="title"><span class="title-inner">CONSUMO RESPONSABLE</span></span>
                    </Link>
                    <Link className="next-post" to="/tips-para-mejorar-tu-negocio/credito/">
                        <span class="title"><span class="title-inner">CRÉDITO</span></span>
                        <span class="arrow" aria-hidden="true">→</span>
                    </Link>
                </div>
                <hr class="styled-separator is-style-wide" aria-hidden="true"/>
            </nav>
        </div>
    );
}

export default Seguros;