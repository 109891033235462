import encabezado from '../../assets/images/Encabezados/banners-web.png';
import QRUnete from '../../assets/images/qrcode_docs.google.com-2-1.png';
import Unete_lineas from '../../assets/images/Unete_lineas.png';

import '../../style/quienessomos.css';

function TrabajaNostros() {
    return (
        <div className="TrabajaNostros">

            <div className="entry-content">

                <div className="joinIntro">
                    <img src={encabezado} alt="Únete al equipo Banco Forjadores" />
                </div>

                <div className="joinDescription">
                    <h2>Únete a <b>Banco Forjadores</b> y sé parte de la construcción del <b>Mejor Banco de Microfinanzas de México </b></h2>
                    <p>Si quieres trabajar con nosotros ingresa a nuestra página de empleos mediante el siguiente código QR o bien haciendo clic en el botón: Revisa nuestras vacantes.</p>
                </div>

                <div className="QR">
                    <img src={QRUnete} className='QR'/>
                    <a className="forjadores-btn" href="https://docs.google.com/forms/d/e/1FAIpQLSeJOToMCp6AunyX9lGhfDZlo2mOQqf7XvIzsQz1R05CrEs9iA/viewform"> Registra tus datos y nos comunicaremos contigo. </a>
                </div>

                <img src={Unete_lineas} className='lineas' />
            </div>

        </div>
    );
}

export default TrabajaNostros;
