
import '../../style/inicio.css';
import Excel from "../../assets/documents/FSI-07-FORMULARIO-DERECHOS-ARCO-1.xlsx";
import PDF from "../../assets/documents/guia-de-usuario-para-el-ejercicio-de-derechos-arco-3.pdf";

function AvisoPrivacidad() {
    return (
        <div class="entry-content">
            <div id="privacyContainer">
                <p><b>Aviso de Privacidad</b></p>
                <p>
                    <strong>Banco Forjadores, S.A., Institución de Banca Múltiple</strong>, (En lo sucesivo “BANCO FORJADORES”)
                    <br/>
                    Avenida Insurgentes sur No. 617, piso 10, Col. Nápoles, Código Postal 03810 en Ciudad de México.
                    <br/>
                    CASFIM: 40149  Teléfono: (55) 56 11 24 50
                    <br/><br/>
                    A todos nuestros solicitantes de crédito y Clientes (en adelante los “Clientes”):
                    <br/><br/>
                    El presente Aviso de Privacidad tiene como fin primordial dar cumplimiento a lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante la “Ley”).
                    <br/><br/>
                    <b>FINALIDAD DEL TRATAMIENTO DE DATOS.</b>
                    <br/><br/>
                    <b>BANCO FORJADORES</b> requiere los datos personales de los solicitantes de crédito y de sus Clientes con la finalidad de:
                    <br/>
                    (a) identificarlos, consultar y verificar los datos e información que nos proporcionan, incluyendo su imagen o su imagen facial, su huella dactilar y/o su voz, así como cualquier otro biométrico;
                    <br/>(b) poder celebrar contratos de crédito;
                    <br/>(c) realizar requerimientos de pago;
                    <br/> (d) que el Cliente pueda efectuar sus pagos utilizando tarjetas de crédito o débito que tenga contratadas con alguna institución financiera;
                    <br/>(e) ceder o enajenar los derechos que a su favor se deriven de los Contratos que se hubieren celebrado;<br/>(f) otorgarles información relacionada con los Contratos celebrados;
                    <br/> (g) ofrecerles productos y/o servicios otorgados directamente por <b>BANCO FORJADORES,</b> en términos del artículo 23 Bis de la Ley para la Transparencia y Ordenamiento de los Servicios Financieros;
                    <br/>(h) contactarlos con la finalidad de mantener actualizados sus datos personales, mientras se encuentre vigente la relación jurídica entre el Cliente y <b>BANCO FORJADORES</b> y dentro de los 10 (diez) años posteriores que termine la relación jurídica, en caso de que sea necesario actualizar algún dato personal en términos del artículo 115 de la Ley de Instituciones de Crédito;
                    <br/> (i) para hacer consultas a las sociedades de información crediticia, con la previa autorización de los Clientes;
                    <br/> (j) consultar, validar y/o verificar los datos, datos biométricos (facial) e información de la credencial para votar a través de los sistemas que para tales efectos tenga implementado el Instituto Nacional Electoral, en caso de que los Clientes utilicen la credencial para votar como documento de identificación y/o comprobante de domicilio;
                    <br/> (k) obtener los datos de geolocalización del dispositivo móvil o fijo, a través del cual contactan a <b>BANCO FORJADORES</b> utilizando de la red mundial denominada internet;
                    <br/> (l) obtener los datos de geolocalización, las imágenes, videos y datos de contacto de sus dispositivos móviles, en caso de que descarguen e ingresen a nuestras aplicaciones o páginas de internet;
                    <br/>(m) prestarle servicios de asesoría financiera; y
                    <br/>(n) para fines mercadotécnicos, publicitarios y de prospección comercial mientras se encuentre vigente la relación jurídica entre el Cliente y <b>BANCO FORJADORES</b> y durante los 5 (cinco) años posteriores a la terminación de la relación jurídica.
                    <br/><br/>
                    Se informa a solicitantes de crédito y Clientes que las finalidades que dieron origen a la existencia de la relación jurídica son las enunciadas en los incisos (a) y (b) y aquellas que servirán al mantenimiento y/o cumplimiento de las obligaciones que se derivan de la relación jurídica, así como para efectos comerciales serán las contenidas en los incisos (c) a (n). A través del presente Aviso de Privacidad,<b>BANCO FORJADORES</b> garantiza el derecho de sus Clientes para manifestar su negativa para el tratamiento de sus datos personales con relación a las finalidades antes enunciadas y aquellas que el solicitante de crédito y el Cliente considere que no son necesarias para la relación jurídica, para lo cual el solicitante del crédito o Cliente deberá informar a <b>BANCO FORJADORES</b> sobre su negativa al momento en que <b>BANCO FORJADORES</b> le ponga a su disposición el presente Aviso de Privacidad, entendiéndose que el consentimiento expreso que el solicitante del crédito y el Cliente otorga al momento en que se le entrega o acepta expresamente de manera electrónica el presente Aviso de Privacidad es señal de su conformidad, sin perjuicio de su derecho para revocar el consentimiento que previamente hubiere otorgado o bien para ejercer su derecho a oponerse al tratamiento de sus datos en términos de las disposiciones aplicables, derechos que en todo caso serán garantizados por <b>BANCO FORJADORES.</b>
                    <br/><br/>
                    <b>OBTENCIÓN Y TRATAMIENTO DE LOS DATOS PERSONALES.</b>
                    <br/><br/>
                    <b>BANCO FORJADORES</b> recaba los datos de sus solicitantes de crédito y Clientes<br/>(a) de manera personal;<br/>(b) a través de medios electrónicos incluyendo medios audiovisuales;<br/>(c) a través de Encargados, en términos de la Ley; o<br/>(d) por cualquier otro medio permitido por la Ley.<br/><br/> Los datos que <b>BANCO FORJADORES</b> recaba de sus solicitantes de crédito y Clientes son tratados a través de medios técnicos, administrativos y digitales adecuados que permiten proteger su información. Los datos de los Clientes de <b>BANCO FORJADORES</b> reciben un tratamiento secreto y confidencial por parte de <b>BANCO FORJADORES</b>, quien adopta para tal efecto las medidas necesarias para evitar su alteración, pérdida, tratamiento o acceso no autorizado de los datos recabados.<br/>Por su parte, el solicitante de crédito y Clientes declaran que sus datos son exactos, auténticos y completos y son responsabilidad de quien los proporciona, por lo que se libera a <b>BANCO FORJADORES</b> de cualquier responsabilidad relacionada al respecto. Los datos que los solicitantes de crédito y Clientes libre y voluntariamente proporcionan a <b>BANCO FORJADORES</b>, incluyen: datos de identificación, datos laborales, datos biométricos, datos patrimoniales, imágenes video grabadas con o sin sonido y referencias personales del Cliente.
                    <br/>
                    Si al momento de celebrar un contrato de crédito con <b>BANCO FORJADORES</b>, los Clientes, libremente deciden contratar un seguro con la Institución de Seguros que provea los mismos, <b>BANCO FORJADORES</b> recabará datos personales sensibles, exclusivamente por lo que se refiere a la causa u origen que dé lugar a la reclamación presentada por los Clientes relacionada con algún siniestro amparado por los seguros contratados, con la finalidad de transferirlos a la Institución de Seguros que proporcione dichos seguros.Como parte de las medidas de seguridad que <b>BANCO FORJADORES</b> implementa en cada una de sus Oficinas de Servicio, tomará video grabación, con o sin sonido, durante el horario en que las Oficinas de Servicio se encuentren abiertas al público. Las imágenes y/o sonidos que en su caso sean captados por las cámaras de video vigilancia serán utilizados para el resguardo de la seguridad de los Clientes y de las personas que laboran en las Oficinas de Servicio.
                    <br/><br/>
                    <b>EJERCICIO DE LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN (DERECHOS ARCO).</b>
                    <br/><br/>
                    Los Clientes serán responsables de la exactitud, veracidad, autenticidad y vigencia de sus datos. Los Clientes de <b>BANCO FORJADORES</b> tienen el derecho de 
                    <br/>(a) acceder a los datos personales que les fueron recabados;
                    <br/>(b) a rectificar sus datos, en caso de ser estos inexactos o incompletos;
                    <br/>(c) a cancelar sus datos cuando a su consideración no son necesarios para alguna de las finalidades señaladas en el presente Aviso de Privacidad, y legalmente sea procedente; y <br/>(d) a oponerse al tratamiento de dichos datos, en términos de la Ley y las disposiciones aplicables en la materia.
                    <br/><br/>
                    <b>REVOCACIÓN DEL CONSENTIMIENTO.</b>
                    <br/><br/>
                    En cualquier momento el Cliente podrá informar a <b>BANCO FORJADORES</b> su determinación de revocar el consentimiento que previamente se hubiere otorgado para el tratamiento de los datos personales de acuerdo con las finalidades descritas en el presente Aviso de Privacidad, a fin de que <b>BANCO FORJADORES</b> deje de hacer uso de los mismos y siempre y cuando el consentimiento que se pretenda revocar no derive de una relación jurídica contractual u operación pactada con <b>BANCO FORJADORES</b>, que la ley lo permita o que así se hubiere pactado expresamente entre <b>BANCO FORJADORES</b> y el Cliente. En todos los casos la revocación del consentimiento no tendrá efectos retroactivos. Los medios y el procedimiento para hacerlo efectivo se mencionan a continuación.
                    <br/><br/>
                    <b>MEDIOS Y PROCEDIMIENTO PARA EL EJERCICIO DE LOS DERECHOS ARCO Y PARA LA REVOCACIÓN DEL CONSENTIMIENTO.</b><br/><br/>
                    Los Clientes de <b>BANCO FORJADORES</b> podrán ejercer sus derechos ARCO y revocar su consentimiento para el tratamiento de sus datos personales, a través de la solicitud respectiva, que podrá ser obtenida en forma gratuita en las Oficinas de Servicio de <b>BANCO FORJADORES</b>, en la página de internet <a href="https://www.forjadores.mx">https://www.forjadores.mx</a> La solicitud deberá ser entregada en la Oficina de Servicio o ser enviada al correo electrónico: <a href="mailto:privacidad@forjadores.com.mx">privacidad@forjadores.com.mx</a> Dicha solicitud indicará los requisitos y el procedimiento para el ejercicio de los derechos ARCO y/o para la revocación del consentimiento para el tratamiento de sus datos personales, que a continuación se describe. Recibida la solicitud debidamente requisitada y una vez que se acredite la identidad del Cliente (titular de los datos personales); o bien, la representación legal del mismo por medio de la identificación oficial vigente y poder del representante legal (mismos que quedarán adjuntos al expediente). Una vez recibida la solicitud, <b>BANCO FORJADORES</b> dará respuesta a la misma en el término de 20 (veinte) días hábiles. La respuesta se pondrá a disposición del Cliente en la Oficina de Servicio de <b>BANCO FORJADORES</b> donde se hubiere presentado la solicitud o a través de su correo electrónico, dentro del plazo antes señalado. En caso de resultar procedente, <b>BANCO FORJADORES</b> cumplirá con lo solicitado dentro de los 15 (quince) días hábiles posteriores.
                    <br/><br/>
                    <b>LIMITACIÓN DEL USO O DIVULGACIÓN DE SUS DATOS PERSONALES.</b>
                    <br/><br/>
                    Cuando el tratamiento de sus datos personales no se refiera a aquellos necesarios para cumplir con una obligación legal o contractual (cumplimiento de las obligaciones legales derivadas de la relación jurídica existente entre el Cliente y <b>BANCO FORJADORES</b>), el Cliente tendrá el derecho de solicitar a <b>BANCO FORJADORES</b> la limitación en el uso y/o divulgación de sus datos por medio de la solicitud que <b>BANCO FORJADORES</b> le proporcionará, para que en caso de resultar procedente <b>BANCO FORJADORES</b> registre los datos personales del Cliente en los listados de exclusión dentro del término de 20 (veinte) días hábiles contados a partir de la presentación de la solicitud respectiva.
                    <br/><br/>
                    <b>TRANSFERENCIA DE DATOS PERSONALES POR BANCO FORJADORES.</b>
                    <br/><br/>
                    <b>BANCO FORJADORES</b> utiliza los datos personales de sus Clientes exclusivamente para los fines señalados en el presente Aviso de Privacidad y los conserva por el tiempo que las disposiciones legales señalan.
                    <b>BANCO FORJADORES</b> comparte y/o transfiere los datos personales de sus solicitantes de crédito y Clientes con terceros 
                    <br/> (a) cuando el Cliente contrata otro producto y/o servicio a través de <b>BANCO FORJADORES</b> 
                    <br/>(b) cuando <b>BANCO FORJADORES</b> cede y/o enajena la cartera de créditos; 
                    <br/>(c) para hacer requerimientos de pago a través de despachos de cobranza; 
                    <br/>(d) para la prestación de servicios; 
                    <br/>(e) cuando <b>BANCO FORJADORES</b> otorgue en garantía los derechos que a su favor se deriven de los Contratos celebrados; 
                    <br/>(f) para consulta y verificación de los datos e información otorgada por los Clientes; 
                    <br/>(g) para que los terceros le ofrezcan productos o servicios;
                    <br/>(h) con los que celebre contratos donde <b>BANCO FORJADORES</b> acuerde que dichos terceros tendrán la calidad de Encargados en términos de la Ley;
                    <br/>(i) para fines mercadotécnicos, publicitarios y de prospección comercial; 
                    <br/>(j) cuando así se requiera por disposición legal o por orden de autoridad competente debidamente fundada y motivada; y 
                    <br/>(k) en los supuestos señalados en el artículo 37 de la Ley. Fuera de los casos enunciados, usted tiene la confianza de que sus datos no serán transferidos a terceras personas sin su expreso consentimiento.
                    <br/>
                    
                    En todo caso <b>BANCO FORJADORES</b> garantiza que las finalidades del tratamiento de los datos personales del Cliente serán respetadas por los terceros receptores cuando se verifique alguna de las transferencias antes enunciadas. Los terceros receptores de los datos personales de los Clientes podrán ser, empresas que se dediquen o tengan un giro similar al de BANCO FORJADORES o bien personas físicas o morales con la que <b>BANCO FORJADORES</b> tenga una relación contractual, despachos de cobranza, entidades financieras (nacionales o extranjeras), Instituciones de Seguros y/o Fianzas, y personas físicas y morales que le presten servicios a <b>BANCO FORJADORES</b>
                    <br/>
                    Las finalidades de las transferencias antes enunciadas perseguirán (a) el cumplimiento de las disposiciones en materia de Datos Personales por parte de <b>BANCO FORJADORES</b>; (b) el mantenimiento y/o cumplimiento de las obligaciones y derechos contraídos por medio de los Contratos celebrados con los Clientes; (c) protección de los derechos de pago de <b>BANCO FORJADORES</b>; (d) la garantía de cualquier derecho derivado de los contratos que celebre <b>BANCO FORJADORES</b>; (f) protección de los derechos de los Clientes; y (g) publicidad y promoción de los productos financieros que <b>BANCO FORJADORES</b> ofrezca a sus Clientes.
                    <br/><br/>
                    
                    <b>CONSENTIMIENTO DE TRANSFERENCIA.</b>
                    <br/><br/>                
                    El Cliente manifiesta su conformidad para que <b>BANCO FORJADORES</b> pueda llevar a cabo las transferencias en los supuestos previstos en el presente Aviso de Privacidad, a los terceros receptores antes mencionados y por las finalidades enunciadas, por lo que el Cliente manifiesta su consentimiento expreso al momento en que se le entrega y firma el presente Aviso de Privacidad.
                    
                    <br/><br/>
                </p>
                <div class="inputGroup">
                    <input id="option1" name="option1" type="checkbox"/>
                    <label for="option1">Si acepto las transferencias de mis datos antes anunciadas:</label>
                </div>
                
                <p> Si usted considera que sus derechos han sido lesionados por alguna conducta de nuestros empleados o de nuestras actuaciones o respuestas, presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley, podrá interponer la queja o denuncia correspondiente ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos
                Personales (INAI), para mayor información visite <a href="https://home.inai.org.mx/">https://home.inai.org.mx/</a>
                <br/>
                <b>COMUNICACIÓN</b>.Cualquier cambio y/o modificación total o parcial al presente Aviso de Privacidad se le dará a conocer directamente en las Oficinas de Servicio de <b>BANCO FORJADORES</b>, así como a través de nuestro sitio en internet <a href="https://www.forjadores.mx">https://www.forjadores.mx</a>
                <br/><br/>                
                <strong>Fecha de actualización: Abril de 2024.</strong></p>
                <a target="_blank" rel="noopener"><b></b> Nombre y firma del cliente</a>
            </div>
            
            <div id="privacyContainer">
                <a href={Excel} target="_blank" rel="noopener"><b>FORMULARIO PARA EL EJERCICIO DE LOS DERECHOS ARCO</b> DA CLIC PARA VER FORMULARIO</a>
                <a href={PDF} target="_blank" rel="noopener"><b>GUÍA DE USUARIO PARA EL EJERCICIO DE DERECHOS ARCO</b> DA CLIC PARA VER FORMULARIO</a>
            </div>
        </div>
    );
}

export default AvisoPrivacidad;