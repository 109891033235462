import encabezado_inversiones from '../../assets/images/Encabezados/encabezado_inversiones.png';
import grafico_forjadores_cede from '../../assets/images/grafico-forjadores-cede.png';
import inversion_lineas_verde from '../../assets/images/Inversion_lineas_verde.png';
import inversion_logoIPAB from '../../assets/images/Inversion_logoIPAB.png';
import grafico_pagare_forjadores from '../../assets/images/grafico-pagare-forjadores.png';
import '../../style/inversiones.css';
import { useNavigate } from 'react-router-dom';

function Inversiones() {
	const navigate = useNavigate();

	return (
		<div className="inversiones">
			<section
				className="encabezado"
				style={{
					padding: '0px 5%',
				}}
			>
				<img
					src={encabezado_inversiones}
					alt="Encabezado inversiones"
				/>
			</section>
			<section className="invierte-con-responsabilidad">
				<h2 className="subtitulo-2">
					<b style={{ color: '#e2bd23' }}>INVIERTE</b> CON{' '}
					<b style={{ color: '#e2bd23' }}>RESPONSABILIDAD</b> SOCIAL Y
					CON SEGURIDAD
				</h2>
				<p className="simple-text">
					Tu dinero tiene que generar más dinero, en Banco Forjadores
					creemos en dos cosas, como banco que somos ofrecerte
					flexibilidad en pagos con rendimientos competitivos y
					segundo darte servicio y atención cumpliendo al máximo
					lineamientos de seguridad y responsabilidad social.
				</p>
			</section>
			<section>
				<h2 className="subtitulo-2">
					TE <b style={{ color: '#449b51' }}>OFRECEMOS</b> LAS
					SIGUIENTES OPCIONES DE {''}
					<b style={{ color: '#449b51' }}>INVERSIÓN</b> CON SEGURIDAD
				</h2>
				<div className="row inversiones-list">
					<div className="inversion">
						<img
							src={grafico_forjadores_cede}
							alt="Grafico forjadores cede"
						/>
						<h3 class="subtitulo-1">
							<b>PAGARÉ </b>Forjadores<small>®</small>
						</h3>
						<button
							onClick={() => {
								navigate('/inversiones/pagare-forjadores/');
							}}
							type="submit"
							class="forjadores-btn"
						>
							Mas información
						</button>
					</div>
					<div className="inversion">
						<img
							src={grafico_pagare_forjadores}
							alt="Grafico forjadores cede"
						/>
						<h3 class="subtitulo-1">
							<b>Forjadores </b>CEDE<small>®</small>
						</h3>
						<button
							onClick={() => {
								navigate('/inversiones/forjadores-cede/');
							}}
							type="submit"
							class="forjadores-btn"
						>
							Mas información
						</button>
					</div>
				</div>
				<p
					className="simple-text"
					style={{
						textAlign: 'center',
					}}
				>
					<big style={{ color: '#449b51' }}>
						COMISIONES GENERADAS POR PRODUCTOS DE INVERSIÓN <br />
						(PRODUCTOS FINANCIEROS EN PRLV y CEDE)
					</big>
					<br />
					<b style={{ color: '#e2bd23' }}>•</b> No aplica comisión
					para los productos de PRLV y CEDE
					<br />
					<b style={{ color: '#e2bd23' }}>•</b> Tasas de Interés antes
					de Impuestos
				</p>
			</section>
			<img
				src={inversion_lineas_verde}
				alt="inversion lineas verdes"
				className="inversion-lineas-verdes"
			/>
			<div className="requisitos">
				<h3 class="subtitulo-3">REQUISITOS:</h3>
				<p
					class="simple-text green-dots"
					style={{ marginBottom: '50px' }}
				>
					<b>•</b> Ser mayor de edad.
					<br />
					<b>•</b> Contar con identificación oficial vigente y
					comprobante de domicilio (no mayor a tres meses)
					<br />
					<b>•</b> Suscribir el contrato de adhesión correspondiente y
					hacer la transferencia a la cuenta que Banco Forjadores te
					proporcione.
				</p>

				<h3 className="subtitulo-3">CONCEPTOS DEL PRODUCTO:</h3>
				<p className="simple-text green-dots">
					<b>Banco Forjadores.-</b> Banco Forjadores S.A., Institución
					de Banca Múltiple, que para su constitución como tal,
					requirió autorización de la Secretaria de Hacienda y Crédito
					Público, con opinión del Banco de México y su supervisión
					está regulada por la Comisión Nacional Bancaria y de Valores
					<br />
					<br />
					<b>Pagaré Forjadores.-</b> Inversión que permite ganar
					atractivos rendimientos en función al plazo y monto que se
					elija, al vencimiento que se decida si el capital y los
					intereses se reinvierten o se depositan a la cuenta bancaria
					que se haya proporcionado.
					<br />
					<br />
					<b>Forjadores CEDE.-</b> Inversión flexible que permite
					disponer de los intereses generados por el dinero mes a mes.
					Al término del plazo pactado el capital se deposita a la
					cuenta bancaria que se haya proporcionado.
					<br />
					<br />
					<b>Comisión.-</b> Para este producto no se aplica comisión
					alguna
					<br />
					<br />
					<b>UDI.-</b> Es un valor de referencia de valor real
					constante que el Banco de México publica en el Diario
					Oficial de la Federación el valor, en moneda nacional, de la
					unidad de inversión, para cada día del mes
					<br />
					<br />
					<b>GAT.-</b> La Ganancia Anual Total Neta expresada en
					términos porcentuales anuales, para fines informativos y de
					comparación, incorpora los intereses nominales
					capitalizables que generen las operaciones pasivas a plazo,
					retirables en días preestablecidos y de ahorro, que celebren
					las instituciones de crédito y las entidades de ahorro y
					crédito popular con sus clientes, menos todos los costos
					relacionados con la operación, incluidos los de apertura, de
					conformidad con las disposiciones que al efecto emita el
					Banco de México
					<br />
					<br />
					<b>Tasa de Interés.-</b> Es la tasa de rendimiento,
					expresada en términos anuales simples, la cual es fija
					durante el plazo de la inversión antes de impuestos
					<br />
					<br />
					<b>IPAB.-</b> Instituto para la Protección al Ahorro
					Bancario
				</p>
			</div>

			<img
				style={{ width: '100%', marginBottom: '50px' }}
				decoding="async"
				src={inversion_lineas_verde}
				data-ll-status="loaded"
			/>

			<div className="row ipab">
				<img
					style={{
						height: '130px',
						marginLeft: 'auto',
					}}
					decoding="async"
					src={inversion_logoIPAB}
					data-ll-status="loaded"
				/>
				<p className="simple-text" style={{ margin: '0' }}>
					El instituto de Protección al Ahorro Bancario le protege al
					garantizar las operaciones bancarias hasta por 400 mil UDIS
					por persona por institución más información en la página:{' '}
					<a
						href="https://www.gob.mx/ipab"
						target="_blank"
						rel="noopener"
					>
						https://www.gob.mx/ipab
					</a>
				</p>
			</div>
			<p className="simple-text center green-dots">
				** 400 mil UDIS equivalen a $3,283,337.60 al 01 de agosto de
				2024
				<br />
				<br />
				Productos garantizados por el IPAB:
				<br />
				<br />
				<b>•</b> Pagaré Forjadores
				<br />
				<b>•</b> Forjadores CEDE
			</p>
		</div>
	);
}

export default Inversiones;
