import encabezado_pagare_forjadores from '../../assets/images/Encabezados/encabezado_pagare_forjadores.png';
import icono_correo_contactanos from '../../assets/images/Iconos/icono_correo_contactanos.png';
import icono_whats_contactanos from '../../assets/images/Iconos/icono_whatsapp.png';
import individual_lineas_contrata from '../../assets/images/Individual_lineas_contrata.png';
import inversion_lineas3 from '../../assets/images/Inversion_lineas3.png';
import inversion_lineas_verde from '../../assets/images/Inversion_lineas_verde.png';
import inversion_logoIPAB from '../../assets/images/Inversion_logoIPAB.png';

function PagareForjadores() {
	return (
		<div className="pagare-forjadores">
			<section
				className="encabezado"
				style={{
					padding: '0px 5%',
				}}
			>
				<img
					src={encabezado_pagare_forjadores}
					alt="Encabezado inversiones"
				/>
			</section>
			<section
				style={{
					padding: '0 5%',
				}}
			>
				<h2 class="subtitulo-1">
					<b>PAGARÉ </b>Forjadores<small>®</small>
				</h2>
				<p
					className="simple-text full-width"
				>
					Pagaré forjadores es una inversión que te permite ganar
					atractivos rendimientos en función al plazo y monto que
					elijas, al vencimiento tú decides si el capital y los
					intereses los reinviertes o se depositan a la cuenta
					bancaria que nos hayas proporcionado.
				</p>

				<h3 className="subtitulo-2">RENDIMIENTO FORJADORES</h3>
				<p className="simple-text center" style={{ fontSize: '14px' }}>
					Plazo desde 28, 56, 91, 182, 280 y hasta 360 días
				</p>
				<div
					className="table-container"
					style={{
						marginBottom: '76px',
					}}
				>
					<div
						className="table-scroll"
						style={{
							marginBottom: '22px',
						}}
					>
						<table className="simple-table mb-10" border="1">
							<thead>
								<tr>
									<th colSpan="2">
										Monto minimo de inversión
									</th>
									<th className="text-2xl" colSpan="3">
										$25,000.00 MXN
									</th>
									<th className="text-2xl" colSpan="3">
										$500,000.00 MXN
									</th>
								</tr>
								<tr>
									<td colSpan="2"></td>
									<td>
										<b className="font-bold">
											TASA FIJA ANUAL
										</b>
									</td>
									<td>
										<b className="font-bold">
											Gat
											<br /> Nominal
										</b>
									</td>
									<td>
										<b className="font-bold">
											Gat
											<br /> Real
										</b>
									</td>
									<td>
										<b className="font-bold">
											TASA FIJA ANUAL
										</b>
									</td>
									<td>
										<b className="font-bold">
											Gat
											<br /> Nominal
										</b>
									</td>
									<td>
										<b className="font-bold">
											Gat
											<br /> Real
										</b>
									</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td colSpan="2">28 días</td>
									<td className="font-bold">8.00%</td>
									<td>8.30%</td>
									<td>4.35%</td>
									<td className="font-bold">8.60%</td>
									<td>8.84%</td>
									<td>4.87%</td>
								</tr>
								<tr>
									<td colSpan="2">56 días</td>
									<td className="font-bold">8.25%</td>
									<td>8.54%</td>
									<td>4.58%</td>
									<td className="font-bold">8.75%</td>
									<td>9.08%</td>
									<td>5.10%</td>
								</tr>
								<tr>
									<td colSpan="2">91 días</td>
									<td className="font-bold">8.65%</td>
									<td>8.93%</td>
									<td>4.96%</td>
									<td className="font-bold">9.15%</td>
									<td>9.47%</td>
									<td>5.47%</td>
								</tr>
								<tr>
									<td colSpan="2">182 días</td>
									<td className="font-bold">9.40%</td>
									<td>9.62%</td>
									<td>5.62%</td>
									<td className="font-bold">9.90%</td>
									<td>10.14%</td>
									<td>6.12%</td>
								</tr>
								<tr>
									<td colSpan="2">280 días</td>
									<td className="font-bold">10.00%</td>
									<td>10.11%</td>
									<td>6.09%</td>
									<td className="font-bold">10.60%</td>
									<td>10.62%</td>
									<td>6.58%</td>
								</tr>
								<tr>
									<td colSpan="2">360 días</td>
									<td className="font-bold">10.70%</td>
									<td>10.70%</td>
									<td>6.66%</td>
									<td className="font-bold">11.20%</td>
									<td>11.20%</td>
									<td>7.14%</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p className="simple-text center">
						Vigencia del 09 de septiembre 2024 al 15 de septiembre
						del 2024. Tasas señaladas antes de impuestos.
						<br />
						Fecha de cálculo de la GAT y GAT Real es al 02 de
						septiembre 2024 y vigentes al 30 de septiembre del 2024.
					</p>
				</div>

				<div>
					<h2 className="title-2">GAT Y VIGENCIA</h2>
					<p className="simple-text">
						<b>Ganancia Anual Total Nominal (GAT Nominal):</b> La
						Ganancia Anual Total Nominal se encuentra expresada en
						términos porcentuales anuales. 10.70% (antes de
						impuestos), para inversiones a partir de $25,000 pesos
						mexicanos en plazos de 360 días, calculada al 02 de
						septiembre de 2024.
					</p>
					<p className="simple-text">
						<b>•</b> Información sujeta a cambios sin previo aviso.
					</p>
					<p className="simple-text">
						<b>Ganancia Anual Total Real (GAT Real):</b>La Ganancia
						Anual Total Real se encuentra expresada en términos
						porcentuales anuales. La GAT Real es el rendimiento que
						obtendrías después de descontar la inflación estimada
						6.69% (antes de impuestos), para inversiones a partir de
						$25,000 pesos mexicanos en plazos de 360 días, calculada
						al 02 de septiembre de 2024.
					</p>
					<p className="simple-text">
						<b>•</b> Vigentes al 30 de septiembre de 2024.
					</p>
					<p className="simple-text">
						<b>•</b> Este producto se oferta en todas las oficinas,
						consulta su ubicación en la parte superior de esta
						página.
					</p>
					<p className="simple-text">
						<b>•</b> Estos productos no tienen comisiones.
					</p>
					<p
						className="simple-text"
						style={{ fontSize: '14px', marginBottom: '50px' }}
					>
						Vigencia a partir del 02 de septiembre de 2024 al 30 de
						septiembre de 2024
					</p>
				</div>
			</section>
			<h2 class="banner">
				<b>INVIERTE </b> EN BANCO <br /> FORJADORES
			</h2>
			<section
				className="row contact-container"
				style={{
					padding: '5%',
				}}
			>
				<div className="info-container">
					<h2>
						¡Elige la opción que más se adecue a tus necesidades!
					</h2>

					<div>
						<p className="info-title">Llámanos al</p>
						<a className="highlighted" href="tel:8008300005">
							800 830 0005
						</a>
					</div>

					<img
						style={{ margin: '-3.5em 0 0.6em' }}
						decoding="async"
						src={individual_lineas_contrata}
						data-ll-status="loaded"
					/>

					<div>
						<a
							className="highlighted"
							href="https://api.whatsapp.com/send?phone=5215580507824"
							target="_blank"
							rel="noopener"
						>
							<img
								className="info-icon"
								decoding="async"
								src={icono_whats_contactanos}
								data-ll-status="loaded"
							/>
							55 8039 8668
						</a>
						<a
							className="highlighted"
							href="mailto:atencionaclientes@forjadores.com.mx"
						>
							<img
								className="info-icon"
								decoding="async"
								src={icono_correo_contactanos}
								data-ll-status="loaded"
							/>
							atencion@forjadores.com.mx
						</a>
						<p className="simple-text">
							Déjanos tus datos y empieza desde hoy tu proceso
						</p>
					</div>
				</div>
				<div className="form-container">
					<form>
						<div className="row" style={{ gap: '25px' }}>
							<div className="form-item">
								<label htmlFor="nombre">
									Nombre
									<span className="erf-required">*</span>
								</label>
								<input
									type="text"
									id="nombre"
									name="nombre"
									required
								/>
							</div>

							<div className="form-item">
								<label htmlFor="apellido">
									Apellido
									<span className="erf-required">*</span>
								</label>
								<input
									type="text"
									id="apellido"
									name="apellido"
									required
								/>
							</div>
						</div>

						<div className="row" style={{ gap: '25px' }}>
							<div className="form-item">
								<label htmlFor="email">
									Email
									<span className="erf-required">*</span>
								</label>
								<input
									type="text"
									id="email"
									name="email"
									required
								/>
							</div>

							<div className="form-item">
								<label htmlFor="telefono">
									Teléfono
									<span className="erf-required">*</span>
								</label>
								<input
									type="tel"
									id="telefono"
									name="telefono"
									required
								/>
							</div>
						</div>

						<div className="row" style={{ gap: '25px' }}>
							<div className="form-item">
								<label htmlFor="estado">
									Estado
									<span className="erf-required">*</span>
								</label>
								<input
									type="text"
									id="estado"
									name="estado"
									required
								/>
							</div>

							<div className="form-item">
								<label htmlFor="municipio">
									Municipio
									<span className="erf-required">*</span>
								</label>
								<input
									type="tel"
									id="municipio"
									name="municipio"
									required
								/>
							</div>
						</div>
						<div className="form-item" style={{ width: '100%' }}>
							<label htmlFor="mensaje">
								Mensaje
								<span className="erf-required">*</span>
							</label>
							<textarea
								style={{ height: 'unset !important' }}
								rows="5"
								id="mensaje"
								required
							></textarea>
						</div>

						<button type="submit" className="forjadores-btn">
							Enviar
						</button>
					</form>
				</div>
			</section>
			<img
				src={inversion_lineas3}
				alt="lienas"
                className='inversion-lineas-3'
			/>
			<section className="requisitos">
				<h3 class="subtitulo-3">REQUISITOS:</h3>
				<p
					class="simple-text green-dots"
					style={{ marginBottom: '50px' }}
				>
					<b>•</b> Ser mayor de edad.
					<br />
					<b>•</b> Contar con identificación oficial vigente y
					comprobante de domicilio (no mayor a tres meses)
					<br />
					<b>•</b> Suscribir el contrato de adhesión correspondiente y
					hacer la transferencia a la cuenta que Banco Forjadores te
					proporcione.
				</p>

				<h3 className="subtitulo-3">CONCEPTOS DEL PRODUCTO:</h3>
				<p className="simple-text green-dots">
					<b>Banco Forjadores.-</b> Banco Forjadores S.A., Institución
					de Banca Múltiple, que para su constitución como tal,
					requirió autorización de la Secretaria de Hacienda y Crédito
					Público, con opinión del Banco de México y su supervisión
					está regulada por la Comisión Nacional Bancaria y de Valores
					<br />
					<br />
					<b>Pagaré Forjadores.-</b> Inversión que permite ganar
					atractivos rendimientos en función al plazo y monto que se
					elija, al vencimiento que se decida si el capital y los
					intereses se reinvierten o se depositan a la cuenta bancaria
					que se haya proporcionado.
					<br />
					<br />
					<b>Forjadores CEDE.-</b> Inversión flexible que permite
					disponer de los intereses generados por el dinero mes a mes.
					Al término del plazo pactado el capital se deposita a la
					cuenta bancaria que se haya proporcionado.
					<br />
					<br />
					<b>Comisión.-</b> Para este producto no se aplica comisión
					alguna
					<br />
					<br />
					<b>UDI.-</b> Es un valor de referencia de valor real
					constante que el Banco de México publica en el Diario
					Oficial de la Federación el valor, en moneda nacional, de la
					unidad de inversión, para cada día del mes
					<br />
					<br />
					<b>GAT.-</b> La Ganancia Anual Total Neta expresada en
					términos porcentuales anuales, para fines informativos y de
					comparación, incorpora los intereses nominales
					capitalizables que generen las operaciones pasivas a plazo,
					retirables en días preestablecidos y de ahorro, que celebren
					las instituciones de crédito y las entidades de ahorro y
					crédito popular con sus clientes, menos todos los costos
					relacionados con la operación, incluidos los de apertura, de
					conformidad con las disposiciones que al efecto emita el
					Banco de México
					<br />
					<br />
					<b>Tasa de Interés.-</b> Es la tasa de rendimiento,
					expresada en términos anuales simples, la cual es fija
					durante el plazo de la inversión antes de impuestos
					<br />
					<br />
					<b>IPAB.-</b> Instituto para la Protección al Ahorro
					Bancario
				</p>
			</section>

			<img
				style={{ width: '100%' }}
				decoding="async"
				src={inversion_lineas_verde}
				data-ll-status="loaded"
			/>

			<section
			>
				<div className="row ipab">
					<img
						style={{
							height: '130px',
							marginLeft: 'auto',
						}}
						decoding="async"
						src={inversion_logoIPAB}
						data-ll-status="loaded"
					/>
					<p className="simple-text" style={{ margin: '0' }}>
						El instituto de Protección al Ahorro Bancario le protege
						al garantizar las operaciones bancarias hasta por 400
						mil UDIS por persona por institución más información en
						la página:{' '}
						<a
							href="https://www.gob.mx/ipab"
							target="_blank"
							rel="noopener"
						>
							https://www.gob.mx/ipab
						</a>
					</p>
				</div>
				<p className="simple-text center green-dots">
					** 400 mil UDIS equivalen a $3,300,431.20 al 02 de
					septiembre de 2024
					<br />
					<br />
					Productos garantizados por el IPAB:
					<br />
					<br />
					<b>•</b> Pagaré Forjadores
					<br />
					<b>•</b> Forjadores CEDE
				</p>
			</section>
		</div>
	);
}

export default PagareForjadores;
