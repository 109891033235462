import cerrar_modal_icon from '../../assets/images/Iconos/cerrar-modal-icon.png';
import '../../style/modal.css';

function Modal({ open, setOpen, children }) {
	return (
		<div className={(open ? 'show' : 'hide') + ' modal'}>
			<div
				onClick={() => {
					setOpen(false);
				}}
				className="modal-overlay"
			></div>
			<div className="modal-content">
				<button
					onClick={() => {
						setOpen(false);
					}}
					className="row close-button"
				>
					Salir
					<img src={cerrar_modal_icon} alt="Cerrar modal" />
				</button>
				{children}
			</div>
		</div>
	);
}

export default Modal;
