import { useNavigate, Link } from 'react-router-dom';
import banner from '../../../assets/images/Encabezados/encabezado_consumo_responsable.jpg';
import graf1 from '../../../assets/images/quees_consumidor_responsable.png';
import graf2 from '../../../assets/images/como_consumir.png';
import graf3 from '../../../assets/images/gastos_inncesarios.png';
import lineas from '../../../assets/images/lineas_1-1024x59.png';
import lineas2 from '../../../assets/images/Ahorro_lineas7-1024x51.png';

import '../../../style/quienessomos.css';

function Consumo() {
    return (
        <div className="type-post">
            <div class="entry-content">
                <div className='banner-postss'>
                    <img src={banner} className='' />
                </div>
                {/* <figure className='wp-block-image size-full'>
                    <img src={banner} decoding="async" width="1920" height="800" alt='' className='lazyloaded' />
                </figure> */}

                <figure className='wp-block-image size-full size-large post-p-right'>
                    <img src={graf1} decoding="async" width="434" height="460" alt='' className='lazyloaded' />
                </figure>
                <p class="post-img-left"><strong>¿Qué es ser un consumidor responsable?</strong><br/><br/>Es una persona informada y consciente de sus hábitos de consumo, así sus compras jamás causaran daño a la economía familiar, no perjudicaran el entorno ambiental, y buscará que tengan un efecto positivo en la sociedad.<br/><br/> <strong>¿Y el consumidor crítico que es?</strong><br/><br/>Es aquél que implica consumir menos, consumir solo lo necesario y estar atentos a cómo influye la publicidad en la creación de necesidades superfluas.</p>

                <figure className='wp-block-image size-large size-full post-img-line'>
                    <img src={lineas} decoding="async" width="1024" height="59" alt='' className='lazyloaded' />
                </figure>
                
                <p><strong>¿Qué significa consumir responsablemente?</strong><br/><br/>Racionalizar, esto es aspirar a productos verdaderamente necesarios, analizando a detalle las ventajas que ofrece el producto o servicio con el fin de comprar utilidad y no solo adquirir por consumir.</p>
                
                <figure className='wp-block-image size-full post-img-centered'>
                    <img src={graf2} decoding="async" width="798" height="374" alt='' className='lazyloaded' />
                </figure>
                
                <p><strong>Presupuesto familiar</strong></p>
                <p><strong>Utilizando el modelo de las 5 “Erres R”</strong></p>
                <p><strong>1. Rechazar</strong><br/><br/>Primero decidir si el producto es necesario, si se requiere en el hogar o en nuestra vida diaria. Por otro lado, los productos que no sean biodegradables, que sean tóxicos o que no sean reciclables deben quedar fuera de nuestra lista de consumo diario.</p>
                <p><strong>2. Reducir</strong><br/><br/>Antes de adquirir un producto es necesario verificar que el artículo no tenga empaquetado excesivo, se tiene que procurar comprar los tamaños grandes y productos concentrados para generar menos basura.</p>
                <p><strong>3. Reusar</strong><br/><br/>Significa prolongar la vida útil de los bienes, esto contribuye a disminuir el impacto ambiental al desechar dichos bienes.</p>
                <p><strong>4. Reformar</strong><br/><br/>Implica transformar un producto que ya fue utilizado tal y como si fuera nuevo y dejarlo listo para un nuevo uso.</p>
                <p><strong>5. Reciclar</strong><br/><br/>Significa separa los residuos de manera adecuada, para posteriormente ser utilizados en la elaboración de nuevos productos, con ello se evita la extracción de nuevas materias primas y se reduce el consumo de energía en la elaboración de las mismas.</p>
                <p><strong>¿Qué son los gastos innecesarios?</strong></p>
                <p><br/>Son todas aquellas compras comunes, que generalmente se hacen solo para satisfacer un antojo puntual y no una necesidad real.</p>
                <p><br/><strong>¿Y el consumidor crítico que es?</strong><br/><br/>Antes que nada, tienes que identificar si lo que vas a comprar realmente satisface una necesidad o es solo deseo por tenerlo.​</p>
                
                <ul class="post-list-half"><li>Haz un presupuesto</li><li>Compara precios</li><li>Organízate</li></ul>

                <figure className='wp-block-image size-large'>
                    <img src={graf3} decoding="async" width="562" height="450" alt='' className='lazyloaded' />
                </figure>

                <figure className='wp-block-image size-large size-full post-img-line'>
                    <img src={lineas2} decoding="async" width="1024" height="51" alt='' className='lazyloaded' />
                </figure>
                
                <p><strong>¿Cómo identifico gastos innecesarios?</strong></p>
                <ul class="post-list-half"><li>No comprar productos solo por su marca.</li><li>Comer en la calle o en restaurantes.</li><li>Ir al súpermercado todos los días.</li><li>Botanas de ocio (café, papitas, etc.).</li><li>Descuidar las comisiones de tu banco.</li><li>Utilizar cajeros de otros bancos.</li><li>Comprar algo por moda.</li><li>Tener una TV en cada habitación.</li></ul>
                <ul class="post-list-half"><li>Tener luces de casa encendidas sin necesidad.</li><li>Comprar artículos “porque están de oferta”</li><li>Salidas constantes con amigos.</li><li>Multas de tránsito.</li><li>Comprar un auto nuevo ( en 3 años pierden<br/>el 46% de su valor).</li><li>Comprar teléfonos de alta gama en cuotas.</li><li>Comprar en exceso “a crédito”.</li></ul>
                <p><strong>¿Cómo evito gastar demás?</strong></p>
                <ul class="post-list-half"><li>Comprar marcas genéricas (ropa o medicinas).</li><li>Tratas de resolver arreglos simples de tu auto.</li><li>Utilizas los cajeros de tu banco y revisas los<br/>estados de cuenta.</li><li>Dejas vicios como el cigarro o alcohol.</li><li>Buscas reseñas de usuarios que ya han<br/>comprado un producto.</li></ul>
                <ul class="post-list-half"><li>Cambias el transporte por bicicleta y autobús.</li><li>Utilizas solo una línea móvil de telefonía.</li><li>Mejoras los hábitos de consumo de energía.</li><li>Cocinas más en casa y compras menos botanas.</li></ul>

                <figure className='wp-block-image size-large size-full post-img-line'>
                    <img src={lineas2} decoding="async" width="1024" height="51" alt='' className='lazyloaded' />
                </figure>

                <p class="post-closure">Recuerda aplicar las <strong>5 Erres R</strong> así no nada más cuidas tu bolsillo sino también el medio ambiente<br/>Además, te conviertes en un <strong>consumidor&nbsp; responsable.</strong></p>

            </div>
            <nav class="pagination-single section-inner only-one only-next" aria-label="Publicación">
                <hr class="styled-separator is-style-wide" aria-hidden="true"/>
                <div class="pagination-single-inner">
                    <Link className="next-post" to="/tips-para-mejorar-tu-negocio/seguros/">
                        <span class="title"><span class="title-inner">SEGUROS</span></span>
                        <span class="arrow" aria-hidden="true">→</span>
                    </Link>
                </div>
                <hr class="styled-separator is-style-wide" aria-hidden="true"/>
            </nav>
        </div>
    );
}

export default Consumo;