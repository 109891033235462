import encabezado from '../../assets/images/Encabezados/nuestra_historia.png';
import lineas_nuestra_historia from '../../assets/images/lineas_nuestra_historia.png';

import '../../style/quienessomos.css';

function NuestraHistoria() {
    return (
        <div className="NuestraHistoria">            
            <div className="aboutTimeline">
                <h2>NUESTRA HISTORIA</h2>
                <img src={encabezado} />
                    <div className="container">
                    <p><big>2005</big>Se constituye legalmente Forjadores de Negocios en la Ciudad de México como <b>SOFOM ENR.</b></p>
                    <p><big>2007</big><b>Grupo ACP</b> se convierte en el principal accionista.</p>
                    <p><big>2011</big>Contamos con <b>500 colaboradores</b>, <b>30 Oficinas de Servicio</b> y <b>60,000 clientes</b>.</p>
                    <p><big>2012/2013</big>Conversión de SOFOM ENR a <b>Banco</b>.</p>
                    <p><big>2015</big>Incorporación a la <b>Asociación de Bancos de México</b>.</p>
                    <p><big>2016</big>Estamos presentes en 10 Estados de la República Mexicana contando con 49 Oficinas de Servicio y más de <b>800 colaboradores</b>.</p>
                    <p><big>2017</big>Apertura de <b>Oficinas de Crédito Individual</b> con un total de <b>57 Oficinas de Servicio</b>.</p>
                    <p><big>2019</big>Enfoque de oficinas de Servicio de Crédito <b>multiproducto</b> con mayor eficiencia con un total de <b>51 Oficinas de Servicio</b>.</p>
                    <p><big>2020</big>Nuevos productos:<br/><b>•</b> Luchador<br/><b>•</b> Industrial<br/><b>•</b> Garantía hipotecaria<br/><b>•</b> Persona Física y Moral</p>
                    </div>
                <img src={lineas_nuestra_historia} className='lineas' />
            </div>
        </div>
    );
}

export default NuestraHistoria;