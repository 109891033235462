import { useNavigate, Link } from 'react-router-dom';
import banner from '../../../assets/images/Encabezados/encabezado_ahorro.jpg';
import cerdo1 from '../../../assets/images/Ahorro_definiciondeahorro.png';
import cerdo2 from '../../../assets/images/Ahorro_grafico_cuantodebo.png';
import lineas from '../../../assets/images/lineas_1.png';
import lineas2 from '../../../assets/images/Ahorro_lineas2.png';
import lineas3 from '../../../assets/images/lineas_2_frase-1024x52.png';
import lineas4 from '../../../assets/images/Ahorro_lineas5-1024x35.png';
import lineas5 from '../../../assets/images/Ahorro_lineas6-1024x46.png';
import lineas6 from '../../../assets/images/Ahorro_lineas7-1024x51.png';

import '../../../style/quienessomos.css';

function Ahorro() {
    return (
        <div className='type-post'>
            <div className="entry-content">
                <div className='banner-postss'>
                    <img src={banner} className='' />
                </div>
                {/* <figure className='wp-block-image size-full'>
                    <img src={banner} className=' lazyloaded' decoding="async" width="1920" height="773" alt='' />
                </figure> */}
                
                <figure className='wp-block-image size-full size-large post-p-right'>
                    <img src={cerdo1} className='lazyloaded' decoding="async" width="466" height="424" alt='' />
                </figure>

                <p className="post-img-left"><strong>Definición de Ahorro</strong><br /><br />Es aquella parte del ingreso que no se destina al consumo de bienes o servicios. Es la diferencia que existe entre los ingresos y los gastos. Es decir, el monto de los ingresos que no se gastó y que se puede guardar para usarlo en el futuro.<br /><br />Entonces, reserva ese capital fuera cualquier riesgo para cubrir una necesidad o contingencia futura. Incluso, puede dejarse como <strong>herencia.</strong></p>

                <figure className='wp-block-image size-full post-img-line'>
                    <img src={lineas} loading="lazy" decoding="async" width="1920" height="110" alt='' />
                </figure>

                <p><strong>El objetivo de ahorrar es:</strong></p>

                <ul class="post-list-half">
                    <li>El hábito de guardar una parte de nuestro ingreso para conseguir una meta.<br />&nbsp;</li>
                    <li>Cumplir un sueño.<br />&nbsp;</li>
                    <li>Crear un fondo que nos permita enfrentar alguna emergencia.<br />&nbsp;</li>
                    <li>Construir una casa.<br />&nbsp;</li>
                    <li>Pagar la&nbsp; educación propia o de los hijos.<br />&nbsp;</li>
                    <li>Enfrentar gastos inesperados como enfermedades o accidentes.<br />&nbsp;</li>
                    <li>Contar con un fondo para vivir la vejez con tranquilidad.<br />&nbsp;</li>
                    <li>Acumular bienes y minimizar los riesgos y controlar los ingresos.<br />&nbsp;</li>
                </ul>

                <p>Hay muchas razones para ahorrar ayudan a las familias a alcanzar sus metas financieras, a cumplir sueños, a enfrentar alguna emergencia.</p>

                <figure className='wp-block-image size-full size-large post-img-line'>
                    <img src={lineas2} loading="lazy" decoding="async" width="1920" height="105" alt='' />
                </figure>

                <p><big><strong>Ahorro</strong></big></p>

                <p>El ahorro es un elemento muy importante de una familia, pero solo se consigue a través de una buena planificación patrimonial. En muchos casos es necesario acudir a un asesor financiero.<br /><br />El ahorro fortalece el bienestar de las familias, incrementa la estabilidad económica personal, familiar y patrimonial.También permite una distribución más eficiente del consumo a través del tiempo.<br /><br />Una vez que se tuvo la disciplina para hacer un presupuesto, de la misma forma se debe tener la disciplina para poder ahorrar.<br /><br />El ahorro es la parte del ingreso actual que se guarda en el presente para poderlo usar en el futuro.</p>

                <p class="post-title-greenbg"><strong>Carácteristicas</strong></p>

                <p class="post-step"><strong>1</strong><br />Debe tratarse de algo material, por ejemplo un coche, vivienda, negocio o algún artículo que tengamos en casa, también puede ser inmaterial, es decir, un perjuicio económico por paralización de actividad, como cuando sucede alguna enfermedad.</p>

                <p class="post-step"><strong>2</strong><br />Debe existir un período de tiempo en el contrato, en el cual se puedan manifestar los riesgos.</p>

                <p><strong>¿Cómo se ahorra?</strong></p>

                <p class="post-step">Muchas personas acostumbran guardar su dinero debajo del colchón, en una alcancía o incluso confiárselo a otra persona. Esta forma de guardar al dinero se le conoce como ahorro informal. No es la forma más conveniente de ahorrar, ya que nos arriesgamos a perder nuestro dinero.</p>

                <p class="post-step">La segunda alternativa consiste en guardar nuestros recursos en una institución financiera, a través de una cuenta de ahorro. Esto se le conoce como ahorro formal y tiene varias ventajas, como, brinda seguridad y también certeza. No hay pérdida del valor adquisitivo del dinero. La mayoría de las cuentas bancarias de ahorro están protegidas con un seguro de depósito.</p>

                <figure className='wp-block-image size-large size-full post-img-centered'>
                    <img src={cerdo2} loading="lazy" decoding="async" width="753" height="444" alt=''/>
                </figure>

                <p><strong>¿Y cuánto debo ahorrar?</strong></p>

                <p>Lo ideal es guardar cantidades de acuerdo a nuestra capacidad. Aunque no existe una regla escrita de cuánto debemos ahorrar, la práctica recomendable es un 10% de forma mensual. Sin embargo habrá ocasiones en las que el ahorro sea mayor cuando establezcamos alguna meta prioritaria y el monto del ahorro sea mayor durante un plazo de tiempo más corto.</p>

                <p>A lo largo de nuestra vida laboral es necesario ahorrar, ya que en el momento del retiro los ingresos personales cesarán y con el monto ahorrado tendremos que hacerle frente al resto de la vida.</p>

                <p><strong>¿Qué importancia tiene el ahorro?</strong><br />La expectativa de vida en México es de 77 años y en América Latina es de 74.Si pensamos que el retiro de la vida laboral se puede dar alrededor de los 60 años, ¿qué fuentes de ingresos tienes para poder vivir hasta el fin de tus días? Por eso es necesario ahorrar mientras trabajas, para asegurar el futuro. Se puede ahorrar de cualquier manera:<br /></p>

                <ul class="post-list-half">
                    <li>Una alcancía<br />&nbsp;</li>
                    <li>Colchón<br />&nbsp;</li>
                    <li>Cuenta de ahorro<br />&nbsp;</li>
                    <li>Caja de ahorro y préstamo<br />&nbsp;</li>
                    <li>Tandas</li>
                </ul>

                <p>Los instrumentos bancarios son una opción formal de ahorro, son más seguros y se puede disponer de los ahorros fácilmente. No existe el riesgo de perder los ahorros a comparación del colchón o la alcancía, ya que se cuenta con el respaldo de una institución financiera, que va a ser regulada y supervisada.</p>

                <p><strong>¿Cuál de estas alternativas es mejor para ti?</strong><br /><br /><strong>¿El colchón, la alcancía, o la cuenta de ahorro?</strong></p>

                <figure className='wp-block-image size-large size-full post-img-line'>
                    <img src={lineas3} loading="lazy" decoding="async" width="1024" height="52" alt='' />
                </figure>

                <p><strong>Buenas prácticas del ahorro</strong></p>

                <ul class="post-list-half"><li>Distribuir de forma racional el ingreso.<br />&nbsp;</li><li>Realizar un presupuesto mensual y anual.<br />&nbsp;</li><li>No guardar el dinero en casa debajo del colchón.<br />&nbsp;</li><li>Es mejor abrir una cuenta de ahorros. Comparar entre diferentes instituciones financieras para abrir una cuenta de ahorro. Buscar la tasa de interés más competitiva y con mejores rendimientos en diferentes bancos. Fijar una cantidad máxima diaria en tarjetas de débito para no gastar más de lo presupuestado.</li></ul>

                <ul class="post-list-half"><li>Destinar una parte del ahorro a posibles imprevistos en un producto de ahorro que no penalice antes del plazo de vencimiento. Es decir, si se retira la inversión antes de que venza el plazo establecido en el contrato, no haya cargos o comisiones sorpresivas.<br />&nbsp;</li><li>Destinar una cantidad fija mensual en una cuenta de ahorro, si el ahorro es significativo es mejor acudir al banco y pedir un contrato de inversión.<br />&nbsp;</li><li>Ahorrar por lo menos el 10% de nuestro sueldo de forma mensual.</li></ul>

                <p>Recordemos que el ahorro se relaciona con la disciplina que tengamos, así que debemos vigilar que en la familia y en las finanzas personales, el ahorro debe de priorizar. Realiza el reto correspondiente. Recuerda, el ahorro es importante durante toda nuestra vida.</p>

                <figure className='wp-block-image size-large size-full post-img-line'>
                    <img src={lineas4} loading="lazy" decoding="async" width="1024" height="35" alt='' />
                </figure>

                <p>Por un lado, ahorro es aquel dinero que guardamos para poder disponer de él en el futuro.<br />Renunciamos a gastarlo en el presente, poniéndolo en un lugar seguro y sin riesgo, pero que suele generar intereses. Estamos ahorrando cuando mantenemos nuestro dinero en efectivo o cuando lo depositamos en una cuenta bancaria, por ejemplo.</p>

                <figure className='wp-block-image size-large size-full post-img-line'>
                    <img src={lineas5} decoding="async" width="1024" height="46" alt='' class="wp-image-2519 error" />
                </figure>

                <p><strong>Podremos invertir capital en un sinfín de cosas, desde algo inmaterial como la educación hasta activos financieros como las acciones, los bonos o los fondos de inversión.</strong></p>

                <figure class="wp-block-table">
                    <table><tbody><tr><td></td><td>Ahorro</td><td>Inversión</td></tr><tr><td>Definición</td><td>Guarda el dinero para prevenir futuros gastos</td><td>Poner el dinero ahorrado en productos para obtener rentabilidad y aumentar su valor</td></tr><tr><td>Tiempo</td><td>Cortos plazos (Normalmente)</td><td>Largo plazo (Normalmente)</td></tr><tr><td>Riesgo</td><td>Sin riesgo o riesgo muy bajo</td><td>Bastante riesgo, depende del producto</td></tr><tr><td>Rentabilidad</td><td>Poca rentabilidad o nula</td><td>Incierta, pero generalmente alta</td></tr><tr><td>Liquidez</td><td>Muy líquidos</td><td>Poco líquidos, depende del producto</td></tr></tbody></table>
                </figure>

                <figure className='wp-block-image size-large size-full post-img-line'>
                    <img src={lineas6} loading="lazy" decoding="async" width="1024" height="51" alt='' class="wp-image-2520" />
                </figure>

                <p class="post-closure">El ahorro es un elemento muy importante de una familia, pero solo se consigue a través de una buena planificación patrimonial.<br />La cuenta de ahorro es uno de los principales instrumentos bancarios para poder ahorrar.<br />La inversión es un ahorro con beneficios adicionales que la institución financiera elegida te otorga.</p>

            </div>
            <nav className="pagination-single section-inner" aria-label="Publicación">

                <hr className="styled-separator is-style-wide" aria-hidden="true" />
                <div className="pagination-single-inner">
                    <Link className="previous-post" to="/tips-para-mejorar-tu-negocio/inversion/">
                        <span class="arrow" aria-hidden="true">←</span>
                        <span class="title"><span class="title-inner">INVERSIÓN</span></span>
                    </Link>
                    <Link className="next-post" to="/tips-para-mejorar-tu-negocio/presupuesto/">
                        <span class="title"><span class="title-inner">PRESUPUESTO</span></span>
                        <span class="arrow" aria-hidden="true">→</span>
                    </Link>
                </div>
                <hr className="styled-separator is-style-wide" aria-hidden="true"/>

            </nav>
        </div>
    );
}

export default Ahorro;