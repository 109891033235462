

import '../../style/quienessomos.css';

function Compromiso() {
    return (
        <div className="Compromiso">
            <div className="aboutCommitment">
                <h2>COMPROMISO SOCIAL</h2>
                <p><big>VISIÓN</big>Ser el mejor Banco de Microfinanzas en México.</p>
                
                <p><big>MISIÓN</big>Mejorar la calidad de vida de nuestros clientes a través del acceso a productos y servicios financieros</p>
                
                <p><big>VALORES</big><b>Trabajo en Equipo</b>Juntos realizamos sueños que serían imposibles de alcanzar solo<b>Integridad</b>Actuamos de manera correcta y justa para no vulnerar los derechos de nadie<b>Responsabilidad</b>Cumplimos todos nuestros compromisos<b>Respeto</b>Respetamos la dignidad de todas las personas y de nuestro entorno</p>
            </div>
        </div>
    );
}

export default Compromiso;