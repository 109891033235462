import isotipo from '../../assets/images/Iconos/isotipo.png';
import inversion_lineas_verde from '../../assets/images/Inversion_lineas_verde.png';
import inversion_logoIPAB from '../../assets/images/Inversion_logoIPAB.png';
import '../../style/costos_comisiones.css';
import Modal from '../../components/Modal';
import { useState } from 'react';

function CostosComisiones() {
	const [openModalSegurosVida, setOpenModalSegurosVida] = useState(false);
	const [openModalSeguroRobo, setOpenModalSeguroRobo] = useState(false);

	return (
		<div className="costos-comisiones-container">
			{/* COSTOS Y COMISIONES */}
			<div style={{ marginBottom: '76px' }}>
				<h1 className="row title-1">
					<img src={isotipo} />
					Costos y Comisiones de productos de Crédito
				</h1>
				<p className="simple-text">
					Banco Forjadores, S.A., Institución de Banca Múltiple te da
					a conocer los costos y comisiones de sus productos de
					crédito.
				</p>

				<div className="table-scroll">
					<table className="cat-table">
						<thead>
							<tr>
								<th>Nombre del Producto</th>
								<th>Tasa Promedio</th>
								<th>CAT Promedio</th>
								<th>Fecha Cálculo CAT</th>
								<th>
									Tasa de interés ordinaria anual fija desde
								</th>
								<th>
									Tasa de interés ordinaria anual fija hasta
								</th>
								<th>Tasa de interés moratoria anual fija</th>
								<th>Comisión apertura de crédito</th>
								<th>Comisión por pago tardío</th>
								<th>Comisión por disposición</th>
								<th>
									Comisión por pago a través de comisionistas
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Grupal</td>
								<td>
									<b>102.35%</b>
								</td>
								<td>
									<b>215.5%</b>
								</td>
								<td>02-julio-24</td>
								<td>
									<b>44.78%</b>
								</td>
								<td>
									<b>153.85%</b>
								</td>
								<td>
									<b>No aplica</b>
								</td>
								<td>No aplica</td>
								<td>No aplica</td>
								<td>No aplica</td>
								<td>
									Monto: $15.00 IVA incluido
									<br />
									Periodicidad: Cada vez que se realice un
									pago en OXXO.
								</td>
							</tr>
							<tr></tr>
							<tr>
								<td>Adicional</td>
								<td>
									<b>84.07%</b>
								</td>
								<td>
									<b>177.2%</b>
								</td>
								<td>02-julio-24</td>
								<td>
									<b>44.78%</b>
								</td>
								<td>
									<b>153.85%</b>
								</td>
								<td>
									<b>No aplica</b>
								</td>
								<td>No aplica</td>
								<td>
									Monto $300 IVA incluido. Periodicidad. Por
									evento
								</td>
								<td>No aplica</td>
								<td>
									Monto: $15.00 IVA incluido
									<br />
									Periodicidad: Cada vez que se realice un
									pago en OXXO.
								</td>
							</tr>
							<tr>
								<td>Interciclo</td>
								<td>
									<b>84.07%</b>
								</td>
								<td>
									<b>177.2%</b>
								</td>
								<td>02-julio-24</td>
								<td>
									<b>44.78%</b>
								</td>
								<td>
									<b>153.85%</b>
								</td>
								<td>
									<b>No aplica</b>
								</td>
								<td>No aplica</td>
								<td>
									Monto $100 IVA incluido. Periodicidad. Por
									evento
								</td>
								<td>No aplica</td>
								<td>
									Monto: $15.00 IVA incluido
									<br />
									Periodicidad: Cada vez que se realice un
									pago en OXXO.
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p className="simple-text">
					Vigencia del CAT: 6 meses a partir de su fecha de cálculo
					<br />
					Leyendas sobre riesgos:
					<br />
					“Incumplir tus obligaciones te puede generar comisiones e
					intereses moratorios”
					<br />
					“Contratar créditos que excedan tu capacidad de pago afecta
					tu historial crediticio”
					<br />
					“El avalista, obligado solidario o coacreditado responderá
					como obligado principal por el total del pago frente a la
					Institución Financiera”
					<br />
					“Las operaciones realizadas a través de los comisionistas
					bancarios podrán generar una comisión, consulte antes de
					realizar su operación”
				</p>
			</div>

			{/* COSTOS DE SEGUROS */}
			<div style={{ marginBottom: '76px' }}>
				<h2 className="row title-1">
					<img src={isotipo} />
					Costos de Seguros
				</h2>
				<p className="simple-text">
					Nuestros productos de crédito requieren la contratación de
					los siguientes seguros (se marca con una x el seguro
					aplicable a cada crédito). SE LE INFORMA QUE EL CLIENTE
					PUEDE CONTRATAR LOS SEGUROS APLICABLES A SU CRÉDITO, A
					TRAVÉS DE UN TERCERO INDEPENDIENTE.
				</p>
				<div className="table-scroll">
					<table
						className="simple-table mb-10"
						border="1"
						style={{ marginTop: '70px' }}
					>
						<thead>
							<tr>
								<th>Producto</th>
								<th style={{ width: '206px' }}>
									<button
										onClick={() => {
											setOpenModalSegurosVida(true);
										}}
										className="over-button-table"
									>
										Ver características (A)
									</button>{' '}
									Seguro de Vida
								</th>
								<th style={{ width: '206px' }}>
									<button
										onClick={() => {
											setOpenModalSeguroRobo(true);
										}}
										className="over-button-table"
									>
										Ver características (B)
									</button>{' '}
									Seguro de Robo <br />
									con violencia
								</th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
							</tr>
							<tr>
								<td></td>
								<td>Costo</td>
								<td>Costo</td>
								<td>Costo</td>
								<td>Costo</td>
								<td>Costo</td>
								<td>Costo</td>
								<td>Costo</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="wht">Grupal</td>
								<td className="wht">$205.00</td>
								<td className="wht">$94.00</td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
							</tr>
							<tr>
								<td className="wht">
									Microcrédito individual montos menores*
								</td>
								<td className="wht">$1.4036 x/c $1000</td>
								<td className="wht">N/A</td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
							</tr>
							<tr>
								<td className="wht">
									Microcrédito individual montos mayores*
								</td>
								<td className="wht">$1.4036 x/c $1000</td>
								<td className="wht">N/A</td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
								<td className="empty-cell"></td>
							</tr>
						</tbody>
					</table>
				</div>
				<h3
					className="row"
					style={{
						fontSize: '26px',
						fontWeight: '600',
						justifyContent: 'center',
						marginBottom: '22px',
					}}
				>
					<span
						style={{
							display: 'block',
							width: '50px',
							height: '50px',
							backgroundColor: '#449b51',
							borderRadius: '100%',
						}}
					></span>{' '}
					Requisito
				</h3>
				<p className="simple-text">
					*DATOS VALIDOS PARA PRODUCTOS CONTRATADOS A PARTIR DEL 20 DE
					JUNIO DE 2023.
				</p>
			</div>

			{/* OPERACIONES PASIVAS */}
			<div style={{ marginBottom: '76px' }}>
				<h2 className="row title-1">
					<img src={isotipo} />
					Operaciones Pasivas
				</h2>

				<h3 className="subtitulo-1">
					<b>PAGARÉ </b>
					Forjadores
					<small>®</small>
				</h3>
				<p className="simple-text center w-2-3">
					Pagaré forjadores es una inversión que te permite ganar
					atractivos rendimientos en función al plazo y monto que
					elijas, al vencimiento tú decides si el capital y los
					intereses los reinviertes o se depositan a la cuenta
					bancaria que nos hayas proporcionado.
				</p>

				<h4 className="subtitulo-2">RENDIMIENTO FORJADORES</h4>
				<p className="simple-text center" style={{ fontSize: '14px' }}>
					Plazo desde 28, 56, 91, 182, 280 y hasta 360 días
				</p>

				<div className="table-container">
					<div className="table-scroll">
						<table className="simple-table mb-10" border="1">
							<thead>
								<tr>
									<th colSpan="2">
										Monto minimo de inversión
									</th>
									<th className="text-2xl" colSpan="3">
										$25,000.00 MXN
									</th>
									<th className="text-2xl" colSpan="3">
										$500,000.00 MXN
									</th>
								</tr>
								<tr>
									<td colSpan="2"></td>
									<td>
										<b className="font-bold">
											TASA FIJA ANUAL
										</b>
									</td>
									<td>
										<b className="font-bold">
											Gat
											<br /> Nominal
										</b>
									</td>
									<td>
										<b className="font-bold">
											Gat
											<br /> Real
										</b>
									</td>
									<td>
										<b className="font-bold">
											TASA FIJA ANUAL
										</b>
									</td>
									<td>
										<b className="font-bold">
											Gat
											<br /> Nominal
										</b>
									</td>
									<td>
										<b className="font-bold">
											Gat
											<br /> Real
										</b>
									</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td colSpan="2">28 días</td>
									<td className="font-bold">8.00%</td>
									<td>8.30%</td>
									<td>4.35%</td>
									<td className="font-bold">8.60%</td>
									<td>8.84%</td>
									<td>4.87%</td>
								</tr>
								<tr>
									<td colSpan="2">56 días</td>
									<td className="font-bold">8.25%</td>
									<td>8.54%</td>
									<td>4.58%</td>
									<td className="font-bold">8.75%</td>
									<td>9.08%</td>
									<td>5.10%</td>
								</tr>
								<tr>
									<td colSpan="2">91 días</td>
									<td className="font-bold">8.65%</td>
									<td>8.93%</td>
									<td>4.96%</td>
									<td className="font-bold">9.15%</td>
									<td>9.47%</td>
									<td>5.47%</td>
								</tr>
								<tr>
									<td colSpan="2">182 días</td>
									<td className="font-bold">9.40%</td>
									<td>9.62%</td>
									<td>5.62%</td>
									<td className="font-bold">9.90%</td>
									<td>10.14%</td>
									<td>6.12%</td>
								</tr>
								<tr>
									<td colSpan="2">280 días</td>
									<td className="font-bold">10.00%</td>
									<td>10.11%</td>
									<td>6.09%</td>
									<td className="font-bold">10.60%</td>
									<td>10.62%</td>
									<td>6.58%</td>
								</tr>
								<tr>
									<td colSpan="2">360 días</td>
									<td className="font-bold">10.70%</td>
									<td>10.70%</td>
									<td>6.66%</td>
									<td className="font-bold">11.20%</td>
									<td>11.20%</td>
									<td>7.14%</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p className="simple-text center">
						Vigencia del 26 de agosto 2024 al 31 de agosto del 2024.
						Tasas señaladas antes de impuestos.
						<br />
						Fecha de cálculo de la GAT y GAT Real es al 01 de agosto
						2024 y vigentes al 31 de agosto del 2024.
					</p>
				</div>
			</div>

			{/* GAT Y VIGENCIA */}
			<div style={{ marginBottom: '76px' }}>
				<h2 className="title-2">GAT Y VIGENCIA</h2>
				<p className="simple-text">
					<b>Ganancia Anual Total Nominal (GAT Nominal):</b> La
					Ganancia Anual Total Nominal se encuentra expresada en
					términos porcentuales anuales. 10.70% (antes de impuestos),
					para inversiones a partir de $25,000 pesos mexicanos en
					plazos de 360 días, calculada al 01 de agosto de 2024.
				</p>
				<p className="simple-text">
					<b>•</b> Información sujeta a cambios sin previo aviso.
				</p>
				<p className="simple-text">
					<b>Ganancia Anual Total Real (GAT Real):</b> La Ganancia
					Anual Total Real se encuentra expresada en términos
					porcentuales anuales. La GAT Real es el rendimiento que
					obtendrías después de descontar la inflación estimada 6.66%
					(antes de impuestos), para inversiones a partir de $25,000
					pesos mexicanos en plazos de 360 días, calculada al 01 de
					agosto de 2024.
				</p>
				<p className="simple-text">
					<b>•</b> Vigentes al 31 de agosto de 2024.
				</p>
				<p className="simple-text">
					<b>•</b> Este producto se oferta en todas las oficinas,
					consulta su ubicación en la parte superior de esta página.
				</p>
				<p className="simple-text">
					<b>•</b> Estos productos no tienen comisiones.
				</p>
				<p
					className="simple-text"
					style={{ fontSize: '14px', marginBottom: '50px' }}
				>
					Vigencia a partir del 01 de agosto de 2024 al 31 de agosto
					de 2024
				</p>

				<h3 className="subtitulo-1">
					Forjadores{' '}
					<b>
						CEDE<small>®</small>
					</b>
				</h3>

				<p className="simple-text center w-2-3">
					Forjadores Cede es una inversión flexible que te permite
					disponer de los intereses generados por tu dinero mes a mes.
					Al término del plazo pactado el capital se deposita a la
					cuenta bancaria que nos hayas proporcionado.
				</p>

				<h4 className="subtitulo-2">RENDIMIENTO FORJADORES</h4>
				<p className="simple-text center" style={{ fontSize: '14px' }}>
					Plazo desde 60, 90, 180, 270 y hasta 360 días
				</p>

				<div className="table-container">
					<div className="table-scroll">
						<table className="simple-table mb-10" border="1">
							<thead>
								<tr>
									<th colSpan="2">
										Monto minimo de inversión
									</th>
									<th className="text-2xl" colSpan="3">
										$25,000.00 MXN
									</th>
									<th className="text-2xl" colSpan="3">
										$500,000.00 MXN
									</th>
								</tr>
								<tr>
									<td colSpan="2"></td>
									<td>
										<b className="font-bold">
											TASA FIJA ANUAL
										</b>
									</td>
									<td>
										<b className="font-bold">
											Gat
											<br /> Nominal
										</b>
									</td>
									<td>
										<b className="font-bold">
											Gat
											<br /> Real
										</b>
									</td>
									<td>
										<b className="font-bold">
											TASA FIJA ANUAL
										</b>
									</td>
									<td>
										<b className="font-bold">
											Gat
											<br /> Nominal
										</b>
									</td>
									<td>
										<b className="font-bold">
											Gat
											<br /> Real
										</b>
									</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td colSpan="2">60 días</td>
									<td className="font-bold">8.20%</td>
									<td>8.52%</td>
									<td>4.55%</td>
									<td className="font-bold">8.70%</td>
									<td>9.06%</td>
									<td>5.07%</td>
								</tr>
								<tr>
									<td colSpan="2">90 días</td>
									<td className="font-bold">8.55%</td>
									<td>8.90%</td>
									<td>4.92%</td>
									<td className="font-bold">9.05%</td>
									<td>9.44%</td>
									<td>5.44%</td>
								</tr>
								<tr>
									<td colSpan="2">180 días</td>
									<td className="font-bold">9.30%</td>
									<td>9.71%</td>
									<td>5.70%</td>
									<td className="font-bold">9.80%</td>
									<td>10.25%</td>
									<td>6.23%</td>
								</tr>
								<tr>
									<td colSpan="2">270 días</td>
									<td className="font-bold">9.95%</td>
									<td>10.42%</td>
									<td>6.38%</td>
									<td className="font-bold">10.45%</td>
									<td>10.97%</td>
									<td>6.91%</td>
								</tr>
								<tr>
									<td colSpan="2">360 días</td>
									<td className="font-bold">10.60%</td>
									<td>11.13%</td>
									<td>7.07%</td>
									<td className="font-bold">11.10%</td>
									<td>11.68%</td>
									<td>7.60%</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p className="simple-text center">
						Vigencia del 26 de agosto 2024 al 31 de agosto del 2024.
						Tasas señaladas antes de impuestos. Fecha de cálculo de
						la GAT y GAT Real es al 01 de agosto 2024 y vigentes al
						31 de agosto del 2024.
					</p>
				</div>
			</div>

			{/* GAT Y VIGENCIA 2 */}
			<div style={{ marginBottom: '76px' }}>
				<h2 className="title-2">GAT Y VIGENCIA</h2>
				<p className="simple-text">
					<b>Ganancia Anual Total Nominal (GAT Nominal):</b> La
					Ganancia Anual Total Nominal se encuentra expresada en
					términos porcentuales anuales. 10.70% (antes de impuestos),
					para inversiones a partir de $25,000 pesos mexicanos en
					plazos de 360 días, calculada al 01 de agosto de 2024.
				</p>
				<p className="simple-text">
					<b>•</b> Información sujeta a cambios sin previo aviso.
				</p>
				<p className="simple-text">
					<b>Ganancia Anual Total Real (GAT Real):</b> La Ganancia
					Anual Total Real se encuentra expresada en términos
					porcentuales anuales. La GAT Real es el rendimiento que
					obtendrías después de descontar la inflación estimada 6.66%
					(antes de impuestos), para inversiones a partir de $25,000
					pesos mexicanos en plazos de 360 días, calculada al 01 de
					agosto de 2024.
				</p>
				<p className="simple-text">
					<b>•</b> Vigentes al 31 de agosto de 2024.
				</p>
				<p className="simple-text">
					<b>•</b> Este producto se oferta en todas las oficinas,
					consulta su ubicación en la parte superior de esta página.
				</p>
				<p className="simple-text">
					<b>•</b> Estos productos no tienen comisiones.
				</p>
				<p
					className="simple-text"
					style={{ fontSize: '14px', marginBottom: '50px' }}
				>
					Vigencia a partir del 01 de agosto de 2024 al 31 de agosto
					de 2024
				</p>

				<img
					style={{ width: '100%', margin: 'auto', display: 'block' }}
					decoding="async"
					src={inversion_lineas_verde}
					data-ll-status="loaded"
				/>
				<div className="p-big">
					<h3 className="subtitulo-3">REQUISITOS:</h3>
					<p
						className="simple-text green-dots"
						style={{ marginBottom: '50px' }}
					>
						<b>•</b> Ser mayor de edad.
						<br />
						<b>•</b> Contar con identificación oficial vigente y
						comprobante de domicilio (no mayor a tres meses)
						<br />
						<b>•</b> Suscribir el contrato de adhesión
						correspondiente y hacer la transferencia a la cuenta que
						Banco Forjadores te proporcione.
					</p>

					<h3 className="subtitulo-3">CONCEPTOS DEL PRODUCTO:</h3>
					<p className="simple-text green-dots">
						<b>Banco Forjadores.-</b> Banco Forjadores S.A.,
						Institución de Banca Múltiple, que para su constitución
						como tal, requirió autorización de la Secretaria de
						Hacienda y Crédito Público, con opinión del Banco de
						México y su supervisión está regulada por la Comisión
						Nacional Bancaria y de Valores
						<br />
						<br />
						<b>Pagaré Forjadores.-</b> Inversión que permite ganar
						atractivos rendimientos en función al plazo y monto que
						se elija, al vencimiento que se decida si el capital y
						los intereses se reinvierten o se depositan a la cuenta
						bancaria que se haya proporcionado.
						<br />
						<br />
						<b>Forjadores CEDE.-</b> Inversión flexible que permite
						disponer de los intereses generados por el dinero mes a
						mes. Al término del plazo pactado el capital se deposita
						a la cuenta bancaria que se haya proporcionado.
						<br />
						<br />
						<b>Comisión.-</b> Para este producto no se aplica
						comisión alguna
						<br />
						<br />
						<b>UDI.-</b> Es un valor de referencia de valor real
						constante que el Banco de México publica en el Diario
						Oficial de la Federación el valor, en moneda nacional,
						de la unidad de inversión, para cada día del mes
						<br />
						<br />
						<b>GAT.-</b> La Ganancia Anual Total Neta expresada en
						términos porcentuales anuales, para fines informativos y
						de comparación, incorpora los intereses nominales
						capitalizables que generen las operaciones pasivas a
						plazo, retirables en días preestablecidos y de ahorro,
						que celebren las instituciones de crédito y las
						entidades de ahorro y crédito popular con sus clientes,
						menos todos los costos relacionados con la operación,
						incluidos los de apertura, de conformidad con las
						disposiciones que al efecto emita el Banco de México
						<br />
						<br />
						<b>Tasa de Interés.-</b> Es la tasa de rendimiento,
						expresada en términos anuales simples, la cual es fija
						durante el plazo de la inversión antes de impuestos
						<br />
						<br />
						<b>IPAB.-</b> Instituto para la Protección al Ahorro
						Bancario
					</p>

					<img
						style={{ width: '100%', marginBottom: '50px' }}
						decoding="async"
						src={inversion_lineas_verde}
						data-ll-status="loaded"
					/>

					{/* REQUISITOS */}
					<div className="row ipab">
						<img
							style={{
								height: '130px',
								marginLeft: 'auto',
							}}
							decoding="async"
							src={inversion_logoIPAB}
							data-ll-status="loaded"
						/>
						<p className="simple-text" style={{ margin: '0' }}>
							El instituto de Protección al Ahorro Bancario le
							protege al garantizar las operaciones bancarias
							hasta por 400 mil UDIS por persona por institución
							más información en la página:{' '}
							<a
								href="https://www.gob.mx/ipab"
								target="_blank"
								rel="noopener"
							>
								https://www.gob.mx/ipab
							</a>
						</p>
					</div>
					<p className="simple-text center green-dots">
						** 400 mil UDIS equivalen a $3,283,337.60 al 01 de
						agosto de 2024
						<br />
						<br />
						Productos garantizados por el IPAB:
						<br />
						<br />
						<b>•</b> Pagaré Forjadores
						<br />
						<b>•</b> Forjadores CEDE
					</p>
				</div>
			</div>

			{/* MODAL SEGURO DE VIDA */}
			<Modal
				open={openModalSegurosVida}
				setOpen={setOpenModalSegurosVida}
			>
				<h2
					className="subtitulo-3"
					style={{ color: '#449b51', textAlign: 'center' }}
				>
					Seguro Protección de Deuda / Vida / Desgravamen
				</h2>
				<h3
					className="subtitulo-3"
					style={{
						color: '#5B5B5F',
						textAlign: 'center',
						marginTop: '50px',
					}}
				>
					Grupal
				</h3>

				<table className="table-modal">
					<tbody>
						<tr>
							<td>Servicio y/o programa de asistencia</td>
							<td>Seguro de vida deudores emprendedores</td>
						</tr>
						<tr>
							<td>Vigencia</td>
							<td>
								Igual a la duración del ciclo del crédito que
								los clientes mantengan con Banco Forjadores
								siempre que exista el pago de la prima
								correspondiente al período en que esté vigente
								el crédito
							</td>
						</tr>
						<tr>
							<td>CoberturaFallecimiento </td>
							<td>
								Fallecimiento del titular, Gastos funerarios
								para el titular y familiares directos
							</td>
						</tr>
						<tr>
							<td>Regla de suma asegurada</td>
							<td>
								Saldo insoluto del crédito Saldo insoluto y con
								máxima de $60,000.00 MN por fallecimiento del
								titular
								<br />
								Mod 1: Gastos funerarios con suma asegurada de
								$12,000.00 MN para el Titular y $10,000.00 MN
								para familiares directos. Un evento
								cuatrimestral
							</td>
						</tr>
						<tr>
							<td>
								Suma asegurada máxima por crédito y en el
								agregado sin examen médico
							</td>
							<td>
								No se requiere examen médico hasta el límite
								máximo indicado en el apartado anterior.
							</td>
						</tr>
						<tr>
							<td>Límites de edad</td>
							<td>
								Titulares 18 a 70 años, se aceptan máximo 99
								años hasta el 5% de la base <br />
								Cónyuge de 18 a 70 años, se aceptan máximo 79
								años hasta el 5% de la base <br />
								Hijos que dependan económicamente del titular de
								1 mes hasta 25 años
							</td>
						</tr>
						<tr>
							<td>Otras condiciones</td>
							<td>
								Ampara pre existencia
								<br />
								No aplican deducibles ni períodos de espera o
								carencia.
								<br />
								Atención preferencial
							</td>
						</tr>
					</tbody>
				</table>

				<h3
					className="subtitulo-3"
					style={{
						color: '#5B5B5F',
						textAlign: 'center',
						marginTop: '50px',
					}}
				>
					Microcrédito individual montos menores, Microcrédito
					individual montos mayores
				</h3>

				<table className="table-modal">
					<tbody>
						<tr>
							<td>Programa</td>
							<td>Seguro de vida deudores PYMES</td>
						</tr>
						<tr>
							<td>Vigencia</td>
							<td>
								gual a la duración del ciclo del crédito que los
								clientes mantengan con Banco Forjadores siempre
								que exista el pago de la prima correspondiente
								al período en que esté vigente el crédito
							</td>
						</tr>
						<tr>
							<td>Cobertura</td>
							<td>Fallecimiento del Titular Obligatorio.</td>
						</tr>
						<tr>
							<td>Regla de suma asegurada</td>
							<td>
								Saldo insoluto del crédito
								<br />
								Saldo insoluto con máximo de $80,000.00 MN para
								PYMES
								<br />
								El beneficiario será Banco Forjadores
							</td>
						</tr>
						<tr>
							<td>
								Suma asegurada máxima por crédito y en el
								agregado sin examen médico
							</td>
							<td>
								No se requiere examen médico hasta el límite
								máximo indicado en el apartado anterior.
								<br />
								En caso que algún acreditado exceda la suma
								asegurada indicada, Banco Forjadores deberá
								reportar caso por caso para que sea evaluado por
								la ASEGURADORA y el titular atienda los
								requerimientos de valoración necesarios
								(examenes médicos, pruebas de laboratorio,
								informes médicos, entre otros) y en su caso
								pagar la extra prima correspondiente
							</td>
						</tr>
						<tr>
							<td>Límites de Edad</td>
							<td>
								Titulares de 18 a 75 años con cancelación
								automática a los 76 años
							</td>
						</tr>
						<tr>
							<td>Otras Condiciones</td>
							<td>
								Ampara pre existencia
								<br />
								No aplican deducibles ni períodos de espera o
								carencia.
								<br />
								Atención preferencial.
							</td>
						</tr>
					</tbody>
				</table>
			</Modal>

			{/* MODAL SEGURO DE ROBO */}
			<Modal open={openModalSeguroRobo} setOpen={setOpenModalSeguroRobo}>
				<h2
					className="subtitulo-3"
					style={{ color: '#449b51', textAlign: 'center' }}
				>
					Seguro de Robo con violencia
				</h2>
				<h3
					className="subtitulo-3"
					style={{
						color: '#5B5B5F',
						textAlign: 'center',
						marginTop: '50px',
					}}
				>
					Crédito Grupal
				</h3>

				<table className="table-modal">
					<tbody>
						<tr>
							<td>Programa</td>
							<td>Seguro de Robo deudores emprendedores</td>
						</tr>
						<tr>
							<td>Vigencia</td>
							<td>
								Igual a la duración del ciclo del crédito que
								los clientes mantengan con Banco Forjadores
								siempre que exista el pago de la prima
								correspondiente al período en que esté vigente
								el crédito
							</td>
						</tr>
						<tr>
							<td>Cobertura</td>
							<td>
								La suma asegurada corresponde al 10% del saldo
								insoluto del monto inicial a pagar de manera
								grupal
							</td>
						</tr>
						<tr>
							<td>Regla de suma asegurada</td>
							<td>
								1 evento durante la temporalidad del crédito
							</td>
						</tr>
						<tr>
							<td>Suma asegurada máxima por crédito</td>
							<td>
								La suma asegurada corresponde al 10% del total a
								pagar por el grupo con un monto máximo de hasta
								$60,000.00 MN
							</td>
						</tr>
						<tr>
							<td>Otras condiciones</td>
							<td>
								Pago de reembolso de 10 a 15 días, con
								documentación completa presentando original o
								copia certificada del acta ante el Ministerio
								Público que ampare la o las denuncias ante las
								autoridades correspondiente acreditando el robo
								con violencia, así como la propiedad de lo
								declarado ante la autoridad competente.
								<br />
								Asistencias:
								<br />– Legal telefónica ilimitada
							</td>
						</tr>
					</tbody>
				</table>

				<h3
					className="subtitulo-3"
					style={{
						color: '#5B5B5F',
						textAlign: 'center',
						marginTop: '50px',
					}}
				>
					Microcrédito individual montos menores, Microcrédito
					individual montos mayores, Crédito Industrial PF y Crédito
					Industrial PM
				</h3>

				<table className="table-modal">
					<tbody>
						<tr>
							<td>Contratante</td>
							<td>
								Banco Forjadores S, .A. Institución de Banca
								Múltiple y/o sus acreditados.
							</td>
						</tr>
						<tr>
							<td>Programa</td>
							<td>Seguro de Robo Deudores PYMES</td>
						</tr>
						<tr>
							<td>Vigencia</td>
							<td>
								Igual a la duración del ciclo de crédito que los
								clientes mantengan con Banco Forjadores siempre
								que exista el pago de la prima correspondiente
								al periodo en que este vigente el crédito.
							</td>
						</tr>
						<tr>
							<td>Coberturas</td>
							<td>
								La Suma Asegurada corresponde al 10% del saldo
								insoluto
							</td>
						</tr>
						<tr>
							<td>Regla de Suma asegurada</td>
							<td>
								1 evento durante la temporalidad del crédito (de
								1 mes a 12 meses)
								<br />2 eventos durante la temporalidad del
								crédito (de 13 meses a 24 meses)
							</td>
						</tr>
						<tr>
							<td>
								Suma Asegurada Máxima por crédito y en el
								agregado sin examen medico
							</td>
							<td>
								La Suma Asegurada corresponde al 10% del saldo
								insoluto hasta $30,000.00
							</td>
						</tr>
						<tr>
							<td>Otras condiciones</td>
							<td>
								Pago de reembolso de 10 a 15 días con
								documentación completa, presentando original o
								copia certificada del acta ante el Ministerio
								Publico que ampare la o las denuncias ante las
								autoridades correspondientes, acreditando el
								robo con violencia, así como la propiedad de lo
								declarado ante la autoridad competente.
								<br />
								Asistencias:
								<br />
								Legal telefónica ilimitada
							</td>
						</tr>
					</tbody>
				</table>
			</Modal>
		</div>
	);
}

export default CostosComisiones;
