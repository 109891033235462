
import '../../style/infohead.css';

import encabezado from '../../assets/images/Encabezados/encabezado-oficinas-de-servicio.jpg';
import sucursales_lineas from '../../assets/images/sucursales_lineas.png';
import aguascalientes from '../../assets/images/sucursales_Aguascalientes_.png';
import edomex from '../../assets/images/sucursales_EdoMex.png';
import guanajuato from '../../assets/images/sucursales_Guanajuato.png';
import michoacan from '../../assets/images/sucursales_Michoacan.png';
import morelos from '../../assets/images/sucursales_Morelos.png';
import oaxaca from '../../assets/images/sucursales_Oaxaca.png';
import puebla from '../../assets/images/sucursales_Puebla.png';
import queretaro from '../../assets/images/sucursales_Queretaro.png';
import veracruz from '../../assets/images/sucursales_Veracruz.png';
import tlaxcala from '../../assets/images/sucursales_tlaxcala.png';

function OficinaServicio() {
    return (
        <div className="entry-content">

            <div className="branchesIntro">
                <img src={encabezado} alt=''/>
                <p>Banco Forjadores tiene presencia en <b>10 estados</b> de la República Mexicana con <b>31 Oficinas de Servicio</b>. Da clic en el estado que desees consultar.</p>
            </div>

            <div className="branchesContainer">
                <div data-target="aguascalientes">
                    <img src={aguascalientes} />
                    <p><b>Aguascalientes</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="edomex">
                    <img src={edomex} />
                    <p><b>Estado de México</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="guanajuato">
                    <img src={guanajuato} />
                    <p><b>Guanajuato</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="michoacan">
                    <img src={michoacan} />
                    <p><b>Michoacán</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="morelos">
                    <img src={morelos} />
                    <p><b>Morelos</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="oaxaca">
                    <img src={oaxaca} />
                    <p><b>Oaxaca</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="puebla">
                    <img src={puebla} />
                    <p><b>Puebla</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="queretaro">
                    <img src={queretaro} />
                    <p><b>Querétaro</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="tlaxcala">
                    <img src={tlaxcala} />
                    <p><b>Tlaxcala</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <div data-target="veracruz">
                    <img src={veracruz} />
                    <p><b>Veracruz</b><br/>da clic <b>aquí</b> para consultar</p>
                </div>
                <img src={sucursales_lineas} />
            </div>

        </div>
    );
}

export default OficinaServicio;