import { useNavigate, Link } from 'react-router-dom';
import banner from '../../../assets/images/Encabezados/encabezado_presupuesto.jpg';
import graf1 from '../../../assets/images/grafico_1.png';
import lineas from '../../../assets/images/lineas_1-1536x88.png';
import graf2 from '../../../assets/images/presupuesto_familiar.png';
import tbl from '../../../assets/images/presupuesto-tablas-1536x562.png';
import lineas2 from '../../../assets/images/lineas_2_frase-1536x78.png';


import '../../../style/quienessomos.css';

function Presupuesto() {
    return (
        <div className='entrys'>
            <div className='banner-postss'>
                <img src={banner} className='' />
            </div>
            <div className=" Presupuesto">
                <img src={graf1} className='img-graf1' />
                <p className="text-ri">Hacer un presupuesto es simplemente sentarse a planear lo que quieres hacer en el futuro y expresarlo en dinero.<br /><br />Un ejemplo son los viajes. Uno se pone a planear, entre otras cosas, cuánto hay que gastar en pasajes o gasolina, comidas y hospedaje.<br /><br />Cuando haces un presupuesto para tu empresa, en realidad lo que estás haciendo es planear a futuro.<br /><br />Entonces te preguntas cuánto piensas vender, qué necesitas hacer para lograrlo, cuánto tienes que gastar y lo mejor de todo, sabrás cuánto vas a ganar en un periodo. Obviamente, esto es una estimación que puedes hacer de acuerdo con tu experiencia y la información que conoces. A partir de ahí sabrás cuánto cobrar por tu trabajo realizado en función de los beneficios que te plantees obtener.</p>

                <img src={lineas} className='lineas' />

                <p><strong>¿ Y cómo se hace un presupuesto?</strong><br />Vamos a partir de los ingresos que percibe la familia, vamos a planificar cuáles son los gastos y aquellas actividades en las cuales vamos a gastar. Vamos a tomar en cuenta que esta resta nunca podrá ser mayor a cero.</p>

                <p><big><strong>PRESUPUESTO</strong> = Gastos contemplados del viaje – Ingresos Presupuesto</big></p>

                <p><strong>Calcular presupuesto</strong><br /><br />Esto nos sirve para conocer cómo se integran la magnitud de los gastos, realizar las compras de forma racional y no emocional. Funciona también como una guía para tomar decisiones de compra.<br /><br />Es lograr el <strong>equilibrio entre el ingreso y el gasto.</strong> Además, es una herramienta de planeación, nos va a permitir equilibrar las entradas de efectivo y los recursos en el hogar con aquellos gastos necesarios para el buen funcionamiento del mismo.</p>

                <img src={graf2} className='img-graf2' />

                <p><strong>Presupuesto familiar</strong><br /><br />El presupuesto familiar es una herramienta que también nos permite ver de forma clara estas perspectivas. Ya que se deben tomar en cuenta en el momento de hacer el presupuesto de forma semanal, mensual o incluso quincenal.<br /><br />A continuación, se muestra un ejemplo de presupuesto familiar mensual. La primera parte integra todos los ingresos que tiene la familia. En la parte 2 podemos apreciar todos los gastos contemplados a cubrir en el mes considerado.</p>


                <figure class="wp-block-image size-full">
                    <img src={tbl} className='img-tbls' decoding="async" width="1550" height="567" alt=''/>
                </figure>
                

                <p>Si el resultado es positivo quiere decir que hay un excedente. Lo cual no debes gastar, es más bien un indicativo que tienes poder de ahorro, por el contrario, si el resultado fue negativo hay una falta de recursos, lo cual es prácticamente imposible, pero nos quiere decir que estamos en necesidad de solicitar un crédito, así es que es momento de cuidar los recursos y gastar menos. Debemos de identificar claramente cuáles son aquellos gastos innecesarios y que, de manera constante, incluso imperceptible, van mermando el ingreso familiar</p>

                <p></p>

                <p><strong>Otros gastos tienen algunas familias</strong></p>

                <ul className="post-list-half wp-block-list">
                    <li>Seguro de salud</li>
                    <li>Vacaciones</li>
                    <li>Gasolina</li>
                    <li>Seguro (auto, vida, discapacidad, casa)</li>
                    <li>Televisión</li><li>Internet de alta velocidad</li>
                </ul>

                <ul className="post-list-half wp-block-list">
                    <li>Teléfono celular</li>
                    <li>Fondo para días festivos</li>
                    <li>Perro (comida, veterinario)</li>
                    <li>Cortes de pelo</li><li>Mantenimiento / reparación del auto</li>
                </ul>

                <p className="post-title-greenbg"><strong>Debemos considerar otra cantidad fija para imprevistos.</strong><br /><strong>Recuerda, satisfacer necesidades y no cumplir deseos.</strong></p>

                <p className="post-step"><strong>1</strong><br />Podemos hacer algunas cosas o algunas actividades dentro del hogar, como limpiar, lavar, pintar o reparar.</p>

                <p className="post-step"><strong>2</strong><br />​No desembolsar dinero si no está presupuestado.</p>

                <p className="post-step"><strong>3</strong><br />​Tampoco gastar energía eléctrica, agua, alimentos, teléfono, gas o gasolina de forma irracional.</p>

                <p className="post-step"><strong>4</strong><br />​Evite endeudarse a menos que sea totalmente y estrictamente indispensable.</p>

                <p className="post-step"><strong>5</strong><br />​Asegúrese de cubrir perfectamente los servicios básicos, alimentos, educación, servicios, transporte y ahorro.</p>

                <p className="post-step"><strong>6</strong><br />​Debemos especificar claramente lo que se va a destinar al entretenimiento, la compra de un regalo, una salida imprevista, pero siempre debemos considerarlos como gastos.</p>

                <p className="post-step"><strong>7</strong><br />​No se debe de olvidar presupuestar desde unas vacaciones hasta la compra de un inmueble, un automóvil o aquello que requerimos en el hogar.</p>

                <p className="post-step"><strong>8</strong><br />​Mes con mes se deberá determinar la cantidad necesaria de ahorro y cómo la vamos a utilizar.</p>

                <p className="post-closure">Todas estas <strong>recomendaciones nos ayudarán</strong> a ser como la hormiga que procuraba <strong>administrar</strong> su alimento para el tiempo que realmente lo necesite.</p>

                <img src={lineas2} className='lineas' />

            </div>
            <nav className="pagination-single section-inner" aria-label="Publicación">

                <hr className="styled-separator is-style-wide" aria-hidden="true" />
                <div className="pagination-single-inner">
                    <Link className="previous-post" to="/tips-para-mejorar-tu-negocio/ahorro/">
                        <span class="arrow" aria-hidden="true">←</span>
                        <span class="title"><span class="title-inner">AHORRO</span></span>
                    </Link>
                    <Link className="next-post" to="/tips-para-mejorar-tu-negocio/50-consejos-para-emprender-tu-negocio-forjador/">
                        <span class="title"><span class="title-inner">50 CONSEJOS PARA EMPRENDER TU NEGOCIO FORJADOR</span></span>
                        <span class="arrow" aria-hidden="true">→</span>
                    </Link>
                </div>
                <hr className="styled-separator is-style-wide" aria-hidden="true" />

            </nav>
        </div>
    );
}

export default Presupuesto;