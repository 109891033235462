import { useNavigate, Link } from 'react-router-dom';
import banner from '../../../assets/images/Encabezados/encabezado_credito_capacitaciones.jpg';
import graf1 from '../../../assets/images/significado_credito.png';
import lineas from '../../../assets/images/lineas_1-1536x88.png';
import lineas2 from '../../../assets/images/Ahorro_lineas7-1024x51.png';

import '../../../style/quienessomos.css';

function Creditos() {
    return (
        <div className="type-post">
            <div class="entry-content">
                <div className='banner-postss'>
                    <img src={banner} className='' />
                </div>
                {/* <figure className='wp-block-image size-full'>
                    <img src={banner} decoding="async" width="1920" height="800" alt='' className='lazyloaded' />
                </figure> */}

                <figure className='wp-block-image size-full size-large post-p-right'>
                    <img src={graf1} decoding="async" width="513" height="453" alt='' className='lazyloaded' />
                </figure>
                <p class="post-img-left"><strong>Significado de crédito</strong><br/><br/><big><strong>Raíz latina “creedere”</strong> = Confianza</big><br/><br/> “Premiso para usar el capital de otro”<br/>– John Stuart Mill<br/><br/> “Poder para obtener bienes y servicios por medio de la promesa de pago en una fecha determinada en el futuro”<br/>– Joseph Fred Johnson</p>

                <figure className='wp-block-image size-large size-full post-img-line'>
                        <img src={lineas} decoding="async" width="1024" height="59" alt='' className='lazyloaded' />
                </figure>
                
                <p><big><strong>Crédito = Confianza</strong></big></p>
                
                <ul class="post-list-half"><li>Todas las relaciones de crédito se basan en la confianza. La confianza que tendrán en prestarnos el dinero o usar el capital de alguien más.<br/>&nbsp;</li><li>El crédito es una cantidad de dinero que obtenemos con la obligación de pagarla en un plazo determinado, más una cantidad adicional por el concepto de intereses.<br/>&nbsp;</li><li>El crédito es una herramienta que nos permite adquirir bienes y servicios, algunos de los cuales no podríamos adquirir de inmediato ya que no ahorramos lo suficiente. O no disponemos de la totalidad del efectivo para poderlos comprar en ese momento.</li></ul>
                
                <p><strong>Ciclo del crédito</strong></p>
                
                <p><strong>1. Originación</strong><br/><br/>Para dar comienzo al origen de un crédito se debe revisar las características que este requiere. El precio de un crédito es la tasa de interés.</p>
                
                <ul class="post-list-half"><li>En cuanto más alta sea esta, será más caro el crédito. Al solicitar un crédito debemos siempre de cuidar que la tasa de interés sea la más favorable.</li><li>Comisiones de apertura y cancelación (total o parcial), si es que existieran en dicho crédito.</li><li>Plazo de amortización (el tiempo para devolver el dinero prestado).</li><li>Importe de la cuota (mensual, catorcenal, semanal, de acuerdo a lo pactado)</li><li>Otros gastos.</li></ul>
                
                <p><strong>2. Análisis y autorización</strong><br/><br/>La institución financiera evaluará los siguientes aspectos. Carácter, capacidad, capital, colateral y condiciones. Esta herramienta se llama las cinco C’s del crédito.<br/><br/><strong>Carácter</strong><br/><br/>Cuando los prestamistas evalúan el carácter, estudian la estabilidad, por ejemplo cuánto tiempo ha vivido en su dirección actual, cuánto tiempo lleva en su trabajo actual, y si tiene buenos antecedentes de pagar sus cuentas puntualmente y en su totalidad.<br/><br/><strong>Capacidad</strong><br/><br/>La capacidad se refiere a su capacidad para pagar el préstamo, tomando en consideración sus otras deudas y gastos.<br/><br/><strong>Capital</strong><br/><br/>Capital se refiere a su valor neto, el valor de sus activos menos sus pasivos.<br/><br/><strong>Colateral</strong><br/><br/>Colateral se refiere a cualquier activo (por ejemplo, una vivienda) del que puede apropiarse el prestamista para pagar la deuda si el prestatario no puede hacer los pagos del préstamo según lo acordado.<br/><br/><strong>Condiciones</strong><br/><br/>Los prestamistas toman en cuenta diversas circunstancias externas que pueden afectar a la situación financiera del prestatario y a su capacidad para pagar, por ejemplo, la situación de la economía local.</p>
                
                <p>​<strong>3. Contratación</strong></p>
                
                <ul class="post-list-half"><li>En toda contratación debes cuidar las letras chiquitas. Es importante revisar las comisiones de la tarjeta de crédito, por ejemplo. Pueden ser muchas y diversas, y también por qué concepto las están cobrando. Las comisiones aparecen detalladas en el estado de cuenta.</li><li>Se recomienda siempre revisar de manera cuidadosa los conceptos y los cargos.</li><li>La tasa de interés.</li></ul>
                
                <p><strong>4. Administración</strong><br/><br/>Toda empresa dedicada a proporcionar créditos debe tener una correcta administración. Para conservar una cartera de clientes y contribuir a una mejora en clientes nuevos, es necesario ofrecer crédito que se adecue a las posibilidades del contratante. Las condiciones de crédito pueden variar. Las ventas a crédito, que dan como resultado las cuentas por cobrar normalmente incluyen condiciones de crédito que estipulan el pago en un número determinado de días. Aunque todas las cuentas por cobrar no se cobran dentro el periodo de crédito, la mayoría de ellas se convierten en efectivo en un plazo inferior a un año; en consecuencia, las cuentas por cobrar se consideran como activos circulantes de la empresa.</p>
                
                <p><strong>5. Contabilidad</strong><br/><br/>Una vez que las condiciones de la empresa y del solicitante, logran pactar lo convenido, se deposita la confianza. El crédito ya está listo para disponerse, ya que cumple todos los requisitos. Es así como se llega a la parte final donde se dispersa el crédito, cabe mencionar que existen varios tipos de dispersión que dependerán del tipo de crédito otorgado.</p>
                
                <p class="post-title-greenbg"><strong>​Tipos de Crédito</strong></p>
                
                <p class="post-step"><strong>Crédito a la vivienda o hipotecario</strong><br/>Es un préstamo a mediano o largo plazo que se otorga para la adquisición, ampliación, remodelación de un bien inmueble. La propiedad adquirida queda en garantía o hipotecada a favor del banco para asegurar el cumplimiento del crédito. Los plazos pueden ser desde ocho hasta 40 años, aunque lo habitual pueden ser unos 20 años. Los costos y las tasas de interés son muy variables.</p>
                
                <p class="post-step"><strong>Crédito automotriz</strong><br/>Es el financiamiento para la adquisición de vehículos nuevos de uso particular dirigido a personas físicas con o sin actividad empresarial. Este financiamiento cuenta con planes de pagos fijos mensuales durante la vigencia del crédito que puede ser desde seis hasta 60 meses, según la institución que otorga el crédito. En algunos casos se requiere de un aval que fungirá como co-responsable en el pago de la deuda.</p>
                
                <p class="post-step"><strong>Crédito personal</strong><br/>En este crédito, la entidad financiera pone a disposición del cliente una suma de dinero, la cual podrá usar libremente. Sin necesidad de destinarlo al consumo de un bien o asunto específico.</p>
                
                <p class="post-step"><strong>Crédito al consumo</strong><br/>Este crédito permite adquirir bienes o servicios que satisfacen necesidades cotidianas y específicas. Los más utilizados son la tarjeta de crédito departamental o de crédito de alguna tienda de autoservicio. Las tasas de interés van desde el 36% hasta el 46% generalmente.</p>
                
                <p class="post-step"><strong>Crédito ABCD</strong><br/>Es para la adquisición de bienes de consumo duradero. De ahí, sus siglas. Estos créditos otorgan a las personas físicas para que adquieran bienes muebles que normalmente se consumen por varios años. Como son los electrodomésticos, un refrigerador, un horno de microondas, un televisor o incluso computadoras. El costo de estos bienes se cubrirá con pagos fijos semanales, quincenales o mensuales. La tasa de interés varía desde el 25 al 50%. Y la garantía del pago es la cuenta de nómina.</p>
                
                <p class="post-step"><strong>Tarjeta de crédito</strong><br/>Es la forma más socorrida de acceder al crédito. Con ella se pueden realizar compras en establecimientos comerciales sin la necesidad de pagar inmediatamente en efectivo. Cuando se contrata una tarjeta de crédito, el banco nos va a abrir una línea de crédito, lo cual es un monto que debemos pagar mes tras mes. Por usar este crédito, debemos pagar la cantidad prestada más<br/>los intereses acumulados en el período. En la actualidad, la tarjeta de crédito tiene un enorme uso, ya que representa un excelente medio de pago sin la necesidad de traer dinero en efectivo.</p>
                
                <p class="post-step"><strong>Crédito de nómina</strong><br/>Es un préstamo que toma como respaldo el salario que recibe una persona por su trabajo. Los pagos del crédito se descuentan directamente de la cuenta en donde depositan el sueldo.</p>

                <figure className='wp-block-image size-large size-full post-img-line'>
                    <img src={lineas2} decoding="async" width="1024" height="51" alt='' className='lazyloaded' />
                </figure>
                
                <p>El crédito es un elemento que está y que siempre estará dentro de la economía de un país y que es necesario, esencial, para que el propio país tenga solvencia, la capacidad empresarial y el apoyo social necesario para mantener un gobierno y dar estabilidad a una economía y al propio país.</p>
                <p></p>
            </div>
            <nav class="pagination-single section-inner" aria-label="Publicación">
                <hr class="styled-separator is-style-wide" aria-hidden="true"/>
                <div class="pagination-single-inner">
                    <Link className="previous-post" to="/tips-para-mejorar-tu-negocio/seguros/">
                        <span class="arrow" aria-hidden="true">←</span>
                        <span class="title"><span class="title-inner">SEGUROS</span></span>
                    </Link>
                    <Link className="next-post" to="/tips-para-mejorar-tu-negocio/inversion/">
                        <span class="title"><span class="title-inner">INVERSIÓN</span></span>
                        <span class="arrow" aria-hidden="true">→</span>
                    </Link>
                </div>
                <hr class="styled-separator is-style-wide" aria-hidden="true"/>
            </nav>
        </div>
    );
}

export default Creditos;