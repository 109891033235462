import { useNavigate, Link } from 'react-router-dom';
import banner from '../../../assets/images/Encabezados/Encabezado.png';
import graf1 from '../../../assets/images/Inversion_grafico1.png';
import graf2 from '../../../assets/images/Inversion_perfilinversionista.png';
import graf3 from '../../../assets/images/Inversion_perfilconservador.png';
import graf4 from '../../../assets/images/Inversion_perfilmoderado.png';
import graf5 from '../../../assets/images/Inversion_perfilagresivo.png';
import lineas from '../../../assets/images/lineas_1.png';
import lineas2 from '../../../assets/images/Ahorro_lineas7-1024x51.png';

import '../../../style/quienessomos.css';

function Inversion() {
    return (
        <div className='type-post'>
            <div className="entry-content">
                <div className='banner-postss'>
                    <img src={banner} className='' />
                </div>
                <figure className='wp-block-image size-full'>
                    {/* <img src={banner} decoding="async" width="1920" height="802" alt='' className='lazyloaded' /> */}
                    <figcaption>OBJETIVO<br />Conocerá Técnicas y procedimientos para maximizar los recursos propios por medio de la inversión
                    </figcaption>
                </figure>

                <figure className='wp-block-image size-full size-large post-p-right'>
                    <img src={graf1} decoding="async" width="656" height="452" alt='' className='lazyloaded' />
                </figure>

                <p class="post-img-left"><big><strong>INVERSIÓN</strong> = Incrementar Patrimonio</big><br /><br />Es decir, generar un interés a través de instrumentos&nbsp; financieros.<br /><br />En una economía inflacionaria el dinero pierde su valor conforme pasa el tiempo, si mantenemos el dinero estático debajo del colchón este va a perder su valor. Por eso es necesario invertir, porque a través de la inversión el capital crece, el dinero mantiene su poder adquisitivo y reducimos los riesgos.</p>

                <figure className='wp-block-image size-full post-img-line'>
                    <img src={lineas} decoding="async" width="1024" height="59" alt='' className='lazyloaded' />
                </figure>

                <p><strong>Elementos al momento de inversión</strong></p>

                <p><strong>Rendimiento</strong><br />Es decir, la ganancia que los instrumentos ofrecen y se mide como un porcentaje de la cantidad originalmente invertida.</p>

                <p><strong>Riesgo</strong> <br />Es la incertidumbre de lo que se va a ganar ya sea mucho o poco.</p>

                <p><strong>Plazo</strong><br />Es el horizonte de inversión, o sea, el tiempo que se espera obtener un resultado de la inversión.</p>

                <p>Los instrumentos a mayor plazo y con mayor riesgo ofrecen mejores rendimientos, pero también existe la incertidumbre de que estos no ofrezcan lo esperado.</p>

                <figure className='wp-block-image size-full post-img-centered'>
                    <img src={graf2} decoding="async" width="690" height="344" alt='' className='lazyloaded' />
                </figure>

                <p><strong>Perfil del inversionista</strong><br /><br />No es necesariamente exclusivo de las personas que saben mucho sobre finanzas al contrario, muestra las características que una persona debe saber antes de tomar decisiones de inversión. ¿Por qué? Porque esto va a influir su tolerancia o aversión al riesgo y la relación que existe con los instrumentos de inversión en el mercado.</p>

                <p><strong>Elementos que deben tomarse en cuenta :</strong></p>

                <ul class="post-list-half">
                    <li><strong>Edad:</strong> la tolerancia o la aversión al riesgo de una persona de 25 es diferente a la de una de 60.</li>
                    <li><strong>Futuro económico:</strong> es posible que una persona tenga mayores ingresos y que tenga más dinero en un futuro, lo que le permitirá ser más arriesgado en sus decisiones financieras.</li>
                    <li><strong>Los conocimientos</strong>: querer conocer algún tipo de cuestiones básicas de inversiones para comprender de qué forma operan los instrumentos financieros y cuáles son las mejores alternativas que existen en el mercado.</li>
                    <li><strong>El carácter:</strong> nos muestra la tolerancia al riesgo e incertidumbre.</li>
                    <li><strong>El tiempo:</strong> estar consciente que una inversión puede ser a corto o a largo plazo y esto tendrá una influencia sobre el rendimiento esperado.</li>
                </ul>

                <figure className='wp-block-image size-full post-img-centered'>
                    <img src={graf3} decoding="async" width="458" height="204" alt='' className='lazyloaded' />
                </figure>

                <p><strong>Perfiles del inversionista</strong></p>

                <p>Es aquél al que no le gusta tomar riesgos. Prefiere las inversiones seguras, aunque los rendimientos sean menores, se caracteriza por su aversión al riesgo. Valora mucho la seguridad y procurar asumir el menor riesgo posible, por lo que suele preferir las inversiones que le reporten rendimientos estables, de poco rendimiento, pero con bajos niveles de volatilidad.</p>

                <p><strong>Características:</strong></p>

                <ol class="post-list-half">
                    <li>Es menos tolerante al riesgo y valora la seguridad.</li>
                    <li>Escoge instrumentos de inversión que le den certeza de que no perderá parte o todo el dinero que invertirá.</li>
                    <li>No le importa que el rendimiento sea bajo.</li>
                    <li>Prefiere invertir en instrumentos como títulos de deuda, depósitos a plazo o cuentas de ahorro, por que puede saber la rentabilidad que tendrán al adquirirlos.</li>
                    <li>Puede haber todo tipo de personas desde jóvenes con sus primeros ingresos y que por lo tanto, no quieren arriesgar sus ahorros hasta aquellos con familias por mantener. Deudas por cubrir o personas retiradas o por jubilarse que no quieren mayores preocupaciones.</li>
                </ol>

                <figure className='wp-block-image size-full post-img-centered'>
                    <img src={graf4} decoding="async" width="378" height="246" alt='' className='lazyloaded' />
                </figure>

                <p><strong>Perfil del inversionista</strong></p>

                <p>No es necesariamente exclusivo de las personas que saben mucho sobre finanzas</p>

                <p>al contrario, muestra las características que una persona debe saber antes de</p>

                <p>tomar decisiones de inversión. ¿Por qué? Porque esto va a influir su tolerancia o aversión al riesgo y la relación que existe con los instrumentos de inversión en el mercado.</p>

                <p><strong>Elementos que deben tomarse en cuenta :</strong></p>

                <ul class="post-list-half"><li><strong>Edad:</strong> la tolerancia o la aversión al riesgo de una persona de 25 es diferente a la de una de 60.</li><li><strong>Futuro económico:</strong> es posible que una persona tenga mayores ingresos y que tenga más dinero en un futuro, lo que le permitirá ser más arriesgado en sus decisiones financieras.</li><li><strong>Los conocimientos</strong>: querer conocer algún tipo de cuestiones básicas de inversiones para comprender de qué forma operan los instrumentos financieros y cuáles son las mejores alternativas que existen en el mercado.</li><li><strong>El carácter:</strong> nos muestra la tolerancia al riesgo e incertidumbre.</li><li><strong>El tiempo:</strong> estar consciente que una inversión puede ser a corto o a largo plazo y esto tendrá una influencia sobre el rendimiento esperado.</li></ul>

                <p><strong>Perfiles del inversionista</strong></p>

                <p>Es aquél al que no le gusta tomar riesgos. Prefiere las inversiones seguras, aunque los rendimientos sean menores, se caracteriza por su aversión al riesgo. Valora mucho la seguridad y procurar asumir el menor riesgo posible, por lo que suele preferir las inversiones que le reporten rendimientos estables, de poco rendimiento, pero con bajos niveles de volatilidad.</p>

                <p><strong>Características:</strong><br />1. Es menos tolerante al riesgo y valora la seguridad.<br />2. Escoge instrumentos de inversión que le den certeza de que no perderá parte o todo el dinero que invertirá.<br />3. No le importa que el rendimiento sea bajo.</p>

                <p>4. Prefiere invertir en instrumentos como títulos de deuda, depósitos a plazo o cuentas de ahorro, por que puede saber la rentabilidad que tendrán al adquirirlos.<br />5. Puede haber todo tipo de personas desde jóvenes con sus primeros ingresos y que por lo tanto, no quieren arriesgar sus ahorros hasta aquellos con familias por mantener. Deudas por cubrir o personas retiradas o por jubilarse que no quieren mayores preocupaciones.</p>

                <p>Es una persona que está más consciente del horizonte temporal de sus inversiones, no está dispuesto a asumir demasiados riesgos y generalmente no hace operaciones diarias, tolera un riesgo moderado. Busca obtener buenos rendimientos, pero sin asumir demasiado riesgo. Procura mantener un balance entre rendimiento y seguridad.</p>

                <p><strong>Características:</strong></p>

                <ol class="post-list-half"><li>Este inversionista es cauteloso con sus decisiones, pero está dispuesto a tolerar un riesgo moderado para aumentar sus ganancias.</li><li>Procura mantener un balance ante rentabilidad y seguridad.</li><li>Busca crear un portafolio que combina inversiones en instrumentos de deuda y capitalización.</li><li>Pueden ser personas de distintas edades. Generalmente se trata de personas con ingresos estables, que pueden ser entre moderados y altos, padres de familia con capacidad de ahorro.</li></ol>

                <figure className='wp-block-image size-full post-img-centered'>
                    <img src={graf5} decoding="async" width="375" height="241" alt='' className='lazyloaded' />
                </figure>

                <p>Generalmente cuenta con percepciones favorables en su trabajo y sus ingresos. Busca mayores rendimientos, aunque esté dispuesto a asumir el riesgo que sea necesario. Generalmente son personas jóvenes o de mediana edad que no tienen responsabilidades familiares por lo que pueden abrir más su margen de riesgo a las pérdidas. Pero saben que si su jugada en el mercado sale bien tendrán una buena cantidad de dinero como ganancia.</p>

                <p><strong>Características:</strong></p>

                <ol class="post-list-half"><li>Busca los mayores rendimientos posibles, por lo que esta dispuesto a asumir el riesgo que sea necesario .</li><li>Son inversionistas jóvenes, pero que cuentan también con solidez económica y con ingresos de moderados a altos y personas solteras o aun sin hijos entre los 30 y los 40 años de edad.</li><li>Prefieren portafolios de inversión en los que combinan fondos de capitalización, deuda a corto plazo y deuda a largo plazo.</li></ol>

                <p class="post-title-greenbg"><strong>​Instrumentos de inversión</strong><br />Un instrumento financiero es un contrato que da lugar a un activo financiero (AF) en una empresa y, simultáneamente, a un pasivo financiero (PF) o a un instrumento de patrimonio (IP) en otra empresa.</p>

                <p class="post-step"><strong>Pagaré bancario</strong><br />Es un instrumento financiero de bajo riesgo, rendimiento y plazo fijo. Es una inversión a plazos que paga rendimientos garantizados a una tasa de interés fija y al término del plazo. Su manejo se realiza a través de una cuenta de cheques o ahorros y está diseñado para personas que buscan buenos rendimientos con una inversión mínima, ya que se abre con una cantidad de dinero muy pequeña.</p>

                <p class="post-step"><strong>Los certificados de depósito</strong><br />Son instrumentos de bajo riesgo con rendimiento y plazo fijo o ajustable, estos certificados ofrecen atractivos rendimientos con base en el plazo y monto invertido con pago de intereses periódicamente, los cuales se realizan automáticamente a la cuenta de cheques o ahorros.</p>

                <p class="post-step"><strong>Los fondos de inversión de deuda</strong><br />Son instrumentos de riesgo bajo medio, rendimiento<br />y plazo variable. Estas son sociedades que reúnen a<br />un grupo de ahorradores que invierten su dinero de<br />manera conjunta.</p>

                <p class="post-step"><strong>​Los fondos de inversión en renta variable</strong><br />Debemos especificar claramente lo que se va a destinar al entretenimiento, la compra de un regalo, una salida imprevista, pero siempre debemos considerarlos como gastos.</p>

                <p class="post-closure"><strong>Además de los instrumentos financieros existen otras alternativas de inversión:</strong><br />Bienes inmuebles | Metales preciosos | La moneda extranjera</p>

                <figure className='wp-block-image size-large size-full post-img-line'>
                    <img src={lineas2} decoding="async" width="1024" height="51" alt='' className='lazyloaded' />
                </figure>

                <p>Invertir <strong>amplia tus conocimientos.</strong> Cuando inviertes, no solo <strong>importan los números…</strong><br /><br />Invertir te enseña que los <strong>matices importan…</strong><br />Invertir te enseña a <strong>ignorar el ruido…</strong><br />Invertir te enseña a <strong>ser empresario…</strong><br />Invertir te hace <strong>responsable…</strong></p>

            </div>
            <nav class="pagination-single section-inner" aria-label="Publicación">
                <hr class="styled-separator is-style-wide" aria-hidden="true" />
                <div class="pagination-single-inner">
                    <Link className="previous-post" to="/tips-para-mejorar-tu-negocio/credito/">
                        <span class="arrow" aria-hidden="true">←</span>
                        <span class="title"><span class="title-inner">CRÉDITO</span></span>
                    </Link>
                    <Link className="next-post" to="/tips-para-mejorar-tu-negocio/ahorro/">
                        <span class="title"><span class="title-inner">AHORRO</span></span>
                        <span class="arrow" aria-hidden="true">→</span>
                    </Link>
                </div>
                <hr class="styled-separator is-style-wide" aria-hidden="true" />
            </nav>
        </div>
    );
}

export default Inversion;