import { useNavigate, Link } from 'react-router-dom';
import encabezado from '../../assets/images/Encabezados/encabezado-quienes-somos-banco-forjadores.png';
import lineas_quienes_somos from '../../assets/images/lineas_quienes_somos.png';
import tip1 from '../../assets/images/Fotos/50_consejos.jpg';
import tip2 from '../../assets/images/Fotos/presupuesto.jpg';
import tip3 from '../../assets/images/Fotos/ahorro.jpg';
import tip4 from '../../assets/images/Fotos/inversion.jpg';
import tip5 from '../../assets/images/Fotos/credito.jpg';
import tip6 from '../../assets/images/Fotos/seguros.jpg';
import tip7 from '../../assets/images/Fotos/consumo_responsable.jpg';

import '../../style/quienessomos.css';

function TipsMejorar() {
    return (
        <div className="TipsMejorar">
            <div className="tipsContainer">
                <h1>TIPS PARA MEJORAR TU NEGOCIO</h1>

                <ul className="display-posts-listing">

                    <li className="listing-item">
                        <Link className="image" to="/tips-para-mejorar-tu-negocio/50-consejos-para-emprender-tu-negocio-forjador/"><img src={tip1} alt="50 CONSEJOS PARA EMPRENDER TU NEGOCIO FORJADOR" /></Link>

                        <Link className="title" to="/tips-para-mejorar-tu-negocio/50-consejos-para-emprender-tu-negocio-forjador/">50 CONSEJOS PARA EMPRENDER TU NEGOCIO FORJADOR</Link>

                        <span className="excerpt">1 Conoce tus habilidades como Forjador Antes de invertir tiempo o recursos, Banco Forjadores te recomienda autoevaluarse y conocer si se cuenta con las habilidades típicas de un emprendedor. 2 Desarrolla una idea Forjadora Desarrolla un concepto del negocio que te apasione como también tengas la experiencia en dicha labor, con el fin de que…                             
                            <Link className="excerpt-more" to="/tips-para-mejorar-tu-negocio/50-consejos-para-emprender-tu-negocio-forjador/">Continuar leyendo</Link>
                        </span>                    
                    </li>
                    
                    <li className="listing-item">
                        <Link className="image" to="/tips-para-mejorar-tu-negocio/presupuesto/">
                            <img src={tip2} alt="" />
                        </Link>

                        <Link className="title" to="/tips-para-mejorar-tu-negocio/presupuesto/">
                            PRESUPUESTO
                        </Link>
                    
                        <span className="excerpt">Hacer un presupuesto es simplemente sentarse a planear lo que quieres hacer en el futuro y expresarlo en dinero.Un ejemplo son los viajes. Uno se pone a planear, entre otras cosas, cuánto hay que gastar en pasajes o gasolina, comidas y hospedaje.Cuando haces un presupuesto para tu empresa, en realidad lo que estás haciendo es… 
                            <Link className="excerpt-more" to="/tips-para-mejorar-tu-negocio/presupuesto/">Continuar leyendo</Link>
                        </span>                        
                    </li>
                
                    <li className="listing-item">
                        <Link className="image" to="/tips-para-mejorar-tu-negocio/ahorro/">
                            <img src={tip3} alt="" />
                        </Link>

                        <Link className="title" to="/tips-para-mejorar-tu-negocio/ahorro/">
                            AHORRO
                        </Link>

                        <span className="excerpt">Definición de AhorroEs aquella parte del ingreso que no se destina al consumo de bienes o servicios. Es la diferencia que existe entre los ingresos y los gastos. Es decir, el monto de los ingresos que no se gastó y que se puede guardar para usarlo en el futuro.Entonces, reserva ese capital fuera cualquier riesgo… 
                            <Link className="excerpt-more" to="/tips-para-mejorar-tu-negocio/ahorro/">Continuar leyendo</Link>
                        </span>
                    </li>
                    
                    <li className="listing-item">
                        <Link className="image" to="/tips-para-mejorar-tu-negocio/inversion/">
                            <img src={tip4} alt="" />
                        </Link>

                        <Link className="title" to="/tips-para-mejorar-tu-negocio/inversion/">
                            INVERSIÓN
                        </Link>

                        <span className="excerpt">OBJETIVOConocerá Técnicas y procedimientos para maximizar los recursos propios por medio de la inversión INVERSIÓN = Incrementar PatrimonioEs decir, generar un interés a través de instrumentos&nbsp; financieros.En una economía inflacionaria el dinero pierde su valor conforme pasa el tiempo, si mantenemos el dinero estático debajo del colchón este va a perder su valor. Por eso… 
                            <Link className="excerpt-more" to="/tips-para-mejorar-tu-negocio/inversion/">Continuar leyendo</Link>
                        </span>
                    </li>
                    
                    <li className="listing-item">
                        <Link className="image" to="/tips-para-mejorar-tu-negocio/credito/">
                            <img src={tip5} alt="" />
                        </Link>

                        <Link className="title" to="/tips-para-mejorar-tu-negocio/credito/">
                            CRÉDITO
                        </Link>

                        <span className="excerpt">Significado de créditoRaíz latina "creedere" = Confianza "Premiso para usar el capital de otro"- John Stuart Mill "Poder para obtener bienes y servicios por medio de la promesa de pago en una fecha determinada en el futuro"- Joseph Fred Johnson Crédito = Confianza Todas las relaciones de crédito se basan en la confianza. La confianza… 
                            <Link className="excerpt-more" to="/tips-para-mejorar-tu-negocio/credito/">Continuar leyendo</Link>
                        </span>
                    </li>
                
                    <li className="listing-item">
                        <Link className="image" to="/tips-para-mejorar-tu-negocio/seguros/">
                            <img src={tip6} alt="" />
                        </Link>

                        <Link className="title" to="/tips-para-mejorar-tu-negocio/seguros/">
                            SEGUROS
                        </Link>

                        <span className="excerpt">Definición de RiesgoEl riesgo es la probabilidad de que ocurra un accidente o un imprevisto que pueda dañar a la persona, la familia o el patrimonio.RiesgoPara protegernos de los riesgos podemos prevenirlos, y transferirlos, se pueden minimizar, pero nunca erradicar. SegurosCuando se habla de seguros, el comprador paga una prima que es el precio del… 
                            <Link className="excerpt-more" to="/tips-para-mejorar-tu-negocio/seguros/">Continuar leyendo</Link>
                        </span>
                    </li>
                    
                    <li className="listing-item">
                        <Link className="image" to="/tips-para-mejorar-tu-negocio/consumo-responsable/">
                            <img src={tip7} alt="" />
                        </Link>

                        <Link className="title" to="/tips-para-mejorar-tu-negocio/consumo-responsable/">
                            CONSUMO RESPONSABLE
                        </Link>

                        <span className="excerpt">¿Qué es ser un consumidor responsable?Es una persona informada y consciente de sus hábitos de consumo, así sus compras jamás causaran daño a la economía familiar, no perjudicaran el entorno ambiental, y buscará que tengan un efecto positivo en la sociedad. ¿Y el consumidor crítico que es?Es aquél que implica consumir menos, consumir solo lo… 
                            <Link className="excerpt-more" to="/tips-para-mejorar-tu-negocio/consumo-responsable/">Continuar leyendo</Link>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default TipsMejorar;
