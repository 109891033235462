import encabezado from '../../assets/images/Encabezados/encabezado-tips-de-seguridad.png';
import ABM from '../../assets/images/icono-tips-de-seguridad-de-la-abm.png';
import BANCO from '../../assets/images/icono-tips-de-seguridad-de-banco-forjadores.png';
import lineas from '../../assets/images/Linea_contactanos.png';

import { useNavigate, Link } from 'react-router-dom';

import '../../style/quienessomos.css';

function TipsSeguridad() {
    return (
        <div className="TipsSeguridad">

            <div className="securityIntro">
                <img src={encabezado} />

                <Link to="/quienes-somos/tips-de-seguridad/tips-de-la-abm/">
                    <img src={ABM} alt="Icono Tips de Seguridad de  la ABM" /> TIPS DE LA <b>ABM</b>
                </Link>
                
                <img src={lineas} className='lineas' />

                <Link to="/quienes-somos/tips-de-seguridad/tips-de-banco-forjadores/">
                    <img src={BANCO} alt="Icono Tips de Seguridad de Banco Forjadores" /> TIPS DE <b>BANCO FORJADORES</b>
                </Link>
            </div>

        </div>
    );
}

export default TipsSeguridad;
