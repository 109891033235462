import { useEffect, useRef } from "react";

function Accordion({open, children}) {
    const accordion = useRef(null);

    useEffect(() => {
        /* PREPARE ACCORDION FOR THE ANIMATION */
        if (open) {
            accordion.current.style.height = accordion.current.scrollHeight + 'px';
            accordion.current.classList.add('accordion-open');
        } else {
            accordion.current.style.height = '0px';
            accordion.current.classList.remove('accordion-open');
        }
    }, [open]);

    return (
        <div className="accordion-container" ref={accordion}>
            {children}
        </div>
    )
}

export default Accordion;