import encabezado from '../../../assets/images/Encabezados/encabezado-tips-de-seguridad-de-la-abm.png';
import lineas_separacion from '../../../assets/images/lineas-separacion-abm.png';
import lineas_separacion2 from '../../../assets/images/Unete_lineas.png';
import vishing from '../../../assets/images/tips-de-seguridad-de-la-abm-vishing.png';
import smishing from '../../../assets/images/tips-de-seguridad-de-la-abm-smishing.png';
import phishing from '../../../assets/images/tips-de-seguridad-de-la-abm-phishing.png';
import spoofing from '../../../assets/images/tips-de-seguridad-de-la-abm-spoofing.png';
import autenticacion from '../../../assets/images/tips-de-seguridad-de-la-abm-autenticacion.png';
import enganar from '../../../assets/images/tips-de-seguridad-de-la-abm-enganar.png';
import video from '../../../assets/campana-contra-estafas-y-fraudes-bancarios.mp4';

import '../../../style/quienessomos.css';

function TipsABM() {
    return (
        <div className="TipsABM">
            <div className="entry-content">
                <div className="abmIntro">
                    <img src={encabezado} />
                </div>

                <div className="abmTypes">
                    <img src={lineas_separacion} />
                    <h2>FRAUDES Y MODALIDADES </h2>
                    <h2><small>Los fraudes pueden ocurrir vía teléfonia, a través de mensajes de texto (SMS), correos electrónicos y enlaces</small></h2>

                    {/* <div className='info'>
                        <span class="active" data-target="vishing">
                        <img src={vishing} />
                        <p>VISHING</p>
                        </span><span data-target="smishing">
                        <img src={smishing} />
                        <p>SMISHING</p>
                        </span><span data-target="phishing">
                        <img src={phishing} />
                        <p>PHISHING</p>
                        </span><span data-target="spoofing">
                        <img src={spoofing} />
                        <p>SPOOFING</p>
                        </span>
                    </div>

                    <p className="vishing active"><big>VISHING</big> Se realiza mediante una llamada telefónica, en la que una <b>voz automatizada similar a la de tu banco</b>, pide tus datos confidenciales.<br/><br/><b>Ignora las llamadas que te pidan proporcionar tus datos confidenciales</b> y los mensajes de texto que te pidan realizar llamada.</p>

                    <p className="smishing"><big>SMISHING</big> Se comete a través de un <b>mensaje de texto (SMS)</b> que afirma ser tu banco. Si te informan que has ganado un premio o que necesitas proporcionar datos personales para resoler algún problema con tu cuenta, <b>no respondas</b>.<br/><br/><b>Ignora los mensajes de texto que te pidan realizar llamadas o compartir datos personales</b>.</p>
                    
                    <p className="phishing"><big>PHISHING</big> Estafas que llegan a través de un <b>correo electrónico que parece ser de tu banco</b>. El correo incluye un <b>enlace peligroso</b> que lleva a una página muy parecida a la de tu banco, para hacerte creer que es la real y así obtener tus datos. <b>Verifica el remitente</b>.<br/><br/><b>Los bancos no mandan correos con enlaces sospechosos</b>.</p>
                        
                    <p className="spoofing"><big>SPOOFING</big> Los delincuentes tienen los recursos para <b>enmascarar su número</b> y hacer que aparezca el nombre y/o número del banco en tu identificador de llamadas. <b>Desconfía</b> si te piden datos.<br/><br/><b>Cuelga y llama a tu banco</b>.</p> */}
                        
                    <img src={lineas_separacion2} className='lines2'/>
                </div>

                <div className="abmCampaign">
                    <h2>CAMPAÑA CON ESTAFAS Y FRAUDES BANCARIOS</h2>
                    <video playsinline="" controls="" src={video} poster="" data-origwidth="0" data-origheight="0"></video>
                </div>

                <div className="abmAuth">
                    <img src={autenticacion} /><div>
                    <h2>AUTENTICACIÓN</h2>
                    <p>Si llamas al número oficial de tu banco pueden solicitar algunos datos personales para asegurarque realmente eres tú. Si el banco te llama primero, no solicitará información confidencial.</p>
                    <span><b>Lo que tu banco puede pedir como autenticación:</b><br/><br/>• Nombre completo<br/>• Fecha de nacimiento<br/>• RFC<br/>• Dirección<br/>• Correo electrónico<br/>• Número de teléfono</span><span><b>Lo que tu banco puede pedir como autenticación:</b><br/><br/>• Nombre completo<br/>• Fecha de nacimiento<br/>• RFC<br/>• Dirección<br/>• Correo electrónico<br/>• Número de teléfono</span>
                    </div>
                </div>

                <div id="abmScam">
                    <img src={enganar} />
                    <div>
                        <h2>NO SE DEJE ENGAÑAR</h2>
                        <p>Los delincuentes <b>utilizan distintos mecanismos para llamar tu atención</b> y obtener tus datos. A veces es algo muy espectacular como un <b>premio u oferta</b> para la adquisición de productos y servicios. “Otras veces es algo que te puede asustar como un <b>supuesto problema</b>, por ejemplo:” operaciones sospechosas, depósitos retenidos, bloqueos de cuenta o de tarjeta, accesos misteriosos; o bien, que es necesario actualizar tus datos. Rechaza todo lo que parezca demasiado bueno para ser verdad.<br /><br />Ante cualquier duda o sospecha, <b>comunícate directo</b> a tu banco o acude a la sucursal.</p>
                    </div>
                    <div>
                        <h2>CONTRASEÑAS,<br /> TOKEN, NIP Y CVV</h2>
                        <p>Nunca compartas tu acceso, contraseña, número de token, NIP o el código de seguridad que aparece al reverso de tu tarjeta (CVV) a través del internet.<br /><br /><b>Tu banco no te contactará para solicitar datos confidenciales como: acceso, contraseña, token, NIP o CVV.</b></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TipsABM;