import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../style/menu.css';
import isotipo from '../../assets/images/Iconos/isotipo.png';
import Accordion from '../Accordion';
import Codigo from "../../assets/documents/CE-26-Codigo-de-Etica-y-Conducta.pdf";

function Menu({ open, setOpen }) {
	const [shouldAnimate, setShouldAnimate] = useState(false);
	const [openQuienesSomos, setOpenQuienesSomos] = useState(false);
	const [openCreditos, setOpenCreditos] = useState(false);
	const [openInversiones, setOpenInversiones] = useState(false);
	const [openProgramasBeneficios, setOpenProgramasBeneficios] = useState(false);

	useEffect(() => {
		if (open) {
			setShouldAnimate(true);
		}
	}, [open]);

	const navigate = useNavigate();

	return (
		<div
			className={`menu-modal ${open ? 'open' : shouldAnimate ? 'close' : ''}`}
		>
			<div className="close-container">
				<img className="isotipo" src={isotipo} alt="Isotipo" />
				<button
					onClick={() => {
						setOpen(false);
					}}
					className="close-button row"
				>
					<span>Cerrar el menú</span>

					<svg
						className="svg-icon"
						aria-hidden="true"
						role="img"
						focusable="false"
						xmlns="http://www.w3.org/2000/svg"
						width="30"
						height="30"
						viewBox="0 0 16 16"
					>
						<polygon
							fill=""
							fillRule="evenodd"
							points="6.852 7.649 .399 1.195 1.445 .149 7.899 6.602 14.352 .149 15.399 1.195 8.945 7.649 15.399 14.102 14.352 15.149 7.899 8.695 1.445 15.149 .399 14.102"
						></polygon>
					</svg>
				</button>
			</div>
			<nav>
				<ul className="menu">
					<li className="active" >
						<button className="btn-list"
							onClick={() => {
								navigate("/");
								setOpen(false);
							}}
						>Inicio</button>
					</li>
					{/* QUINES SOMOS */}
					<li >
						<button
							className="btn-list"
							onClick={() => {
								navigate("/quienes-somos");
								setOpen(false);
							}}>
							Quiénes somos
						</button>
						<svg
							onClick={() => {
								setOpenQuienesSomos(!openQuienesSomos);
							}}
							style={{
								transform: openQuienesSomos
									? 'rotate(180deg)'
									: 'rotate(0deg)',
							}}
							className="svg-icon"
							aria-hidden="true"
							role="img"
							focusable="false"
							xmlns="http://www.w3.org/2000/svg"
							width="15"
							height="9"
							viewBox="0 0 20 12"
						>
							<polygon
								fill="#fff"
								fillRule="evenodd"
								points="1319.899 365.778 1327.678 358 1329.799 360.121 1319.899 370.021 1310 360.121 1312.121 358"
								transform="translate(-1310 -358)"
							></polygon>
						</svg>
					</li>
					<Accordion open={openQuienesSomos}>
						<div className="submenu">
							<button className="btn-list"
								onClick={() => {
									navigate("/quienes-somos/nuestra-historia");
									setOpen(false);
								}}>
								Nuestra Historia
							</button>
							<button className="btn-list" onClick={() => {
								navigate("/quienes-somos/compromiso");
								setOpen(false);
							}}>Compromiso</button>
							<button className="btn-list"
								onClick={() => {
									navigate("/quienes-somos/relacion-con-inversionistas");
									setOpen(false);
								}}>
								Relación con Inversionistas
							</button>
							<button className="btn-list"
								onClick={() => {
									navigate("/quienes-somos/trabaja-con-nosotros");
									setOpen(false);
								}}>
								Trabaja con nosotros
							</button>
							<button className="btn-list"
								onClick={() => {
									navigate("/quienes-somos/tips-para-mejorar-tu-negocio");
									setOpen(false);
								}}>
								Tips para Mejorar tu Negocio
							</button>
							<button className="btn-list"
								onClick={() => {
									navigate("/quienes-somos/tips-de-seguridad");
									setOpen(false);
								}}>
								Tips de Seguridad
							</button>
							<button className="btn-list">
								<a href={Codigo} target='_blank'>Código de Ética y Conducta</a>
							</button>
						</div>
					</Accordion>

					{/* CREDITOS */}
					<li>
						<button
							onClick={() => {
								setOpenCreditos(!openCreditos);
							}}
							className="btn-list"
						>
							Créditos
						</button>
						<svg
							onClick={() => {
								setOpenCreditos(!openCreditos);
							}}
							style={{
								transform: openCreditos
									? 'rotate(180deg)'
									: 'rotate(0deg)',
							}}
							className="svg-icon"
							aria-hidden="true"
							role="img"
							focusable="false"
							xmlns="http://www.w3.org/2000/svg"
							width="15"
							height="9"
							viewBox="0 0 20 12"
						>
							<polygon
								fill="#fff"
								fillRule="evenodd"
								points="1319.899 365.778 1327.678 358 1329.799 360.121 1319.899 370.021 1310 360.121 1312.121 358"
								transform="translate(-1310 -358)"
							></polygon>
						</svg>
					</li>
					<Accordion open={openCreditos}>
						<div className="submenu">
							<button className="btn-list"
								onClick={() => {
									navigate("/creditos/credito-grupal/");
									setOpen(false);
								}}>Crédito Grupal</button>
							<button className="btn-list"
								onClick={() => {
									navigate("/creditos/credito-adicional/");
									setOpen(false);
								}}>
								Crédito Adicional
							</button>
							<button className="btn-list"
								onClick={() => {
									navigate("/creditos/credito-interciclo/");
									setOpen(false);
								}}>
								Crédito Interciclo
							</button>
						</div>
					</Accordion>

					{/* INVERSIONES */}
					<li>
						<button
							onClick={() => {
								navigate("/inversiones");
								setOpen(false);
							}}
							className="btn-list"
						>
							Inversiones
						</button>
						<svg
							onClick={() => {
								setOpenInversiones(!openInversiones);
							}}
							style={{
								transform: openInversiones
									? 'rotate(180deg)'
									: 'rotate(0deg)',
							}}
							className="svg-icon"
							aria-hidden="true"
							role="img"
							focusable="false"
							xmlns="http://www.w3.org/2000/svg"
							width="15"
							height="9"
							viewBox="0 0 20 12"
						>
							<polygon
								fill="#fff"
								fillRule="evenodd"
								points="1319.899 365.778 1327.678 358 1329.799 360.121 1319.899 370.021 1310 360.121 1312.121 358"
								transform="translate(-1310 -358)"
							></polygon>
						</svg>
					</li>
					<Accordion open={openInversiones}>
						<div className="submenu">
							<button className="btn-list"
								onClick={() => {
									navigate("/inversiones/pagare-forjadores");
									setOpen(false);
								}}>
								Pagaré Forjadores
							</button>
							<button className="btn-list"
								onClick={() => {
									navigate("/inversiones/forjadores-cede");
									setOpen(false);
								}}>
								Forjadores CEDE
							</button>
						</div>
					</Accordion>
					<li>
						<button className="btn-list"
							onClick={() => {
								navigate("/asistencias-combo");
								setOpen(false);
							}}>Asistencias Combo</button>
					</li>

					{/* PROGRAMAS DE BENEFICIOS */}
					<li>
						<button
							onClick={() => {
								navigate("/programas-de-beneficios");
								setOpen(false);
							}}
							className="btn-list"
						>
							Programas de beneficios
						</button>
						<svg
							onClick={() => {
								setOpenProgramasBeneficios(
									!openProgramasBeneficios,
								);
							}}
							style={{
								transform: openProgramasBeneficios
									? 'rotate(180deg)'
									: 'rotate(0deg)',
							}}
							className="svg-icon"
							aria-hidden="true"
							role="img"
							focusable="false"
							xmlns="http://www.w3.org/2000/svg"
							width="15"
							height="9"
							viewBox="0 0 20 12"
						>
							<polygon
								fill="#fff"
								fillRule="evenodd"
								points="1319.899 365.778 1327.678 358 1329.799 360.121 1319.899 370.021 1310 360.121 1312.121 358"
								transform="translate(-1310 -358)"
							></polygon>
						</svg>
					</li>
					<Accordion open={openProgramasBeneficios}>
						<div className="submenu">
							<button className="btn-list"
								onClick={() => {
									navigate("/programas-de-beneficios/recomienda-y-gana");
									setOpen(false);
								}}>
								Recomienda y Gana
							</button>
						</div>
					</Accordion>

					<li>
						<button className="btn-list"
							onClick={() => {
								navigate("/contacto");
								setOpen(false);
							}}>Contacto</button>
					</li>
					<li>
						<button className="btn-list"
							onClick={() => {
								navigate("/contratos");
								setOpen(false);
							}}>Contratos</button>
					</li>
					<li>
						<button className="btn-list"
							onClick={() => {
								navigate("/costos-y-comisiones-de-productos-de-credito/");
								setOpen(false);
							}}>
							Consulta los costos y las comisiones de
							<br /> nuestros productos
						</button>
					</li>
					<li>
						<button className="btn-list"
							onClick={() => {
								navigate("/quienes-somos/trabaja-con-nosotros");
								setOpen(false);
							}}>
							Trabaja con nosotros
						</button>
					</li>
				</ul>
			</nav>
		</div>
	);
}

export default Menu;
