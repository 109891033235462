
import '../../style/creditos.css';
import encabezado from "../../assets/images/Encabezados/encabezado-credito-adicional.jpg";
import forjandohistorias from "../../assets/images/forjando-historias-de-exito-credito.png";
import linea_formulario_contrata from "../../assets/images/Individual_lineas_contrata.png";
import icono_correo_contactanos from '../../assets/images/Iconos/icono_correo_contactanos.png';
import icono_whats_contactanos from '../../assets/images/Iconos/icono_whatsapp.png';

function CreditoAdicional() {
    return (
        <div class="entry-content">

            <div id="additionalCredit" class="service-intro">
                <img className='bann' src={encabezado} alt="Encabezado Crédito Adicional - Banco Forjadores" />
                <h2>¿Ya tienes tu crédito grupal?</h2>
                <p>Eres cliente activo y tienes excelente comportamiento de pago, entonces nuestro nuevo crédito te ayudará a obtener un capital de trabajo, activos fijos o pagos de pasivos.</p>
                <img src={forjandohistorias} alt="Frase: Forjando Historias de Éxito" />
            </div>
            
            <div id="additionalCredit" class="service-description">
                <span>
                    <h2>QUIERO OBTENER<br/> UN CRÉDITO ADICIONAL</h2>
                    <a class="forjadores-btn" href="#service-quote">CONTRATAR</a>
                </span>
                <div>
                    <h3>CARACTERÍSTICAS</h3>
                    {/* <div id='Car-CredGpo'></div> */}
                    <ul>
                        <li>Monto del préstamo desde $25,000 mxn hasta $65,000 mxn</li>
                        <li>Plazo de 3 a 12 meses</li>
                        <li>Pago Mensual o quincenal</li>
                        <li>Seguro con cobertura Saldo Deudor</li>
                        <li>Comisiones por pago tardio o incompleto por $300 MXN IVA incluido</li>
                    </ul>
                </div>
                <div>
                    <h3>REQUISITOS</h3>
                    {/* <div id='Req-CredGpo'></div> */}
                    <ul>
                        <li>Tener un crédito grupal vigente y al corriente en sus pagos</li>
                        <li>Antigüedad en el Banco de al menos 5 ciclos</li>
                        <li>Se solicita a partir de la semana 4 del ciclo grupal actual</li>
                        <li>Se requiere solicitud de crédito firmada por cliente</li>
                        <li>Copia de la identificación oficial del cliente</li>
                        <li>Validación de la capacidad de pago</li>
                        <li>Consulta al Buró de Crédito</li>
                    </ul>
                </div>

                <p>
                    <big>LEYENDAS SOBRE RIESGOS</big> – “Incumplir tus obligaciones te puede generar comisiones e intereses moratorios”<br/>– “Contratar créditos que excedan tu capacidad de pago afecta tu historial crediticio”<br/>– “El avalista, obligado solidario o coacreditado responderá como obligado principal por el total del pago frente a la Institución Financiera”<br/>– “Las operaciones realizadas a través de los comisionistas bancarios podrán generar una comisión, consulte antes de realizar su operación”<br/><br/>Este producto se oferta en todas las oficinas, consulta su ubicación en la parte superior de esta página
                </p>
            </div>

            <div class="service-quote" id='service-quote'>
                <h2>¿CUMPLES CON TODOS LOS <b>REQUISITOS</b>?</h2>
                <div className="row contact-container">
                    <div className="info-container">
                        <p>
                            <big>¡Contrata tu Crédito Adicional ahora!</big>

                            Llámanos al<br />
                            <a className='highlighted' href="tel:8008300005">800 830 0005</a>

                            <img
                                decoding="async"
                                src={linea_formulario_contrata}
                                data-ll-status="loaded"
                            />

                            <a className="highlighted" href="https://api.whatsapp.com/send?phone=5215580507824" target="_blank" rel="noopener">
                                <img className="info-icon" decoding="async" src={icono_whats_contactanos} data-ll-status="loaded" />
                                55 8039 8668
                            </a>

                            <a className="highlighted" href="mailto:atencionaclientes@forjadores.com.mx"
                            >
                                <img className="info-icon" decoding="async" src={icono_correo_contactanos} data-ll-status="loaded"
                                />
                                atencionaclientes@forjadores.com.mx
                            </a>

                            Déjanos tus datos y empieza desde hoy tu proceso
                        </p>
                    </div>
                    <div className="form-container">
                        <form>
                            <div className="row" style={{ gap: '25px' }}>
                                <div className="form-item">
                                    <label htmlFor="nombre">
                                        Nombre
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="nombre"
                                        name="nombre"
                                        required
                                    />
                                </div>

                                <div className="form-item">
                                    <label htmlFor="apellido">
                                        Apellido
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="apellido"
                                        name="apellido"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row" style={{ gap: '25px' }}>
                                <div className="form-item">
                                    <label htmlFor="email">
                                        Email
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        required
                                    />
                                </div>

                                <div className="form-item">
                                    <label htmlFor="telefono">
                                        Teléfono
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="tel"
                                        id="telefono"
                                        name="telefono"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row" style={{ gap: '25px' }}>
                                <div className="form-item">
                                    <label htmlFor="estado">
                                        Estado
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="estado"
                                        name="estado"
                                        required
                                    />
                                </div>

                                <div className="form-item">
                                    <label htmlFor="municipio">
                                        Municipio
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="tel"
                                        id="municipio"
                                        name="municipio"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="form-item" style={{ width: '100%' }}>
                                <label htmlFor="mensaje">
                                    Mensaje
                                    <span className="erf-required">*</span>
                                </label>
                                <textarea
                                    style={{ height: 'unset !important' }}
                                    rows="5"
                                    id="mensaje"
                                    required
                                ></textarea>
                            </div>

                            <button type="submit" className="forjadores-btn">
                                Enviar
                            </button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CreditoAdicional; 