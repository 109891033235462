import encabezado_recomienda_y_gana from '../../assets/images/Encabezados/encabezado-recomienda-y-gana.png';
function RecomiendaGana() {
	return (
		<div className="recomienda-y-gana">
			<section
				className="encabezado"
				style={{
					padding: '0px 5%',
				}}
			>
				<img
					src={encabezado_recomienda_y_gana}
					alt="Encabezado recomienda y gana"
				/>
				<p className='simple-text'>
					<big>
						<b>Gana $150</b> pesos por cada cliente que invites a
						formar parte de un grupo nuevo
					</big>
					<br />
					<br />
					Términos y condiciones:
					<br />
					&nbsp;&nbsp;– Vigencia al 31 de diciembre 2024
					<br />
					&nbsp;&nbsp;– Las personas referentes deben tener un crédito
					activo y al corriente en sus pagos
					<br />
					&nbsp;&nbsp;– El pago de $150 pesos es por cada persona
					invitada que forme parte de un grupo nuevo
					<br />
					&nbsp;&nbsp;– El premio se deposita en un monedero
					electrónico
				</p>
			</section>
		</div>
	);
}
export default RecomiendaGana;
