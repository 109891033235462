
import '../../style/asistencias.css';
import bannerasis from '../../assets/images/Encabezados/encabezado_asistencias.png';
import asismed from '../../assets/images/Fotos/asistencia-medica.jpg';
import asisfun from '../../assets/images/Fotos/asistencia-funeraria.jpg';
import asisden from '../../assets/images/Fotos/asistencia-dental.jpg';
import asishog from '../../assets/images/Fotos/cobertura-hogar.jpg';
import linea_formulario_contrata from "../../assets/images/Individual_lineas_contrata.png";
import icono_correo_contactanos from '../../assets/images/Iconos/icono_correo_contactanos.png';
import icono_whats_contactanos from '../../assets/images/Iconos/icono_whatsapp.png';


function Asistencias() {
    return (
        <div className="entry-content">
            <div id="careService" className="service-intro">
                <img src={bannerasis} alt="Encabezado Asistencias Combo - Banco Forjadores" />
            </div>
            <div id="careCharacteristics">
                <h2>Características</h2>
                <div>
                    <img src={asismed} alt="Asistencia Medica" decoding="async" data-ll-status="loaded" />
                    <h3>Asistencia Médica <br/>$140.00 Cuatrimestral <small>Sin límite de eventos<br/><br/></small></h3>
                    <ul>
                        <li>Medico a domicilio, medico general con un costo de $350.00, dos eventos al cuatrimestre</li>
                        <li>Reembolso de medicamentos hasta por $350.00, dos eventos al cuatrimestre</li>
                        <li>Check up básico (Un evento al cuatrimestre)</li>
                        <li>Descuentos en hospitales y clínicas del 5% al 25%</li>
                    </ul>
                    <p>Sin límite de eventos:</p>
                    <ul>
                        <li>Asistencia telefónica psicológica</li>
                        <li>Asistencia telefónica nutricional</li>
                        <li>Asistencia telefónica legal</li>
                        <li>Asistencia telefónica Medica</li>
                        <li>Referencias médicas de clínicas, hospitales y ambulancias, sólo información telefónica</li>
                        <li>Laboratorios del 10% al 60%</li>
                    </ul>
                </div>
                <div>
                    <img src={asisfun} alt="Asistencia Funeraria" decoding="async" data-ll-status="loaded" />
                    <h3>Asistencia Funeraria <br/>$160.00 Cuatrimestral <small>Un evento cuatrimestral <br/>Cobertura Titular / Edad: 18 a 75 años</small></h3>
                    <ul>
                        <li>Asesoría legal y administrativa</li>
                        <li>En caso de fallecimiento violento podrá solicitar la presencia de un abogado para gestionar los trámites legales</li>
                        <li>Asistencia legal sucesoria y pensiones</li>
                        <li>Ataúd básico estándar</li>
                        <li>Acondicionamiento estético del cuerpo</li>
                        <li>Traslado del cuerpo al lugar de velación</li>
                        <li>Sala de velación o implementos para velar en casa</li>
                        <li>En su caso, servicio de Cremación y Urna estándar para cenizas</li>
                        <li>Traslado al cementerio</li>
                        <li>Asistencia telefónica ilimitada 24/7</li>
                        <li>Asistencia tanatológica</li>
                    </ul>
                </div>
                <div>
                    <img src={asisden} alt="Asistencia Dental" decoding="async" data-ll-status="loaded" />
                    <h3>Asistencia Dental <br/>$240.00 Cuatrimestral <small>Sin límite de eventos <br/>Cobertura Titular / Edad: 18 a 75 años</small></h3>
                    <ul>
                        <li>Consulta gratuita</li>
                        <li>1 evento al cuatrimestre hasta $500</li>
                        <li>Limpieza Oral Preventiva. (Pulido de dientes con pastas abrasivas y cepillos giratorios y/o copas de hule. 1 eventos al cuatrimestre hasta $500</li>
                        <li>Precios preferenciales en consultas y servicios dentales</li>
                    </ul>
                </div>
                <div>
                    <img src={asishog} alt="Cobertura Hogar" decoding="async" data-ll-status="loaded" />
                    <h3>Cobertura Hogar <br/>$160.00 Cuatrimestral <small>Cobertura Titular<br/><br/></small></h3>
                    <p>Para tu tranquilidad con alguna emergencia en tu Hogar, cubriendo mano de obra y materiales hasta $500 pesos por evento (un evento cuatrimestral) o bien obteniendo precios preferentes en caso de gastos excedentes. Te asistimos en cualquiera de los siguientes servicios:</p>
                    <ul>
                        <li>Cerrajería</li>
                        <li>Plomería</li>
                        <li>Vidriería</li>
                        <li>Electricidad</li>
                    </ul>
                </div>
            </div>
            <div className="service-quote" id='service-quote'>
                <h2>¿YA TIENES TUS <b>DOCUMENTOS LISTOS</b>?</h2>
                <div className="row contact-container">
                    <div className="info-container">
                        <p>
                            <big>¡Déjanos ayudarte a proteger lo que más te importa!</big>

                            Llámanos al<br />
                            <a className='highlighted' href="tel:8008300005">800 830 0005</a>

                            <img
                                decoding="async"
                                src={linea_formulario_contrata}
                                data-ll-status="loaded"
                            />

                            <a className="highlighted" href="https://api.whatsapp.com/send?phone=5215580507824" target="_blank" rel="noopener">
                                <img className="info-icon" decoding="async" src={icono_whats_contactanos} data-ll-status="loaded" />
                                55 8039 8668
                            </a>

                            <a className="highlighted" href="mailto:atencionaclientes@forjadores.com.mx"
                            >
                                <img className="info-icon" decoding="async" src={icono_correo_contactanos} data-ll-status="loaded"
                                />
                                atencionaclientes@forjadores.com.mx
                            </a>

                            Déjanos tus datos y empieza desde hoy tu proceso
                        </p>
                    </div>
                    <div className="form-container">
                        <form>
                            <div className="row" style={{ gap: '25px' }}>
                                <div className="form-item">
                                    <label htmlFor="nombre">
                                        Nombre
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="nombre"
                                        name="nombre"
                                        required
                                    />
                                </div>

                                <div className="form-item">
                                    <label htmlFor="apellido">
                                        Apellido
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="apellido"
                                        name="apellido"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row" style={{ gap: '25px' }}>
                                <div className="form-item">
                                    <label htmlFor="email">
                                        Email
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        required
                                    />
                                </div>

                                <div className="form-item">
                                    <label htmlFor="telefono">
                                        Teléfono
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="tel"
                                        id="telefono"
                                        name="telefono"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row" style={{ gap: '25px' }}>
                                <div className="form-item">
                                    <label htmlFor="estado">
                                        Estado
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="estado"
                                        name="estado"
                                        required
                                    />
                                </div>

                                <div className="form-item">
                                    <label htmlFor="municipio">
                                        Municipio
                                        <span className="erf-required">*</span>
                                    </label>
                                    <input
                                        type="tel"
                                        id="municipio"
                                        name="municipio"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="form-item" style={{ width: '100%' }}>
                                <label htmlFor="mensaje">
                                    Mensaje
                                    <span className="erf-required">*</span>
                                </label>
                                <textarea
                                    style={{ height: 'unset !important' }}
                                    rows="5"
                                    id="mensaje"
                                    required
                                ></textarea>
                            </div>

                            <button type="submit" className="forjadores-btn">
                                Enviar
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Asistencias; 