import '../../style/inicio.css';


function UnidadEspe() {
    return (
        <div className="entry-content">
            <div id="uneContainer">
                <p>
                    <b>UNIDAD ESPECIALIZADA (UNE) DE BANCO FORJADORES</b> Tel: <a href="tel:5556112450">55 5611 2450</a> opción 1 Ciudad de México<br/> 
                    
                    Lada sin costo <a href="tel:8008300005">800 830 0005</a><br/>
                    
                    <a href="mailto:unidad_especializada@forjadores.com.mx">unidad_especializada@forjadores.com.mx</a> <small>En su caso puede acudir a la Delegación Estatal que corresponda según tu demanda COMISIÓN NACIONAL PARA LA PROTECCIÓN Y DEFENSA DE LOS USUARIOS DE LOS SERVICIOS FINANCIEROS (CONDUSEF)</small>

                    <a target="_blank" rel="noopener" href="https://www.forjadores.mx/wp-content/uploads/2024/02/Aviso-UNE_-Febrero-2024.pdf">Consulta AQUÍ el nombre del titular y encargados regionales de la UNE</a>
                </p>
                <br/>

                <a href="mailto:asesoria@condusef.gob.mx">asesoria@condusef.gob.mx</a><br/>
                <a href="https://www.condusef.gob.mx/" target="_blank" rel="noopener"><b>www.condusef.gob.mx</b></a>
                <br/>
                <a href="tel:5553400999">55 5340 0999</a><br/>
                <a href="tel:8009998080">800 999 8080</a>
            </div>
        </div>
    );
}

export default UnidadEspe;