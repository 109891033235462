
import '../../style/infohead.css';
import DespachoCobranzaPDF from "../../assets/documents/Despachos-de-Cobranza-septiembre-2024.pdf";

function DespachoCobranza() {
    return (
        <div className="entry-content">
            <div className="officesContainer">
                <h1>Consulta <a href={DespachoCobranzaPDF}>aquí</a> los despachos de cobranza</h1>

                <p>A continuación se señala la información para que pueda identificar y localizar a los despachos de cobranza designados por Banco Forjadores para realizar gestiones de cobranza.</p>

                <a className="forjadores-btn" href={DespachoCobranzaPDF} target="_blank" rel="noopener">CONSULTAR DATOS DE DESPACHO</a>

            </div>
        </div>
    );
}

export default DespachoCobranza;