import encabezado from '../../assets/images/Encabezados/encabezado_contactanos.png';
import linea_formulario_contactanos from '../../assets/images/Linea_formulario_contactanos.png';
import icono_correo_contactanos from '../../assets/images/Iconos/icono_correo_contactanos.png';
import icono_whats_contactanos from '../../assets/images/Iconos/icono_whatsapp.png';
import '../../style/contacto.css';

function Contacto() {
	return (
		<div className="contacto">
			<section className="encabezado">
				<img src={encabezado} />
			</section>
			<h2 className="banner">
				<b>DÉJANOS TUS DATOS</b> Y EN BREVE UN ASESOR SE PONDRÁ EN{' '}
				<b>CONTACTO CONTIGO</b>
			</h2>
			<div className="row contact-container">
				<div className="info-container">
					<h2>BANCO FORJADORES (Corporativo)</h2>

					<a
						className="highlighted"
						href="https://goo.gl/maps/qFzawYgGGWGoU5J69"
						target="_blank"
						rel="noopener"
					>
						Avenida Insurgentes sur No. 617, piso 10, Col. Nápoles,
						C.P. 03810, México, CDMX
					</a>

					<div>
						<p className="info-title">Llámanos al</p>
						<a className="highlighted" href="tel:8008300005">
							<big>800 830 0005</big>
						</a>
					</div>

					<img
						style={{ margin: '-40px 0px' }}
						decoding="async"
						src={linea_formulario_contactanos}
						data-ll-status="loaded"
					/>

					<div>
						<p className="info-title">Escríbenos</p>
						<a
							className="highlighted"
							href="mailto:atencionaclientes@forjadores.com.mx"
						>
							<img
								className="info-icon"
								decoding="async"
								src={icono_correo_contactanos}
								data-ll-status="loaded"
							/>
							atencionaclientes@forjadores.com.mx
						</a>
						<a
							className="highlighted"
							href="https://api.whatsapp.com/send?phone=5215580507824"
							target="_blank"
							rel="noopener"
						>
							<img
								className="info-icon"
								decoding="async"
								src={icono_whats_contactanos}
								data-ll-status="loaded"
							/>
							55 8039 8668
						</a>
					</div>
				</div>
				<div className="form-container">
					<form>
						<div className="row" style={{ gap: '25px' }}>
							<div className="form-item">
								<label htmlFor="nombre">
									Nombre
									<span className="erf-required">*</span>
								</label>
								<input
									type="text"
									id="nombre"
									name="nombre"
									required
								/>
							</div>

							<div className="form-item">
								<label htmlFor="apellido">
									Apellido
									<span className="erf-required">*</span>
								</label>
								<input
									type="text"
									id="apellido"
									name="apellido"
									required
								/>
							</div>
						</div>

						<div className="row" style={{ gap: '25px' }}>
							<div className="form-item">
								<label htmlFor="email">
									Email
									<span className="erf-required">*</span>
								</label>
								<input
									type="text"
									id="email"
									name="email"
									required
								/>
							</div>

							<div className="form-item">
								<label htmlFor="telefono">
									Teléfono
									<span className="erf-required">*</span>
								</label>
								<input
									type="tel"
									id="telefono"
									name="telefono"
									required
								/>
							</div>
						</div>

						<div className="row" style={{ gap: '25px' }}>
							<div className="form-item">
								<label htmlFor="estado">
									Estado
									<span className="erf-required">*</span>
								</label>
								<input
									type="text"
									id="estado"
									name="estado"
									required
								/>
							</div>

							<div className="form-item">
								<label htmlFor="municipio">
									Municipio
									<span className="erf-required">*</span>
								</label>
								<input
									type="tel"
									id="municipio"
									name="municipio"
									required
								/>
							</div>
						</div>

						<div className="form-item" style={{ width: '100%' }}>
							<label htmlFor="producto">
								¿Qué producto te interesa?
								<span className="erf-required">*</span>
							</label>
							<select id="producto" required>
								<option value="Crédito Adicional">
									Crédito Adicional
								</option>
								<option value="Crédito Grupal">
									Crédito Grupal
								</option>
								<option value="Crédito Interciclo">
									Crédito Interciclo
								</option>
								<option value="Seguro de Vida">
									Seguro de Vida
								</option>
								<option value="Seguro de Gastos Funerarios">
									Seguro de Gastos Funerarios
								</option>
								<option value="PAGARÉ FORJADORES">
									PAGARÉ FORJADORES
								</option>
								<option value="FORJADORES CEDE">
									FORJADORES CEDE
								</option>
							</select>
						</div>

						<div className="form-item" style={{ width: '100%' }}>
							<label htmlFor="mensaje">
								Mensaje
								<span className="erf-required">*</span>
							</label>
							<textarea
								style={{ height: 'unset !important' }}
								rows="5"
								id="mensaje"
								required
							></textarea>
						</div>

						<button type="submit" className="forjadores-btn">
							Enviar
						</button>
					</form>
				</div>
			</div>
		</div>
	);
}

export default Contacto;
