
import '../../style/creditos.css';
import isotipo from '../../assets/images/Iconos/isotipo.png';

function Glosario() {
    return (
        <div className="entry-content">
            <div class="terms-glossary">
                <h1> <img src={isotipo} alt="" /> Glosario de Terminología Legal Crédito Grupal</h1>                
                <p>
                    DISPOSICIONES LEGALES REFERIDAS EN EL CONTRATO DE CRÉDITO SIMPLE PARA EL PRODUCTO DENOMINADO CRÉDITO GRUPAL.
                    <br/><br/>                
                    <b>Artículo 56. Ley de Protección y Defensa al Usuario de Servicios Financieros.</b>
                    <br/>
                    Se cita: “Como una medida de protección al Usuario, la Comisión Nacional revisará y, en su caso, propondrá a las Instituciones Financieras, modificaciones a los modelos de contratos de adhesión utilizados en sus diversas operaciones, en términos de lo dispuesto en la fracción XVIII, del artículo 11 de esta Ley.<br/>Se entenderá por contrato de adhesión, para efectos de esta Ley, aquel elaborado unilateralmente por una Institución Financiera, cuyas estipulaciones sobre los términos y condiciones aplicables a la contratación de operaciones o servicios sean uniformes para los Usuarios”.
                    <br/><br/>
                    <b>Artículo 57. Ley de Protección y Defensa al Usuario de Servicios Financieros.</b>
                    <br/>
                    Se cita: “La revisión que, en su caso, se haga de los contratos de adhesión, tendrá por objeto determinar que se ajusten a los ordenamientos correspondientes y a las disposiciones emitidas conforme a ellos, así como verificar que dichos documentos no contengan estipulaciones confusas o que no permitan a los Usuarios conocer claramente el alcance de las obligaciones de los contratantes.
                    <br/><br/>
                    <b>Artículo 294. Ley General de Títulos y Operaciones de Crédito.</b>
                    <br/>
                    Se cita: “Cuando no se estipule término, se entenderá que cualquiera de las partes puede dar por concluido el contrato en todo tiempo, notificándolo así a la otra como queda dicho respecto del aviso a que se refiere el párrafo anterior.”
                    <br/><br/>
                    <b>Artículo 295. Ley General de Títulos y Operaciones de Crédito.</b>
                    <br/>
                    Se cita: “Salvo convenio en contrario, el acreditado puede disponer a la vista de la suma objeto del contrato”.
                    <br/><br/>
                    <b>Artículo 299. Ley General de Títulos y Operaciones de Crédito.</b>
                    <br/>
                    Se cita: “El otorgamiento o trasmisión de un título de crédito o de cualquier otro documento por el acreditado al acreditante, como reconocimiento del adeudo que a cargo de aquel resulte en virtud de las disposiciones que haga del crédito concedido, no facultan al acreditante para descontar o ceder el crédito así documentado, antes de su vencimiento, sino hasta cuando el acreditado lo autorice a ello expresamente. Negociado o cedido el crédito por el acreditante, este abonara al acreditado, desde la fecha de tales actos, los intereses correspondientes al importe de la disposición de que dicho crédito proceda, conforme al tipo estipulado en la apertura de crédito; pero el crédito concedido no se entenderá renovado por esa cantidad, sino cuando las partes así lo hayan convenido”.
                    <br/><br/>
                    <b>Artículo 1834. Código Civil para el Distrito Federal</b>
                    <br/>
                    Se cita: “Cuando se exija la forma escrita para el contrato, los documentos relativos deben ser firmados por todas las personas a las cuales se imponga esa obligación. Si alguna de ellas no puede o no sabe firmar, lo hará otra a su ruego y en el documento se imprimirá la huella digital del interesado que no firmó”.
                    <br/><br/>
                    <b>Artículo 1987. Código Civil para el Distrito Federal</b>
                    <br/>
                    Se cita: “Además de la mancomunidad, habrá solidaridad activa, cuando dos o más acreedores tienen derecho para exigir, cada uno de por sí, el cumplimiento total de la obligación; y solidaridad pasiva cuando dos o más deudores reporten la obligación de prestar, cada uno de por sí, en su totalidad, la prestación debida”.<br/><br/><b>Artículo 1988. Código Civil para el Distrito Federal</b><br/>Se cita:“La solidaridad no se presume; resulta de la ley o de la voluntad de las partes”.
                    <br/><br/>
                    <b>Artículo 1989. Código Civil para el Distrito Federal</b>
                    <br/>
                    Se cita: “Cada uno de los acreedores o todos juntos pueden exigir de todos los deudores solidarios o de cualquiera de ellos, el pago total o parcial de la deuda. Si reclaman todo de uno de los deudores y resultare insolvente, pueden reclamarlo de los demás o de cualquiera de ellos. Si hubiesen reclamado sólo parte, o de otro modo hubiesen consentido en la división de la deuda, respecto de alguno o algunos de los deudores, podrán reclamar el todo de los demás obligados, con deducción de la parte del deudor o deudores libertados de la solidaridad”.
                    <br/><br/>
                    <b>Artículo 1996. Código Civil para el Distrito Federal</b>
                    Se cita: “El deudor solidario es responsable para con sus coobligados si no hace valer las excepciones que son comunes a todos”.
                    <br/><br/>
                    <b>Artículo 6. Disposición Única de la CONDUSEF.</b>
                    <br/>
                    Se cita: “Los Contratos de Adhesión deben:
                    <br/>
                    I. Redactarse en idioma español y presentarse en tipografía de al menos 8 puntos;
                    <br/>
                    II. Dividirse en capítulos, apartados o incisos que faciliten su lectura y comprensión
                    <br/>
                    III. Procurar no incorporar referencias a otros documentos o preceptos legales; en caso contrario, se incluirá una explicación del texto referenciado. Excepto cuando dichas referencias aludan a preceptos legales, en cuyo caso las Entidades Financieras deben incluir en el RECA un anexo con la transcripción de las disposiciones legales referidas expresamente en el Contrato de Adhesión para consulta del Usuario, e indicar en el mismo contrato el lugar en donde podrá consultarlo, independientemente de que deberá estar a su disposición en sucursales”.
                    <br/><br/>
                    <b>Artículo 68. Ley de Instituciones de Crédito.</b>
                    <br/>
                    Se cita: “Los contratos o las pólizas en los que, en su caso, se hagan constar los créditos que otorguen las instituciones de crédito, junto con los estados de cuenta certificados por el contador facultado por la institución de crédito acreedora, serán títulos ejecutivos, sin necesidad de reconocimiento de firma ni de otro requisito.
                    <br/><br/>
                    El estado de cuenta certificado por el contador a que se refiere este artículo, hará fe, salvo prueba en contrario, en los juicios respectivos para la fijación de los saldos resultantes a cargo de los acreditados o de los mutuatarios.
                    <br/>
                    El estado de cuenta certificado antes citado deberá contener nombre del acreditado; fecha del contrato; notario y número de escritura, en su caso; importe del crédito concedido; capital dispuesto; fecha hasta la que se calculó el adeudo; capital y demás obligaciones de pago vencidas a la fecha del corte; las disposiciones subsecuentes que se hicieron del crédito, en su caso; tasas de intereses ordinarios que aplicaron por cada periodo; pagos hechos sobre los intereses, especificando las tasas aplicadas de intereses y las amortizaciones hechas al capital; intereses moratorios aplicados y tasa aplicable por intereses moratorios.
                    <br/><br/>
                    Para los contratos de crédito a que se refiere el primer párrafo de este artículo, el estado de cuenta certificado que expida el contador sólo comprenderá los movimientos realizados desde un año anterior contado a partir del momento en el que se verifique el último incumplimiento de pago.
                </p>
            </div>

        </div>
    );
}

export default Glosario; 